import $ from 'jquery';
import Backbone from 'backbone';

let uniqueIdBase = 'cb-' + Math.floor((1 + Math.random()) * 0x10000).toString(16) + '-label';
let uniqueIdCounter = 1;

let defaultOptions = {
    checked: false,
    label: '',
    name: '',
    value: '',
    hideLabelWhenEmpty: true,
    htmlLabel: false,
};

let textContentProp = (function () {
    let el = document.body || document.createElement('span');
    return ('textContent' in el) ? 'textContent' : 'innerText';
})();

/** @let */
let hasCreateEvent = 'createEvent' in document;

let CheckBox = Backbone.View.extend({

    tagName: 'span',
    className: 'checkbox',

    initialize: function (options) {

        this.options = options = Object.assign({}, defaultOptions, options);

        $.data(this.el, 'view', this); // Enable access to the view instance using jQuery

        let labelId = uniqueIdBase + uniqueIdCounter++;

        let button = document.createElement('span');
        button.className = 'checkbox-button';
        button.setAttribute('aria-labelledby', labelId);
        this.el.appendChild(button);

        let label = this._label = document.createElement('span');
        label.className = 'checkbox-label';
        label.id = labelId;
        this.el.appendChild(label);

        let input = this._input = document.createElement('input');
        input.type = 'checkbox';
        input.tabIndex = -1;
        input.checked = !!options.checked;
        this.el.appendChild(input);

        this.el.tabIndex = 0;

        this.label(options.text || options.label, !!options.htmlLabel);

        input.style.width = '0px';
        input.style.height = '0px';
        input.style.outline = '0';
        input.style.border = '0';
        input.style.background = 'transparent';
        input.style.margin = '0';
        input.style.padding = '0';
        input.style.display = 'none';

        if (options.name) {
            input.setAttribute('name', options.name || '');
            input.setAttribute('value', options.value);
        } else {
            input.removeAttribute('name');
        }

        this._sync();

        this._boundOnChange = this._onChange.bind(this);
        this._boundOnMousedown = this._onMouseDown.bind(this);
        this._boundOnMouseup = this._onMouseUp.bind(this);
        this._boundOnTouchstart = this._onTouchStart.bind(this);
        this._boundOnTouchend = this._onTouchEnd.bind(this);
        this._boundOnTouchcancel = this._onTouchCancel.bind(this);
        this._boundOnKeyup = this._onKeyup.bind(this);
        this._boundOnClick = event => {
            if (this.isDisabled()) return;
            event.stopPropagation();
        };

        input.addEventListener('change', this._boundOnChange);
        this.el.addEventListener('mousedown', this._boundOnMousedown);
        this.el.addEventListener('touchstart', this._boundOnTouchstart);
        this.el.addEventListener('keyup', this._boundOnKeyup);
        this.el.addEventListener('click', this._boundOnClick);
    },

    remove: function remove() {

        if (this._input) {
            this._input.removeEventListener('change', this._boundOnChange);
        }

        this.el.removeEventListener('mousedown', this._boundOnMousedown);
        this.el.removeEventListener('mouseup', this._boundOnMouseup);
        this.el.removeEventListener('touchstart', this._boundOnTouchstart);
        this.el.removeEventListener('touchend', this._boundOnTouchend);
        this.el.removeEventListener('touchcancel', this._boundOnTouchcancel);
        this.el.removeEventListener('keyup', this._boundOnKeyup);
        this.el.removeEventListener('click', this._boundOnClick);

        this._input = null;
        this._label = null;

        CheckBox.__super__.remove.apply(this, arguments);
    },

    _onChange: function _onChange() {
        this._sync().trigger('change', this, this.checked());
        $(this._input).change();
    },

    _handleClickDown: function (event) {
        if (this.isDisabled()) return false;

        let touch = null;

        if (event.changedTouches) {
            if (this._currentTouchId != null) {
                for (let item of event.changedTouches) {
                    if (item.identifier === this._currentTouchId) {
                        touch = item;
                        break;
                    }
                }
            }

            if (!touch) {
                touch = event.changedTouches[0];
            }
        }

        this._downPos = touch
            ? {x: touch.pageX, y: touch.pageY}
            : {x: event.pageX, y: event.pageY};

        return true;
    },

    _handleClickUp: function (event, distanceThreshold = null) {
        if (this.isDisabled()) return false;

        if (!this._downPos) return false;

        let touch = null;

        if (event.changedTouches) {
            if (this._currentTouchId != null) {
                for (let item of event.changedTouches) {
                    if (item.identifier === this._currentTouchId) {
                        touch = item;
                        break;
                    }
                }
            }

            if (!touch) {
                touch = event.changedTouches[0];
            }
        }

        let currentPos = touch
            ? {x: touch.pageX, y: touch.pageY}
            : {x: event.pageX, y: event.pageY};
        let startPos = this._downPos;

        if (distanceThreshold !== null) {
            distanceThreshold = distanceThreshold || 1;
            let distanceTravelled = Math.hypot(Math.abs(currentPos.x - startPos.x), Math.abs(currentPos.y - startPos.y));
            if (distanceTravelled >= distanceThreshold) return false;
        }

        event.stopPropagation();
        this.toggle();

        return true;
    },

    _onMouseDown: function _onMouseDown(event) {
        if (this.isDisabled()) return;

        if (!this._handleClickDown(event)) return;

        this.el.addEventListener('mouseup', this._boundOnMouseup);
    },

    _onMouseUp: function _onMouseUp(event) {
        this.el.removeEventListener('mouseup', this._boundOnMouseup);

        if (this.isDisabled()) return;

        this._handleClickUp(event, null);
    },

    _onTouchStart: function _onTouchStart(event) {
        if (this.isDisabled()) return;
        if (this._currentTouchId != null) return;

        let touch = event.changedTouches[0];
        this._currentTouchId = touch.identifier;

        if (!this._handleClickDown(event)) return;

        this.el.addEventListener('touchend', this._boundOnTouchend);
        this.el.addEventListener('touchcancel', this._boundOnTouchcancel);

        event.stopPropagation();
    },

    _onTouchEnd: function _onTouchStart(event) {
        this.el.removeEventListener('touchend', this._boundOnTouchend);
        this.el.removeEventListener('touchcancel', this._boundOnTouchcancel);

        if (this.isDisabled()) return;
        if (this._currentTouchId == null) return;

        event.preventDefault();

        this._handleClickUp(event, 9);

        this._currentTouchId = null;
    },

    _onTouchCancel: function _onTouchStart(/*event*/) {
        this.el.removeEventListener('touchend', this._boundOnTouchend);
        this.el.removeEventListener('touchcancel', this._boundOnTouchcancel);

        this._currentTouchId = null;
    },

    _onKeyup: function _onKeyup(event) {
        if (this.isDisabled()) {
            return;
        }

        if (event.which === 32) {
            this.toggle();
            event.preventDefault();
            event.stopPropagation();
        }
    },

    label: function label() {
        if (arguments.length) {
            this._label[arguments[1] ? 'innerHTML' : textContentProp] = arguments[0];

            if (this.options.hideLabelWhenEmpty) {
                if (!(arguments[0] + '')) {
                    this._label.style.display = 'none';
                } else {
                    this._label.style.display = '';
                }
            }

            this.el.setAttribute('aria-label', arguments[0]);
            return this;
        } else {
            return this._label[textContentProp];
        }
    },

    text: function text() {
        this.label.apply(this, arguments);
    },

    name: function name() {
        if (arguments.length) {
            if (arguments[0] != null) {
                this._input.setAttribute('name', arguments[0]);
            } else {
                this._input.removeAttribute('name');
            }
            return this;
        } else {
            return this._input.getAttribute('name');
        }
    },

    value: function value() {
        if (arguments.length) {
            this._input.value = arguments[0];
            return this;
        } else {
            return this._input.value;
        }
    },

    val: function val() {
        return this.value.apply(this, arguments);
    },

    toggle: function toggle() {
        this._input.checked = !this._input.checked;
        this._fireNativeChange(this._input);
        return this;
    },

    _sync: function _sync() {
        this.$el.toggleClass('checked', this._input.checked);
        return this;
    },

    check: function check() {
        this._input.checked = !!arguments[0] || arguments[0] === undefined;

        if (arguments[1] === undefined || arguments[1]) {
            this._fireNativeChange(this._input);
        } else {
            this._sync();
        }

        return this;
    },

    checked: function checked() {
        return this._input.checked;
    },

    isEnabled: function isEnabled() {
        return !this.isDisabled();
    },

    isDisabled: function isDisabled() {
        return this.$el.hasClass('disabled');
    },

    enable: function enable() {

        if (arguments.length && !arguments[0]) {
            return this.disable();
        }

        if (this.el.getAttribute('tabindex') == null && this._enabledTabIndex != null) {
            this.el.setAttribute('tabindex', this._enabledTabIndex);
            delete this._enabledTabIndex;
        }

        this.$el.removeClass('disabled');

        return this;
    },

    disable: function disable() {

        if (arguments.length && !arguments[0]) {
            return this.enable();
        }

        if (this.el.getAttribute('tabindex') != null) {
            this._enabledTabIndex = this.el.getAttribute('tabindex');
            this.el.removeAttribute('tabindex');
        }

        this.$el.addClass('disabled');

        return this;
    },

    _fireNativeChange: function _fireNativeChange(input) {
        if (hasCreateEvent) {
            let evt = document.createEvent('HTMLEvents');
            evt.initEvent('change', false, true);
            input.dispatchEvent(evt);
        } else {
            input.fireEvent('onchange');
        }
    }

});

export default CheckBox;
