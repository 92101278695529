import BigEval from 'bigeval';

class Calculator {

    constructor($el) {

        let $calculator = this.$el = $el;
        let $screen = $calculator.find('.calculator-screen');
        let $buttons = $calculator.find('.calculator-button');

        $buttons.click(function () {

            let action = this.className.match(/calculator-action\[([^\]]+)]/);
            if (action) {
                action = action[1];
            }

            if (action === 'x') {
                action = '*';
            }
            if (action === '÷') {
                action = '/';
            }

            if ($screen.attr('data-has-error') === 'true') {
                $screen.removeAttr('data-has-error').text('0');
            }
            if ($screen.attr('data-is-textual') === 'true') {
                $screen.removeAttr('data-is-textual').text('0');
            }

            /** @type String */
            let currentScreen = $screen.text();

            const lastChar = currentScreen[currentScreen.length - 1];

            if (action !== '=') {
                // Clear the "last op" that was saved for pressing '=' again
                $screen.removeAttr('data-last-op');
            }

            const reverseLastZeroIfNeeded = function () {
                const charBeforeLast = currentScreen[currentScreen.length - 2];
                if (lastChar === '0' && (charBeforeLast === undefined || charBeforeLast < '0' || charBeforeLast > '9')) {
                    currentScreen = currentScreen.substr(0, currentScreen.length - 1);

                    if ($screen.attr('data-original-data') === '0') {
                        $screen.attr('data-original-data', '');
                    }
                }
            };

            if (action >= '0' && action <= '9') {
                reverseLastZeroIfNeeded();
                $screen.text(currentScreen + action);
            }
            else if (action === '.') {
                let hasDecimal = false;
                for (let pos = currentScreen.length - 1; pos >= 0; pos--) {
                    const c = currentScreen[pos];
                    if (c === '.') {
                        hasDecimal = true;
                        break;
                    }
                    if ((c < '0' || c > '9') && c !== '.') {
                        break;
                    }
                }
                if (!hasDecimal) {
                    $screen.text(currentScreen + action)
                }
            }
            else if (action === '+' || action === '-' || action === '*' || action === '/') {
                if (action === '+' || action === '-') {
                    reverseLastZeroIfNeeded();
                }

                // If two operations were specified in sequence, take just the last one
                if (lastChar === '+' || lastChar === '-' || lastChar === '*' || lastChar === '/') {
                    currentScreen = currentScreen.substr(0, currentScreen.length - 1);
                }

                // Save the base data so we can figure out later what the added formula was
                if (!$screen.attr('data-original-data')) {
                    $screen.attr('data-original-data', currentScreen);
                }

                // Append to screen
                $screen.text(currentScreen + action);
            }
            else if (action === '(' || action === ')') {
                reverseLastZeroIfNeeded();
                $screen.text(currentScreen + action);
            }
            else if (action === '=') {
                if ($screen.attr('data-last-op')) {
                    // Just so the last-op mechanism will work again for the next time
                    $screen.attr('data-original-data', currentScreen);

                    // Append the last-op to the screen
                    currentScreen += $screen.attr('data-last-op');
                }

                try {
                    const result = new BigEval().execute(currentScreen);

                    if (typeof result === 'number') {
                        // Try to determine if we have an isolated formula appended to the last screen value
                        const lastOp = $screen.text().substr($screen.attr('data-original-data').length);
                        if (lastOp && (lastOp[0] === '+' || lastOp[0] === '-' || lastOp[0] === '*' || lastOp[0] === '/')) {
                            // Save it for the next time the user hits '='
                            $screen.attr('data-last-op', lastOp);
                        }

                        // Set the screen to the result
                        $screen.text(result.toString());
                    } else {
                        if (result === Infinity) {
                            $screen.attr('data-is-textual', 'true').text('Infinity');
                        } else {
                            $screen.attr('data-has-error', 'true').text('Error');
                        }
                    }
                } catch (err) {
                    if (/parenthesis/.test(err.message)) {
                        $screen.attr('data-has-error', 'true').text('Unbalanced Parenthesis');
                    } else {
                        $screen.attr('data-has-error', 'true').text('Error');
                    }
                }

                $screen.removeAttr('data-original-data');
            }
            else if (action === 'clear') {
                $screen.text('0').removeAttr('data-original-data').removeAttr('data-last-op');
            }
            else if (action === 'backspace') {
                $screen.removeAttr('data-original-data').removeAttr('data-last-op')
                    .text(currentScreen.length <= 1 ? '0' : currentScreen.substr(0, currentScreen.length - 1));
            }

        });
    }

    destroy() {
        this.$el
            .find('.calculator-screen').off('.calculator').end()
            .find('.calculator-button').off('.calculator');
    }
}

export default Calculator;