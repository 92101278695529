const datePrototype = Date.prototype;

const hideProperty = function (prototype, functionName) {
    if (Object.defineProperty) {
        Object.defineProperty(prototype, functionName, {
            enumerable: false,
            configurable: true,
            writable: true,
            value: prototype[functionName],
        });
    }
};

if (!('dateWithZeroTime' in datePrototype)) {
    /** @expose */
    datePrototype.dateWithZeroTime = function () {
        return (new Date(this)).zeroTime();
    };
    hideProperty(datePrototype, 'dateWithZeroTime');
}

if (!('zeroTime' in datePrototype)) {
    /** @expose */
    datePrototype.zeroTime = function () {
        this.setHours(0);
        this.setMinutes(0);
        this.setSeconds(0);
        this.setMilliseconds(0);
        return this;
    };
    hideProperty(datePrototype, 'zeroTime');
}

if (!('toLocalISODateString' in datePrototype)) {
    /** @expose */
    datePrototype.toLocalISODateString = function () {
        let date = this.getFullYear() + '-';

        let i = this.getMonth() + 1;
        if (i < 10) {
            date += '0';
        }
        date += i;

        date += '-';

        i = this.getDate();
        if (i < 10) {
            date += '0';
        }
        date += i;

        return date;
    };
    hideProperty(datePrototype, 'toLocalISODateString');
}