const BypassDisconnectEventSymbol = Symbol('bypass_disconnect_event');

class WsCommBase {

    /**
     * @param {function()} [onConnect]
     * @param {function()} [onDisconnect]
     * @param {function(event:ErrorEvent)} [onError]
     * @param {function(id:number,message:*,shouldReturn:boolean)} [onMessage]
     */
    constructor({
                    onConnect,
                    onDisconnect,
                    onError,
                    onMessage
                } = {}) {
        this._p = {
            /** @type WebSocket|null */
            conn: null,

            nextMessageId: 1,
            openCallbacks: [],

            onConnect,
            onDisconnect,
            onError,
            onMessage,
        };
    }

    /**
     *
     * @param {WebSocket} conn
     * @protected
     */
    _setupConnection(conn) {
        const p = this._p;

        p.conn = conn;

        /** @type {Map<number,{resolve:Function,reject:Function}>} */
        this._resultMap = new Map();

        conn.addEventListener('message', event => {
            let envelope = JSON.parse(event.data);

            switch (envelope['type']) {
                case 'result':
                    let controller = this._resultMap.get(envelope['id']);
                    this._resultMap.delete(envelope['id']);

                    if (controller !== undefined) {
                        if (envelope['error']) {
                            let errData = envelope['error'];
                            let err = new Error(errData['name'] || '');
                            Object.assign(err, errData);
                            controller.reject(err);
                        } else {
                            controller.resolve(envelope['data']);
                        }
                    }
                    break;

                case 'message':
                    p.onMessage && p.onMessage.call(
                        this,
                        envelope['id'],
                        envelope['data'],
                        !!envelope['return']);

                    break;
            }
        });

        conn.addEventListener('close', _event => {
            if (p.conn === conn) {
                p.conn = null;
            }

            if (!conn[BypassDisconnectEventSymbol]) {
                p.onDisconnect && p.onDisconnect.call(this);
            }
        });

        conn.addEventListener('error', event => {
            if (p.conn !== conn) return;

            p.onError && p.onError.call(this, event);
        });

        conn.addEventListener('open', () => {
            if (p.openCallbacks.length > 0) {
                let cbs = p.openCallbacks;
                p.openCallbacks = [];
                for (let cb of cbs)
                    cb();
            }
            p.onConnect && p.onConnect.call(this);
        });
    }

    /**
     * Send a message and optionally wait for a result
     * @param {*} data - message to send
     * @param {boolean} [expectResult=false] - should we expect a result for this message
     * @returns {Promise<*>} - promise for after the message was sent, or with the result if `expectResult=true`
     */
    async sendMessage(data, expectResult = false) {
        const p = this._p;

        let envelope = {
            type: 'message',
            id: p.nextMessageId++,
            return: !!expectResult,
            data: data,
        };

        let result;

        if (expectResult) {
            let resolve = null, reject = null;
            result = new Promise((r, j) => {
                resolve = r;
                reject = j;
            });

            this._resultMap.set(envelope.id, {
                resolve: resolve,
                reject: reject,
            });
        }

        p.conn.send(JSON.stringify(envelope));

        return result;
    }

    /**
     * Send result for an incoming message
     * @param {number} id - original message id
     * @param {*} result - the result
     * @returns {boolean} - true if the result was sent successfully
     */
    sendMessageResult(id, result) {
        const p = this._p;

        let envelope = {
            type: 'result',
            id: id,
            data: result,
        };

        if (result instanceof Error) {
            envelope.error = {
                name: result.name,
                message: result.message
            };
        }
        else {
            envelope.data = result;
        }

        p.conn.send(JSON.stringify(envelope));

        return true;
    }

    get isConnectionOpen() {
        const p = this._p;
        return p.conn !== null && p.conn.readyState === p.conn.OPEN;
    }

    disconnect(emitEvent = true) {
        const p = this._p;

        if (p.conn) {
            let conn = p.conn;
            p.conn = null; // Set specifically to null

            conn[BypassDisconnectEventSymbol] = !emitEvent;

            conn.close();
        }

        return this;
    }

}

module.exports = WsCommBase;