import PhoneNumberUtil from "../../utils/PhoneNumberUtil";

const templates = {
    'textComponent': `<input 
        :type='subType' 
        :value='originalData'
        :readonly="!!readOnly"
        :class='invalid && "invalid"'
        @input="onUpdate($event, subType)"/>`,

    'multilineComponent': `<textarea 
        :value='originalData' 
        :class='invalid && "invalid"'
        :readonly="!!readOnly"
        @input="onUpdate"/>`,

    'checkboxComponent': `<input type="checkbox" 
        :checked='originalData === "1"'
        :disabled="!!readOnly"
        @change="onUpdate"/>`,

    'checkboxlistComponent': `<div>
        <label v-for="option in options" class="input-checkbox-label">
            <input type="checkbox"
                :value='option'
                :checked='originalData && originalData.indexOf(option) > -1'
               @change="onUpdateCheckbox"
           >
            <span>{{option}}</span>
            <br>
        </label></div>`,

    'radioComponent': `<div>
        <label v-for="option in options" class="input-radio-label">
           <input type="radio"
               :disable="!!readOnly"
               :name='name'
               :value='option'
               :checked="originalData === option"
               @change="onUpdate"
           >
                <span>{{option}}</span>
                <br>
        </label></div>`,

    'dropdownComponent': `<select 
        :class='invalid && "invalid"' 
        @change="onUpdate"
        :value="originalData"
        :disabled="!!readOnly"
        >
    <option value=""></option>
    <option v-for="option in options" :value="option">{{option}}</option>
</select>`

};

const data = function () {
    return {
        selected: new Set()
    }
};

const mounted = function () {

    const originalData = this.subType !== 'tel' ?
        this.originalData : this.normalizePhone(this.originalData);

    this.$emit('input', originalData);
};

const methods = {
    normalizePhone(number) {
        return number ? PhoneNumberUtil.normalizePhoneNumber(number) : '';
    },
    onUpdateCheckbox(e) {
        const checked = e.target.checked,
            value = e.target.value;

        checked ? this.selected.add(value) : this.selected.delete(value);

        this.$emit('input', Array.from(this.selected).join('\n'));
    },
    onUpdate(e, subType){

        let value = e.target.value;

        if (e.target.type === 'checkbox') {
            value = e.target.checked
        }

        switch (subType) {
            case 'date':
                value = value ? new Date(value).toISOString().substr(0, 10) : null;
                break;
            case 'tel':
                value = this.normalizePhone(value);
        }

        this.$emit('input', value);

    }
};

const Components = {};

Object.entries(templates).forEach(([name, template]) => {
    Components[name] = {
        mounted, data, methods, template,
        //props: {name: String, subType: String, readOnly: Number, invalid: Boolean, options: Array, originalData: String}
        props: ['name', 'subType', 'readOnly', 'invalid', 'options', 'originalData']
    }
});

export default Components