import $ from 'jquery';
import Backbone from 'backbone';

/** @class View */
const View = Backbone.View.extend(
    /** @lends View.prototype */
    {

        ajax: function () {
            const that = this;
            if (!that.__ajaxQueries) {
                that.__ajaxQueries = [];
            }

            const xhr = $.ajax.apply(this, arguments);
            that.__ajaxQueries.push(xhr);

            xhr.always(function () {
                that.__ajaxQueries.remove(xhr);
            });

            return xhr;
        },

        ajaxGet: function () {
            const that = this;
            if (!that.__ajaxQueries) {
                that.__ajaxQueries = [];
            }

            const xhr = $.get.apply(this, arguments);
            that.__ajaxQueries.push(xhr);

            xhr.always(function () {
                that.__ajaxQueries.remove(xhr);
            });

            return xhr;
        },

        ajaxPost: function () {
            const that = this;
            if (!that.__ajaxQueries) {
                that.__ajaxQueries = [];
            }

            const xhr = $.post.apply(this, arguments);
            that.__ajaxQueries.push(xhr);

            xhr.always(function () {
                that.__ajaxQueries.remove(xhr);
            });

            return xhr;
        },

        ajaxJsonPost: function () {
            const that = this;
            if (!that.__ajaxQueries) {
                that.__ajaxQueries = [];
            }

            const xhr = $.ajaxJsonPost.apply(this, arguments);
            that.__ajaxQueries.push(xhr);

            xhr.always(function () {
                that.__ajaxQueries.remove(xhr);
            });

            return xhr;
        },

        /**
         *
         * @param {String|Function} code
         * @param {number} delay
         * @return {number}
         */
        setTimeout: function (code, delay) {

            if (!this.__timeouts) {
                this.__timeouts = [];
            }

            const handle = setTimeout(code, delay);
            this.__timeouts.push(handle);

            return handle;
        },

        /**
         *
         * @param {String|Function} code
         * @param {number} delay
         * @return {number}
         */
        setInterval: function (code, delay) {

            if (!this.__intervals) {
                this.__intervals = [];
            }

            const handle = setInterval(code, delay);
            this.__intervals.push(handle);

            return handle;
        },

        /**
         *
         * @param {number} intervalId
         * @returns {View}
         */
        clearTimeout: function (intervalId) {

            if (this.__timeouts) {
                const idx = this.__timeouts.indexOf(intervalId);
                if (idx !== -1) {
                    this.__timeouts.splice(idx, 1);
                    clearTimeout(intervalId);
                }
            }

            return this;
        },

        /**
         *
         * @param {number} intervalId
         * @returns {View}
         */
        clearInterval: function (intervalId) {

            if (this.__intervals) {
                const idx = this.__intervals.indexOf(intervalId);
                if (idx !== -1) {
                    this.__intervals.splice(idx, 1);
                    clearInterval(intervalId);
                }
            }

            return this;
        },

        /**
         *
         * @param {View|Array.<View>} view
         * @returns {View}
         */
        addSubview: function (view) {

            if (!this.__subviews) {
                this.__subviews = [];
            }

            if (view) {
                if (view instanceof Array) {
                    for (let i = 0, count = view.length; i < count; i++) {
                        this.__subviews.push(view[i]);
                    }
                } else {
                    this.__subviews.push(view);
                }
            }

            return this;
        },

        /**
         *
         * @param {View|Array.<View>} view
         * @returns {View}
         */
        removeSubview: function (view) {

            if (this.__subviews) {
                let idx;

                if (view instanceof Array) {

                    for (let i = 0, count = view.length; i < count; i++) {
                        idx = this.__subviews.indexOf(view[i]);
                        if (idx !== -1) {
                            this.__subviews.splice(idx, 1);
                            view[i].remove();
                        }
                    }

                } else {
                    idx = this.__subviews.indexOf(view);
                    if (idx !== -1) {
                        this.__subviews.splice(idx, 1);
                        view.remove();
                    }
                }
            }

            return this;
        },

        /**
         *
         * @param {View} view
         * @returns {View}
         */
        detachSubview: function (view) {

            if (this.__subviews) {
                this.__subviews.remove(view);
            }

            return this;
        },

        remove: function () {
            const that = this;

            if (that.__ajaxQueries) {
                that.__ajaxQueries.forEach(function (xhr) {
                    xhr.abort();
                });
                that.__ajaxQueries = [];
            }

            if (that.__timeouts) {
                that.__timeouts.forEach(function (handle) {
                    clearTimeout(handle);
                });
                that.__timeouts = [];
            }

            if (that.__intervals) {
                that.__intervals.forEach(function (handle) {
                    clearInterval(handle);
                });
                that.__intervals = [];
            }

            if (that.__subviews) {
                that.__subviews.forEach(function (view) {
                    view.remove();
                });
                that.__subviews = [];
            }

            View.__super__.remove.apply(this, arguments);
        },

    },
);

export default View;
