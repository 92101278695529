import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import app from './App.js';
import comm from './Comm.js';

let isInCall = false, currentCallData = {}, canCallNow = false;

class Calls {

    static call(number, contactId, campaignId) {
        Calls
            .trigger('makecall', { 'destination': number }) // Tell everyone that we have *initiated* a call
            .trigger('incall'); // Tell everyone that we now have the "incall" status

        app.AlertUI.showLoader();
        $.ajaxJsonPost(app.apiUrl('outgoing/call', { 'contact_id': contactId, 'campaign_id': campaignId, 'destination': number}))
            .always(() => {
                app.AlertUI.hideLoader();
            })
            .then(response => {
                isInCall = true;
                currentCallData['destination'] = number;

                Calls.trigger('makecallsuccess', response);
            })
            .fail((xhr, error) => {
                app.showXhrError(xhr, error);

                let errorCode;
                try {
                    errorCode = xhr.responseJSON ? xhr.responseJSON['error'] : JSON.parse(xhr.responseText)['error'];
                } catch (e) {
                }
                errorCode = errorCode || error;

                Calls.trigger('makecallfail', errorCode);
            });

        return this;
    }

    static hangUp() {
        Calls.trigger('hangup');

        app.AlertUI.showLoader();

        if (app.getProjectMode() === 'predictive') {
            return $.ajaxJsonPost(app.apiUrl('predictive/hangup'), {ext: app.getPhoneExtension()})
                .always(() => {
                    app.AlertUI.hideLoader();
                })
        }

        $.ajaxJsonPost(app.apiUrl('outgoing/hangup'))
            .always(() => {
                app.AlertUI.hideLoader();
            })
            .then(data => {

                if (this.callStatWs) return; // No need, we will get the data from ws.

                let oldIsInCall = isInCall;
                isInCall = !!data['has_call'];

                currentCallData = data;

                Calls.trigger('hangupsuccess');

                if (isInCall !== oldIsInCall) {
                    if (isInCall) {
                        Calls.trigger('incall'); // Tell everyone that we now have the "incall" status
                    } else {
                        Calls.trigger('callfinished'); // Tell everyone that we no longer have the "incall" status
                    }
                }

            })
            .fail((xhr, error) => {
                app.showXhrError(xhr, error);

                let errorCode;
                try {
                    errorCode = xhr.responseJSON ? xhr.responseJSON['error'] : JSON.parse(xhr.responseText)['error'];
                } catch (e) {
                }
                errorCode = errorCode || error;

                Calls.trigger('hangupfail', errorCode);
            });

        return this;
    }

    static registerToCallStatus() {

        comm.sendMessage({type: 'current_call_stats'})
            .then(() => {
                this.callStatWs = true;
                comm.on('disconnect', () => this.callStatWs = false);
            })
            .catch(() => {});

        return this;
    }

    static updateCallStatus(data) {

        const oldIsInCall = isInCall;
        isInCall = !!data['has_call'];

        currentCallData = data;

        if (data['call_settings']) {
            app.extendCachedGeneralSettings();
        }

        if (isInCall !== oldIsInCall) {
            if (isInCall) {
                Calls.trigger('incall'); // Tell everyone that we now have the "incall" status
            } else {
                Calls.trigger('callfinished'); // Tell everyone that we no longer have the "incall" status
            }
        }

        if (data['call_type'] === 'predictive') {
            Calls.trigger('predictive_call', data);
        }

        return this;

    }

    static incomingCallUpdate(evt) {
        const data = {
            //has_call: evt.status === 'answer',
            call_type: 'incoming',
            caller: evt.caller,
            //uniqueId: evt.unique_id,
            status: evt.status,
            call_session_id: evt.call_session_id,
            //contact_id: evt.contact_id,
        }

        if (data.status === 'answer') {
            localStorage.removeItem('lastIncomingSessionId');
            localStorage.setItem('currentIncomingSessionId', data.call_session_id);

        } else if (data.status === 'complete') {
            localStorage.setItem('lastIncomingSessionId', data.call_session_id);
            localStorage.removeItem('currentIncomingSessionId');
        }

        Calls.trigger('incoming_call', data);
    }

    static onQueueEnter() {
        Calls.trigger('queue_enter');
    }

    static isInCall() {
        return isInCall;
    }

    static getCallData() {
        return currentCallData;
    }

    static setCanCallNow(value) {
        canCallNow = value;
        Calls.trigger('cancallnowupdate', canCallNow);
        return this;
    }

    static getCanCallNow() {
        return canCallNow;
    }

}

_.extend(Calls, Backbone.Events);

export default Calls;
