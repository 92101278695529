import $ from 'jquery';
import i18n, { t }  from '../i18n/i18n.js';
import View from '../../controls/View.js';
import app from '../App.js';
import Vue from "vue/dist/vue.esm.js";
import OrderFields from "./OrderCustomFields.vue";

const OrderEditArea = View.extend({

    className: 'new-order-area',

    initialize: function (options) {
        var that = this;

        that.options = options;

        that.vueOrderFields = new Vue({
            data: {
                productId: null,
                order: {},
                fieldsDef: [],
            },
            render: function (h) {
                return h(OrderFields, {
                    props: {
                        productId: this.productId,
                        order: this.order,
                        fieldsDef: this.fieldsDef,
                    },
                    ref: 'orderFieldsComponent'

                })
            }
        });

        that.$vueData = that.vueOrderFields.$data;

        return that;
    },

    mountVue: function () {
        this.$vueData.order = this.options.order;
        this.$vueData.fieldsDef = this._custom_fields_def;

        this.vueOrderFields.$mount('#order-fields');
    },

    render: function () {
        var that = this;

        var order = that.options.order;

        that.$el.empty();

        // --- Base fields

        var $baseFieldsWrapper = $('<div class="order-base-fields">').appendTo(that.$el)
            .append(
                $('<h2 class="required">').text(t('orders.product_title')),
                that.$product = $('<select>').attr('name', 'product_id')
            );

        if (app.getLoginMode() === 'override') {

            $baseFieldsWrapper.append(
                $('<h2 class="required">').text(t('orders.saved_campaign')),
                $('<div class="explain">').html(t('orders.saved_campaign_explain', { 'campaign_name': order['campaign_name'] })),
                that.$savedCampaign = $('<select>').attr('name', 'saved_campaign_id')
            );
        }

        that.$product.change(function () {
            that.$vueData.productId = that.$product.val();
        });

        if (that._products) {
            that._products.forEach(function (item) {
                $('<option>').val(item['id']).text(item['name']).appendTo(that.$product);
            });
            that.$product.val(order['product_id']);
        } else {

            app.AlertUI.showLoader();
            var args = { 'contact_id': order['contact_id'] };
            if (order['id']) {
                args['order_id'] = order['id'];
            }

            that.ajaxGet(app.apiUrl('products/available', args))
                .always(function () {
                    app.AlertUI.hideLoader();
                })
                .then(function(products) {
                    that._products = products;
                    that._products.forEach(function (item) {
                        $('<option>').val(item['id']).text(item['name']).appendTo(that.$product);
                    });

                    let productId = order['product_id'];

                    if (!productId && products.length === 1) { // Set default product only if it's a single product
                        productId = products[0]['id'];
                    }
                    that.$product.val(productId)
                    // Trigger rendering of relevant custom fields
                        .change();
                })
                .fail(function(xhr, error) {
                    app.showXhrError(xhr, error);
                });

        }

        if (order['product_id']) {
            that.$product.prop('disabled', true);
        }

        if (that.$savedCampaign) {

            if (that.options.campaigns) {

                that.options.campaigns.forEach(function (item) {
                    $('<option>').val(item['id']).text(item['name']).appendTo(that.$savedCampaign);
                });

            }

            that.$savedCampaign.val(order['saved_campaign_id'] || order['campaign_id']);

        }

        // --- Custom fields

        that.$fields = $('<div id="order-fields" class="order-fields">').appendTo(that.$el);

        if (that._custom_fields_def) {

            setTimeout(() => {
                that.mountVue();
            }, 0);

        } else {

            app.AlertUI.showLoader();
            that.ajaxGet(app.apiUrl('orders/custom_fields_def'))
                .always(function () {
                    app.AlertUI.hideLoader();
                })
                .then(function(custom_fields_def) {
                    that._custom_fields_def = custom_fields_def;

                    that.mountVue();

                })
                .fail(function(xhr, error) {
                    app.showXhrError(xhr, error);
                });
        }

        // --- Actions

        var $actions = $('<div class="actions">').appendTo(that.$el);

        $('<button class="neutral-button">').text(t('cancel')).click(that.onCancel.bind(that)).appendTo($actions);
        $('<button class="neutral-button">').text(t(order['status'] === 'closed' ? 'save' : 'orders.save_order')).click(that.onSave.bind(that)).appendTo($actions);

        if (order['status'] === 'open') {
            $('<button class="positive-button">').text(t('orders.end_order')).click(that.onEnd.bind(that)).appendTo($actions);
            $('<button class="positive-button">').text(t('orders.end_order_and_duplicate')).click(that.onEndAndDuplicate.bind(that)).appendTo($actions);
        }

        return that;
    },

    getOrderData: function () {
        var that = this,
            $el = that.$el;

        var order = that.options.order;

        var data = {};

        if (order['id']) {
            data['id'] = order['id'];
        }
        data['contact_id'] = order['contact_id'];
        data['campaign_id'] = order['campaign_id'];
        data['campaign_name'] = order['campaign_name'];
        data['created_at'] = order['created_at'];
        data['created_by'] = order['created_by'];
        data['closed_at'] = order['closed_at'];
        data['closed_by'] = order['closed_by'];

        data['product_id'] = parseInt($el.find('select[name=product_id]').val(), 10);
        data['product_name'] = $el.find('select[name=product_id]>option:selected').text();

        data['saved_campaign_id'] = parseInt($el.find('select[name=saved_campaign_id]').val(), 10) || data['campaign_id'];
        data['saved_campaign_name'] = $el.find('select[name=saved_campaign_id]>option:selected').text() || data['campaign_name'];

        data['custom_fields'] = {};

        that._custom_fields_def.forEach(function (fieldDef) {
            if (fieldDef['product_id'] != data['product_id']) return;
            if (fieldDef['readonly']) return;

            const fieldId = fieldDef.id;
            const fieldName = `f${fieldId}`;

            data['custom_fields'][fieldName] = that.vueOrderFields.$refs
                .orderFieldsComponent.orderData[fieldId];

        });

        return data;
    },

    getCustomFieldsDef: function () {
        return this._custom_fields_def;
    },

    validateRequired: function () {

        return this.vueOrderFields.$refs
            .orderFieldsComponent.testForInvalidFields();

    },

    onCancel: function () {
        return this.trigger('cancel', this);
    },

    onSave: function () {
        if (!this.validateRequired()) return this;
        return this.trigger('save', this);
    },

    onEnd: function () {
        if (!this.validateRequired()) return this;
        return this.trigger('end', this);
    },

    onEndAndDuplicate: function () {
        if (!this.validateRequired()) return this;
        return this.trigger('end_and_duplicate', this);
    },

    remove: function () {
        var that = this;

        if (that.vueOrderFields) {
            that.vueOrderFields.$destroy();
        }

        OrderEditArea.__super__.remove.apply(this, arguments);
    }

});

export default OrderEditArea;