
class UrlBuilder {
    static buildUrl(url, options, timestamp) {
        if (timestamp || typeof timestamp === 'undefined') {
            options = options || {};
            options._timestamp = +new Date;
        }
        // Detect the ?
        let hasQ = url.indexOf('?') > -1;

        // Add query string
        let q = '';
        if (options) {
            for (let [key, val] of Object.entries(options)) {
                q += hasQ ? '&' : '?';
                q += encodeURIComponent(key);
                q += '=';
                q += encodeURIComponent(val instanceof Date ? val.toISOString() : val);

                hasQ = true;
            }
        }

        return url + q;
    }
}

export default UrlBuilder;