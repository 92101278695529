import $ from 'jquery';

// selectors
function focusable(element, isTabIndexNotNaN) {
    const nodeName = element.nodeName.toLowerCase();
    if ("area" === nodeName) {
        let map = element.parentNode;
        let mapName = map.name;
        if (!element.href || !mapName || map.nodeName.toLowerCase() !== "map") {
            return false;
        }
        let img = $("img[usemap=#" + mapName + "]")[0];
        return !!img && visible(img);
    }
    return (/input|select|textarea|button|object/.test(nodeName) ?
        !element.disabled :
        "a" === nodeName ?
            element.href || isTabIndexNotNaN :
            isTabIndexNotNaN) &&
        // the element and all of its ancestors must be visible
        visible(element);
}

function visible(element) {
    return $.expr.filters.visible(element) &&
        !$(element).parents().addBack().filter(function () {
            return $.css(this, "visibility") === "hidden";
        }).length;
}

Object.assign($.expr[":"], {
    focusable: function (element) {
        return focusable(element, !isNaN($.attr(element, "tabindex")));
    },

    tabbable: function (element) {
        const tabIndex = $.attr(element, "tabindex");
        let isTabIndexNaN = isNaN(tabIndex);
        return (isTabIndexNaN || tabIndex >= 0) && focusable(element, !isTabIndexNaN);
    }
});
