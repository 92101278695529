<template>
    <div>
        <button @click="enterQueue"
                class="side-call-button"
                :disabled="!enableQueueEnter">{{t('side_nav.enter_queue_button')}}</button>
        <button @click="exitQueue"
                class="side-next-button"
                :disabled="!enableQueueExit">{{t('side_nav.exit_queue_button')}}</button>
        <button @click="unavailable"
                v-if="projectMode === 'incoming'"
                class="side-next-button"
                :disabled="enableQueueEnter">{{paused ? t('side_nav.available_button') : t('side_nav.unavailable_button')}}</button>
    </div>
</template>

<script>
    import app from '../App';
    import axios from 'axios';
    import {t} from '../i18n/i18n';
    import comm from '../Comm';
    import MessagePopupView from '../../controls/MessagePopupView';

    export default {
        name: 'SideNavPredictive',
        props: ['sessionClosed', 'updateQueueStatus', 'projectMode'],
        data() {
            return {
                wsError: null,
                isLoading: false,
                camp: null,
                queueStatus: 'none',
                deviceState: '',
                paused: false
            }
        },
        watch: {
            updateQueueStatus(newStatus) {
                this.queueStatus = newStatus;
            },
            deviceState(newState) {
                if (['UNKNOWN', 'INVALID', 'UNAVAILABLE'].includes(newState)) {
                    const message = t('error_codes.wrong-device-state', {status: t(`device_states.${newState}`)});
                    new MessagePopupView({addClass: 'warning', message, messageIsHtml: true, queue: true}).show();
                }
            }
        },
        created() {
            this.getStatus();

            comm.on('queue_status', event => {
                if (event.msg === 'queue_exit' && event.data) {

                    const newDeviceState = event.data;

                    if (newDeviceState !== 'NOT_INUSE') {
                        this.queueStatus = 'none'; // TODO: Delete and use the device state
                        const message = t('error_codes.wrong-device-state', {status: t(`device_states.${event.data}`)});
                        new MessagePopupView({addClass: 'warning', message, messageIsHtml: true, queue: true}).show();
                    } else {
                        this.queueStatus = 'none';
                    }

                } else if (event.msg === 'queue_paused') {
                    this.paused = true;
                    this.$emit('enableOutgoing', true);
                } else if (event.msg === 'queue_unpause') {
                    this.paused = false;
                    this.$emit('enableOutgoing', false);
                }
            });

            comm.on('device_state', event => {
                this.deviceState = event.msg;
            });
        },
        methods: {
            async enterQueue() {
                if (!this.enableQueueEnter) return;

                const ext = this.getExt;
                if (!ext) return;

                this.isLoading = true;

                try {
                    const { data: {camp}} = await axios.post(app.apiUrl('predictive/enter_queue'), {ext});
                    this.camp = camp;
                    this.queueStatus = 'available';
                    this.paused = false;

                } catch (error) {
                    app.showXhrError(error.request);
                }

                this.isLoading = false;

            },
            async exitQueue() {
                if (!this.enableQueueExit) return;

                this.isLoading = true;

                try {
                    await axios.post(app.apiUrl('predictive/exit_queue'), {ext: this.getExt, camp: this.camp});
                    this.camp = null;
                    this.queueStatus = 'none';
                    this.paused = false;

                } catch (error) {
                    app.showXhrError(error.request);
                }

                this.isLoading = false;

            },
            async unavailable() {
                if (this.enableQueueEnter) return;

                this.isLoading = true;

                try {

                    const method = this.paused ? 'delete' : 'post';
                    const urlPath = this.paused ? 'break' : 'break/outgoing';
                    await axios[method](app.apiUrl(urlPath + `?ext=${this.getExt}`));

                } catch (error) {
                    app.showXhrError(error.request);
                }

                this.isLoading = false;
            },
            async getStatus () {

                this.isLoading = true;

                const ext = this.getExt;
                if (!ext) return;

                app.AlertUI.showLoader();

                try {

                    const {data} = await axios.get(app.apiUrl('predictive/queue_status/' + ext));

                    this.queueStatus = data['queue_status'];
                    this.deviceState = data['device_state'];
                    this.paused = data['paused'];

                    if (this.paused) {
                        this.$emit('enableOutgoing', true); // TODO: Better logic required
                    }

                } catch (error) {
                    app.showXhrError(error.request);
                }

                app.AlertUI.hideLoader();
                this.isLoading = false;

            },
        },
        computed: {
            enableQueueEnter () {
                return !this.isLoading && this.queueStatus === 'none' && this.sessionClosed && this.deviceState === 'NOT_INUSE'
            },
            enableQueueExit () {
                return !this.isLoading && this.queueStatus === 'available'
            },
            getExt() {
                const ext = app.getPhoneExtension();

                if (!ext) {
                    new MessagePopupView({addClass: 'warning', message: t('error_codes.no-extension'), queue: true}).show();
                    return;
                }

                return ext;
            }
        },
        destroyed() {
            console.log('Vue SideNav destroyed!')
        }
    }
</script>

<style scoped>

</style>
