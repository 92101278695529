import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';

/**
 * @typedef {Backbone.ViewOptions} DropDownMenu.Options
 *
 * @property {Number} [itemBlurDelay=300] How long to wait before deciding to blur the focused item?
 * @property {Boolean} [capturesFocus=true] Should this DropDownMenu be added to the TAB-key stack?
 * @property {Boolean} [multi=false] Does this DropDownMenu show checkboxes for multiple item selection?
 * @property {Function} [keyDownHandler=null] An alternative "keydown" event handler. Return true to prevent default behaviour.
 * @property {Boolean} [autoCheckGroupChildren=true] When a group is checked/unchecked - all items beneath it will update accordingly
 * @property {Boolean} [useExactTargetWidth=false] Use the exact target's width, do not allow growing
 * @property {Boolean} [constrainToWindow=true] Should the position be constrained to the window, attaching to window's borders if needed?
 * @property {Boolean} [estimateWidth=false] Use an estimation for the width instead of measuring. May be faster - needs testing and may depend on the CSS.
 * @property {Boolean} [estimateHeight=false] Use an estimation for the height instead of measuring. May be faster - needs testing and may depend on the CSS.
 * @property {Number} [virtualMinItems=100] Turns into a virtual list - with items being created and showing up on viewport only. The value specified the minimum item count where a virtual list will be created.
 * @property {function(item: Object):(jQuery|Element|String|null)|undefined} [itemTemplate] Function to call when rendering an item element
 * */
let defaultOptions = {
    itemBlurDelay: 300
    , capturesFocus: true
    , multi: false
    , keyDownHandler: null
    , autoCheckGroupChildren: true
    , useExactTargetWidth: false

    , constrainToWindow: true
    , estimateWidth: false
    , estimateHeight: false
    , virtualMinItems: 100,
};

let KeyCodes = {
    /** @let */PAGE_UP: 33,
    /** @let */PAGE_DOWN: 34,
    /** @let */UP: 38,
    /** @let */DOWN: 40,
    /** @let */LEFT: 37,
    /** @let */RIGHT: 39,
    /** @let */ENTER: 13,
    /** @let */TAB: 9,
    /** @let */ESCAPE: 27,
    /** @let */SPACE: 32,
    /** @let */HOME: 36,
    /** @let */END: 35,
};

let hasComputedStyle = document.defaultView && document.defaultView.getComputedStyle;
// noinspection JSUnusedLocalSymbols
let getComputedStyle = function (el) {
    return hasComputedStyle ? document.defaultView.getComputedStyle(el) : el.currentStyle;
};

let escapeRegex = function (value) {
    return value.replace(/[\-\[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

/**
 * Calculates the anchor relative to this element
 * @param {Element|jQuery} $el? - optional if `outerSize` and `isRtl` are specified for the $el
 * @param {String} anchor
 * @param {{width: Number, height: Number}?} outerSize
 * @param {Boolean?} isRtl
 * @returns {{left: *, top: *}}
 */
let calcAnchor = function ($el, anchor, outerSize, isRtl) {

    let anchorParts = (anchor || '').split(/[ ,]/);
    let x = anchorParts[0],
        y = anchorParts[1];

    if (!outerSize) {
        outerSize = {
            width: $el.outerWidth(),
            height: $el.outerHeight(),
        };
    } else {
        if (outerSize.width == null) {
            outerSize.width = outerSize.outerWidth;
        }
        if (outerSize.height == null) {
            outerSize.height = outerSize.outerHeight;
        }
    }

    if (x === 'start' || x === 'end') {
        if (isRtl == null) {
            isRtl = $el.css('direction') === 'rtl';
        }
        x = x === 'start' ? (isRtl ? 'right' : 'left') : (isRtl ? 'left' : 'right');
    }

    if (x === 'right') {
        x = outerSize.width;
    } else if (x === 'center') {
        x = outerSize.width / 2;
    } else if (x === 'left') {
        x = 0;
    } else if (x && (typeof x === 'string') && x.charAt(x.length - 1) === '%') {
        x = outerSize.width * ((parseFloat(x) || 0) / 100);
        if (isRtl == null) {
            isRtl = $el.css('direction') === 'rtl';
        }
        if (isRtl) {
            x = outerSize.width - x;
        }
    } else {
        x = parseFloat(x) || 0;
    }

    if (y === 'bottom') {
        y = outerSize.height;
    } else if (y === 'center') {
        y = outerSize.height / 2;
    } else if (y === 'top') {
        y = 0;
    } else if (y && (typeof y === 'string') && y.charAt(y.length - 1) === '%') {
        y = outerSize.height * ((parseFloat(y) || 0) / 100);
    } else {
        y = parseFloat(y) || 0;
    }

    return {left: x, top: y};
};

// noinspection JSUnusedGlobalSymbols
/**
 * @class DropDownMenu
 * @extends Backbone.View
 * */
let DropDownMenu = Backbone.View.extend(
    /** @lends DropDownMenu.prototype */
    {

        tagName: 'ul',
        className: 'dropdown-menu',

        _requestIndex: 0,
        _pending: 0,

        /**
         * @constructs
         * @param {DropDownMenu.Options?} options
         * @returns {DropDownMenu}
         */
        initialize: function (options) {

            let that = this;

            this.el.role = 'menu';

            /** @type {DropDownMenu.Options} */
            let o = this.o = Object.assign({}, defaultOptions, options);

            this._items = [];

            o._groupCount = 0; // This will keep state of how many `group` items we have

            this._mouseHandled = false;
            this.el.role = null;
            if (o.capturesFocus) {
                this.el.tabIndex = 0;
            }

            let itemUpAction = function (event) {
                let $target = $(event.currentTarget);
                if ($target.hasClass('ui-sortable-helper') || $target.css('display') === 'none') return;
                if (!that._mouseHandled) {

                    that.triggerItemSelection(event);

                    if (o) { // If we are not closed in response to a click/select
                        that.toggleFocusedItem();

                        if (!event.isPropagationStopped()) {
                            that._mouseHandled = true;
                        }

                        if (!that.$el.is(":focus")) {

                            that.$el.trigger("focus", [true]);

                            if (that._active) {
                                clearTimeout(that._timer);
                            }
                        }
                    }
                    setTimeout(function () {
                        that._mouseHandled = false;
                    }, 0);
                }
            };


            let currentTouchId,
                ghostBuster = 0; // Some Android browsers to not support preventDefault(), we need to do catch the ghost events

            let ghostBust = function (event) {
                if ((+new Date - ghostBuster) <= 500) { // Bust the ghost mouse events on old Android browsers
                    event.preventDefault();
                    return true;
                }
                return false;
            };

            this.$el.on('mousedown', 'li', function (event) {
                let $target = $(event.currentTarget);
                // Touches may give the wrong target, pointing to the hidden element beneath the ui-sortable-helper
                if ($target.hasClass('ui-sortable-helper') || $target.css('display') === 'none') return;
                event.preventDefault();
            }).on('mousedown', function (event) {
                let $target = $(event.currentTarget);
                // Touches may give the wrong target, pointing to the hidden element beneath the ui-sortable-helper
                if ($target.hasClass('ui-sortable-helper') || $target.css('display') === 'none') return;
                event.preventDefault();
            }).on('touchstart', 'li', function (event) {
                if (currentTouchId) return;
                let $target = $(event.currentTarget);
                // Touches may give the wrong target, pointing to the hidden element beneath the ui-sortable-helper
                if ($target.hasClass('ui-sortable-helper') || $target.css('display') === 'none') return;
                currentTouchId = event.originalEvent.changedTouches[0].identifier;

                $(this).mouseenter();

                let didScroll = false;
                let onScroll = function () {
                    didScroll = true;
                };
                let onTouchCancel = function () {
                    that._unhookTouchEvents();
                };

                let trackedScrolling = $(this).parents().add(window);
                trackedScrolling.on('scroll.dropdownmenu', onScroll);

                $(window).on('touchcancel.dropdownmenu', onTouchCancel);

                that._unhookTouchEvents = function () {
                    currentTouchId = null;
                    that.$el.off('touchend', 'li');
                    trackedScrolling.off('scroll.dropdownmenu', onScroll);
                    $(window).off('touchcancel.dropdownmenu', onTouchCancel);
                    that._unhookTouchEvents = null;
                };

                that.$el.on('touchend', 'li', function (event) {
                    let touch = _.find(event.originalEvent.changedTouches, function (touch) {
                        return touch.identifier === currentTouchId;
                    });
                    if (!touch) return;

                    that._unhookTouchEvents();

                    if (!didScroll && !event.isDefaultPrevented()) {
                        ghostBuster = +new Date;
                        itemUpAction(event);
                        event.preventDefault();
                    }
                });

            }).on('mouseup', 'li', function (event) {
                if (event.which !== 1) return;

                if (ghostBust(event)) return;

                itemUpAction(event);
            }).on('mouseenter', 'li', function (event) {

                if (ghostBust(event)) return;

                let $target = $(event.currentTarget);
                if ($target.hasClass('ui-sortable-helper')) return;
                $target.siblings()
                    .children(".dropdown-menu-item-active")
                    .removeClass("dropdown-menu-item-active");
                that._focus(event, $target);
            }).on('focus', function (event, keepActiveItem) {
                let $target = $(event.currentTarget);
                if ($target.hasClass('ui-sortable-helper')) return;
                let $item = that._active || // active item
                    that.$el.children("li").eq(0); // or the first item
                if (!keepActiveItem) {
                    that._focus(event, $item);
                }
            }).on('blur', function (event) {
                let $target = $(event.currentTarget);
                if ($target.hasClass('ui-sortable-helper')) return;
                _.delay(function () {
                    if (!$.contains(that.el, document.activeElement)) {
                        that._delayBlur();
                    }
                });
            }).on('keydown keypress', function (event) {
                let $target = $(event.currentTarget);
                if ($target.hasClass('ui-sortable-helper')) return;
                that._keydown(event);
            });

            return that;
        },

        /*render: function () {

         return this;
         },*/

        remove: function () {
            let that = this;

            clearTimeout(that._timer);
            clearTimeout(that._filterTimer);
            if (this._onDocumentMouseDown) {
                $(document).off('mousedown', this._onDocumentMouseDown);
                this._onDocumentMouseDown = null;
            }
            that.o = null;
            that._active = null;
            if (this._unhookTouchEvents) {
                this._unhookTouchEvents();
            }

            DropDownMenu.__super__.remove.apply(this, arguments);
        },

        _keydown: function (event) {
            let that = this, o = that.o;

            if (o.keyDownHandler && o.keyDownHandler.call(this, event)) {
                return;
            }

            let regex, preventDefault = true;

            switch (event.which) {

                case KeyCodes.PAGE_UP:
                    this.previousPage(event);
                    break;

                case KeyCodes.PAGE_DOWN:
                    this.nextPage(event);
                    break;

                case KeyCodes.HOME:
                    this._move('first', event);
                    break;

                case KeyCodes.END:
                    this._move('last', event);
                    break;

                case KeyCodes.UP:
                    this.previous(event);
                    break;

                case KeyCodes.DOWN:
                    this.next(event);
                    break;

                case KeyCodes.ENTER:
                case KeyCodes.SPACE:
                case KeyCodes.TAB:
                    this.triggerItemSelection(event);
                    if (event.which === KeyCodes.SPACE) {
                        this.toggleFocusedItem();
                    }
                    if (event.which === KeyCodes.TAB) {
                        preventDefault = false;
                    }
                    break;

                case KeyCodes.ESCAPE:
                    this.hide();
                    break;

                default:
                    if (event.type === 'keydown') return;
                    preventDefault = false;
                    let key = event.keyCode;
                    // Keydown: Keycodes.... String.fromCharCode((96 <= key && key <= 105) ? key - 48 : key);
                    // Keypress: Character codes String.fromCharCode(key)
                    let character = String.fromCharCode(key);

                    clearTimeout(this._filterTimer);

                    // Accumulate text to find from keystrokes
                    let keyword = (this._previousFilter || '') + character;

                    regex = new RegExp("^" + escapeRegex(keyword), 'i');

                    let matchIndices = [];
                    let item;

                    // These are all the possible matches
                    for (let i = 0, count = this._items.length; i < count; i++) {
                        item = this._items[i];
                        if (regex.test(item.label)) {
                            matchIndices.push(i);
                        }
                    }

                    // Did we find anything?
                    if (!matchIndices.length) {
                        // No... So start over with this character as the only one.
                        keyword = character;
                        regex = new RegExp("^" + escapeRegex(keyword), 'i');

                        for (let i = 0, count = this._items.length; i < count; i++) {
                            item = this._items[i];
                            if (regex.test(item.label)) {
                                matchIndices.push(i);
                            }
                        }
                    }

                    let activeIndex = this._active ? this._items.indexOf(this._active.data('item')) : -1;
                    let matchIndex = -1;

                    // Find a match *after* the active item
                    for (let i = 0, count = matchIndices.length; i < count; i++) {
                        if (matchIndices[i] >= activeIndex) {
                            matchIndex = matchIndices[i];
                            break;
                        }
                    }

                    // Find a match from the beginning.
                    if (matchIndex === -1 && matchIndices.length) {
                        matchIndex = matchIndices[0];
                    }

                    if (matchIndex > -1) {
                        let next = this.itemElementAtIndex(matchIndex);
                        let $next = next ? $(next) : null;
                        this._focus(event, $next, matchIndex);

                        if (!this.isVisible()) {
                            this.triggerItemSelection(event);
                        }

                        // Record the last filter used
                        this._previousFilter = keyword;

                        // Clear the last filter - a second from now.
                        this._filterTimer = _.delay(_.bind(function () {
                            delete this._previousFilter;
                        }, this), 1000);

                    } else {
                        delete this._previousFilter;
                    }
            }

            if (preventDefault) {
                event.preventDefault();
            }
        },

        _focus: function (event, $itemElement, itemIndex) {
            this.blurFocusedItem(event && event.type === 'focus');

            this._scrollIntoView($itemElement, itemIndex);

            let active = $itemElement ? $itemElement : $(this.itemElementAtIndex(itemIndex));
            if (active.length && active.data('item').nointeraction) {
                active = $([]);
            }

            if (active.length) {
                this._active = active.addClass('dropdown-menu-item-focus');
                this.trigger('focus', this._active.data('item'), event);
            } else {
                // This could happen if trying to focus a grouped item
                if (this._active) {
                    this.blurFocusedItem();
                }
            }
        },

        _scrollIntoView: function ($itemElement, itemIndex) {

            let $el = this.$el;
            let offset, scroll, elementHeight, itemHeight;

            if (this._hasScroll()) {

                scroll = $el.scrollTop();

                if (!$itemElement && this._isVirtual) {
                    itemHeight = this._lastMeasureItemHeight;
                    offset = itemIndex * itemHeight + (itemIndex > 0 ? itemIndex * this._lastMeasureItemYSpacing : 0);
                    offset -= scroll;
                } else {
                    if (!$itemElement) {
                        $itemElement = $(this.itemElementAtIndex(itemIndex));
                    }
                    let borderTop = parseFloat($el.css("border-top-width")) || 0;
                    let paddingTop = parseFloat($el.css("padding-top")) || 0;
                    offset = $itemElement.offset().top - $el.offset().top + borderTop + paddingTop;
                    itemHeight = $itemElement.height();
                }

                elementHeight = $el.height();

                if (offset < 0) {
                    $el.scrollTop(scroll + offset);
                    if (this._isVirtual) {
                        this._updateVirtualViewportScroll();
                    }
                } else if (offset + itemHeight > elementHeight) {
                    $el.scrollTop(scroll + offset - elementHeight + itemHeight);
                    if (this._isVirtual) {
                        this._updateVirtualViewportScroll();
                    }
                }
            }
        },

        blurFocusedItem: function (fromFocus) {
            if (!fromFocus) {
                clearTimeout(this._timer);
            }

            if (!this._active) {
                return;
            }

            let active = this._active.removeClass('dropdown-menu-item-focus');
            this._active = null;

            this.trigger('blur', active.data('item'));
        },

        _delayBlur: function () {
            let that = this, o = that.o;

            clearTimeout(that._timer);
            if (o) {
                that._timer = _.delay(function () {
                    that.blurFocusedItem();
                }, o.itemBlurDelay);
            }
        },

        _move: function (direction, event) {

            let next, nextIndex, directionUp = false;

            if (direction === 'first') {
                nextIndex = 0;
                directionUp = false;
            } else if (direction === 'last') {
                nextIndex = this._items.length - 1;
                directionUp = true;
            } else if (direction === 'prev') {
                if (!this._active) return this._move('last', event);

                nextIndex = this._items.indexOf(this._active.data('item')) - 1;
                if (nextIndex === -1) {
                    nextIndex = this._items.length - 1;
                }

                directionUp = true;
            } else if (direction === 'next') {
                if (!this._active) return this._move('first', event);

                nextIndex = this._items.indexOf(this._active.data('item')) + 1;
                if (nextIndex === this._items.length) {
                    nextIndex = 0;
                }

                directionUp = false;
            } else if (direction === 'prev_page' || direction === 'next_page') {

                if (!this._active) {
                    return this._move(direction === 'prev_page' ? 'prev' : 'next', event);
                }

                let base, height;

                if ((direction === 'prev_page' && this.isFirstItem()) ||
                    (direction === 'next_page' && this.isLastItem())) return;

                if (this._hasScroll()) {

                    if (this._isVirtual) {

                        let viewportHeight = this.$el.innerHeight();
                        let visibleItemCount = Math.ceil((viewportHeight + this._lastMeasureItemYSpacing)
                            / (this._lastMeasureItemHeight + this._lastMeasureItemYSpacing));

                        nextIndex = this._items.indexOf(this._active.data('item'));

                        if (direction === 'prev_page') {
                            nextIndex -= visibleItemCount;
                        } else {
                            nextIndex += visibleItemCount;
                        }

                        if (nextIndex < 0) {
                            nextIndex = 0;
                        } else if (nextIndex >= this._items.length) {
                            nextIndex = this._items.length;
                        }

                    } else {
                        base = this._active.offset().top;
                        height = this.$el.height();
                        this._active.nextAll('li').each(function () {
                            next = $(this);
                            if (direction === 'prev_page') {
                                return next.offset().top - base + height > 0;
                            } else {
                                return next.offset().top - base - height < 0;
                            }
                        });

                        if (next) {
                            nextIndex = this._items.indexOf(next.data('item'));
                        }
                    }

                } else {
                    return this._move(direction === 'prev_page' ? 'first' : 'last', event);
                }

                directionUp = direction === 'prev_page';
            } else {
                return;
            }

            let itemCount = this._items.length;

            if (nextIndex >= itemCount) {
                return;
            }

            let item = this._items[nextIndex];
            // noinspection UnnecessaryLocalVariableJS
            let startedAtIndex = nextIndex;

            while (item && item.nointeraction) {
                if (directionUp) {
                    nextIndex--;
                    if (nextIndex === -1) {
                        nextIndex = itemCount;
                    }
                } else {
                    nextIndex++;
                    if (nextIndex === itemCount) {
                        nextIndex = 0;
                    }
                }

                item = this._items[nextIndex];

                if (nextIndex === startedAtIndex) {
                    break;
                }
            }

            next = this.itemElementAtIndex(nextIndex);
            if (next) {
                next = $(next);
            }

            this._focus(event, next, nextIndex);

            if (!this.isVisible()) {
                this.triggerItemSelection(event);
            }
        },

        nextPage: function (event) {
            this._move('next_page', event);
        },

        previousPage: function (event) {
            this._move('prev_page', event);
        },

        _hasScroll: function () {
            return this.$el.outerHeight() < this.el.scrollHeight;
        },

        toggleFocusedItem: function () {
            let that = this, o = that.o;

            if (that._active && o.multi) {
                let item = this._active.data('item');
                if (item.nocheck || item.nointeraction) return this;

                item.checked = !item.checked;
                this._active.toggleClass('dropdown-menu-item-checked', item.checked);
                this.trigger('check', {item: item});

                that._updateGroupStateForItem(item);
            }
            return this;
        },

        _updateGroupStateForItem: function (item) {
            let that = this, o = that.o;

            if (o.multi && o.autoCheckGroupChildren) {

                let items, groupIndex, itemIndex;

                if (item.group) {
                    // Now loop through children below the group

                    items = that._items;
                    groupIndex = items.indexOf(item);

                    let affectedItems = 0;

                    for (let i = groupIndex + 1, len = items.length; i < len; i++) {
                        let next = items[i];

                        // Hit the next group, break out
                        if (next.group || (!next.child && items[i - 1].child))
                            break;

                        // No change, skip
                        if (!!next.checked === item.checked)
                            continue;

                        // Update state
                        next.checked = item.checked;

                        affectedItems++;

                        // Update DOM
                        let nextEl = that.itemElementAtIndex(i);
                        if (nextEl) {
                            $(nextEl).toggleClass('dropdown-menu-item-checked', item.checked);
                        }

                        // Fire event
                        this.trigger('check', {item: next, isCheckingGroup: true});
                    }

                    // Fire event
                    this.trigger('groupcheck', {item: item, affectedItems: affectedItems});
                } else if (o._groupCount > 0) {
                    items = that._items;
                    itemIndex = items.indexOf(item);
                    groupIndex = -1;

                    // Find the group index
                    for (let i = itemIndex - 1; i >= 0; i--) {
                        if (items[i].group) {
                            groupIndex = i;
                            break;
                        }
                    }

                    if (groupIndex > -1) {
                        that._updateGroupCheckedState(groupIndex, true);
                    }
                }
            }

            return that;
        },

        _updateGroupCheckedState: function (groupIndex, fireEvents) {
            let that = this, o = that.o;

            if (o.multi && o.autoCheckGroupChildren && groupIndex > -1) {

                let items = that._items;
                let groupItem = items[groupIndex];
                if (!groupItem || !groupItem.group) return that;

                let item, hasChecked = false, hasUnchecked = false;

                for (let i = groupIndex + 1, len = items.length; i < len; i++) {
                    item = items[i];

                    // Hit the next group, break out
                    if (item.group || (!item.child && items[i - 1].child))
                        break;

                    if (item.checked) {
                        hasChecked = true;
                    } else if (!item.checked) {
                        hasUnchecked = true;
                    }
                }

                // See if we need to update the group
                let shouldCheckGroup = hasChecked && !hasUnchecked;
                if (!!groupItem.checked !== shouldCheckGroup) {
                    // Update state
                    groupItem.checked = shouldCheckGroup;

                    // Update DOM
                    let nextEl = that.itemElementAtIndex(groupIndex);
                    if (nextEl) {
                        $(nextEl).toggleClass('dropdown-menu-item-checked', groupItem.checked);
                    }

                    if (fireEvents) {
                        // Fire event
                        this.trigger('check', {item: groupItem});
                    }
                }
            }

            return that;
        },

        triggerItemSelection: function (event) {
            this._active = this._active || $(event.target).closest("li");
            let item = this._active.data('item');

            if (item.nointeraction) {
                return false;
            }

            this.trigger('select', {item: item, originalEvent: event});

            return true;
        },

        /**
         * @private
         * @returns {DropDownMenu} self
         */
        _measureItem: function () {
            let that = this, o = that.o;

            let longestLabel = that._lastMeasureLongestLabel || 1,
                longestLabelText = that._lastMeasureLongestLabelText || '';

            if (this._lastMeasureItemCount !== this._items.length) {
                for (let i = 0, items = this._items, count = items.length;
                     i < count;
                     i++) {

                    let text = items[i].label;
                    let length = text.length;
                    if (length > longestLabel) {
                        longestLabel = length;
                        longestLabelText = text;
                    }
                }

                this._lastMeasureItemCount = this._items.length;
                this._lastMeasureLongestLabel = longestLabel;
                this._lastMeasureLongestLabelText = longestLabelText;
            }

            let itemData = {
                label: longestLabelText
                , short_label: 'Measure'
                , value: 'Measure',
            };

            let $item;

            if (o.itemTemplate) {
                let itemHtml = o.itemTemplate(itemData);
                if (itemHtml && !(itemHtml instanceof $)) {
                    $item = $(itemHtml);
                } else {
                    $item = itemHtml;
                }
            }

            if (!$item) {
                $item = $('<li>').append(
                    $('<span class="dropdown-menu-item-label">')
                        .text(itemData.label),
                );
            }

            $item
                .attr({role: 'menuitem', 'aria-hidden': 'true'})
                .data('item', itemData);

            this.trigger('renderitem', $item);

            $item.appendTo(this.$el);

            let $item2 = $('<li>')
                .html($item.html())
                .appendTo(this.$el);

            if (o.estimateWidth || this._isVirtual) {
                this._lastMeasureItemWidth = $item.outerWidth();
            }

            if (o.estimateHeight || this._isVirtual) {
                this._itemHeightMeasureChanged = false;
                let itemHeightMeasure = parseFloat($item.css('height'));
                let itemYSpacingMeasure = Math.max(
                    parseFloat($item.css('margin-bottom')) || 0,
                    parseFloat($item2.css('margin-top')) || 0,
                );
                this._itemHeightMeasureChanged =
                    itemHeightMeasure !== this._lastMeasureItemHeight ||
                    itemYSpacingMeasure !== this._lastMeasureItemYSpacing;

                this._lastMeasureItemHeight = itemHeightMeasure;
                this._lastMeasureItemYSpacing = itemYSpacingMeasure;
            }

            $item.remove();
            $item2.remove();

            return this;
        },

        addItem: function (value, label, shortLabel, checked, atIndex) {
            let that = this, o = that.o;

            if (typeof shortLabel === 'boolean') {
                atIndex = checked;
                checked = shortLabel;
                shortLabel = null;
            }

            let item = {
                value: value,
                label: label,
                short_label: shortLabel,
            };

            if (o.multi) {
                item.checked = !!checked;
            }

            return that.addItems([item], atIndex);
        },

        /**
         * Adds items to the menu and renders
         * @param {Array<{
         *  label: String,
         *  short_label: String,
         *  value: *,
         *  checked: Boolean?,
         *  group: Boolean=false,
         *  child: Boolean=false,
         *  nocheck: Boolean=false,
         *  nointeraction: Boolean=false
         * }>} itemsToAdd The items to add. These are copied.
         * @param atIndex The index to insert at (or -1)
         * @returns {DropDownMenu}
         */
        addItems: function (itemsToAdd, atIndex) {
            let that = this, o = that.o;

            let el = that.el, $el = that.$el;
            let isMulti = o.multi;
            let items = that._items;

            if (atIndex == null || atIndex < 0 || atIndex >= this._items.length) {
                atIndex = -1;
            }

            // Determine if the list is virtual or not
            this._determineVirtualMode(items.length + itemsToAdd.length);

            for (let i = 0, count = itemsToAdd.length; i < count; i++) {
                let oitem = itemsToAdd[i];
                //noinspection PointlessBooleanExpressionJS
                let item = {
                    label: oitem.label
                    , short_label: oitem.short_label || oitem.label
                    , value: oitem.value
                    , nocheck: !!oitem.nocheck
                    , nointeraction: !!oitem.nointeraction,
                };

                if (isMulti) {
                    item.checked = oitem.checked;
                }

                if (oitem.group) {
                    item.group = true;
                    o._groupCount++;
                }

                if (oitem.child) {
                    // This is used for setting a child class,
                    // But can be used to determine that current item is not part of above group,
                    //   mainly where the groups are oddly sorted.
                    item.child = true;
                }

                // Add the item to the list of them
                if (atIndex !== -1) {
                    items.splice(atIndex, 0, item);
                } else {
                    items.push(item);
                }

                // For a non-virtual list, we will render the item immediately
                if (!this._isVirtual) {
                    let $item = this._renderItem(item);

                    if (atIndex !== -1) {
                        $item.insertBefore(el.childNodes[atIndex]);
                    } else {
                        $el.append($item);
                    }
                }

                if (atIndex !== -1) {
                    atIndex++;
                }
            }

            // Render if necessary
            if (this._isVirtual) {

                this._updateVirtualViewportSize()
                    ._updateVirtualViewportScroll();

            }

            return this;
        },

        /**
         * Determines whether the list should be in virtual mode, depending on the item count.
         * @param {Number?} targetItemCount - item count we are expecting. Defaults to current item count;
         * @returns {DropDownMenu}
         * @private
         */
        _determineVirtualMode: function (targetItemCount) {
            let that = this, o = that.o;

            let items = that._items;
            if (targetItemCount === undefined) {
                targetItemCount = items.length;
            }

            if (targetItemCount >= o.virtualMinItems) {
                if (!that._isVirtual) {
                    that.$el.empty();
                    that._$virtualWrapper = $('<div>').appendTo(that.$el);
                    that._virtualWrapper = that._$virtualWrapper[0];
                    that._virtualVisibleTop = Infinity;
                    that._virtualVisibleBottom = -Infinity;
                    that._updateVirtualViewportSize();
                    that._updateVirtualViewportScroll();
                    that.$el.on('scroll.virtuallist', function () {
                        that._updateVirtualViewportScroll();
                    });
                    that._isVirtual = true;
                }
            } else {
                if (that._isVirtual) {
                    that._$virtualWrapper.remove();
                    delete that._$virtualWrapper;
                    delete that._virtualWrapper;
                    that.$el.off('scroll.virtuallist');

                    for (let i = 0, itemCount = items.length; i < itemCount; i++) {
                        that._renderItem(items[i]).appendTo(that.$el);
                    }
                    that._isVirtual = false;
                }
            }

            return that;
        },

        _updateVirtualViewportSize: function () {

            if (this._lastMeasureItemHeight === undefined) return this;

            // The other method is measuring one item and multiplying:
            let itemCount = this._items.length;
            let fullHeight =
                (itemCount * (this._lastMeasureItemHeight || 0)) +
                (Math.max(itemCount - 1, 0) * (this._lastMeasureItemYSpacing || 0));

            if (parseFloat(this._virtualWrapper.style.height) !== fullHeight) {
                this._$virtualWrapper.css('height', fullHeight);
            }
            if (parseFloat(this._virtualWrapper.style.width) !== fullHeight) {
                this._$virtualWrapper.css('width', this._lastMeasureItemWidth);
            }

            return this;
        },

        _updateVirtualViewportScroll: function () {
            let that = this;

            if (this._lastMeasureItemHeight === undefined) return this;

            let EXTRA_ITEMS_BUFFER = 4;

            let itemHeight = that._lastMeasureItemHeight;
            let itemSpacing = that._lastMeasureItemYSpacing;
            let viewportHeight = that.$el.innerHeight();
            let scrollTop = that.el.scrollTop;

            let visibleItemCount = Math.ceil((viewportHeight + that._lastMeasureItemYSpacing)
                / (that._lastMeasureItemHeight + that._lastMeasureItemYSpacing));
            if (!isFinite(visibleItemCount) || isNaN(visibleItemCount)) {
                visibleItemCount = 0;
            }

            let virtualWrapper = that._virtualWrapper;

            // Calculate items in viewport
            let viewportTop = Math.floor((scrollTop + that._lastMeasureItemYSpacing)
                / (that._lastMeasureItemHeight + that._lastMeasureItemYSpacing));

            if (isNaN(viewportTop)) {
                viewportTop = scrollTop + that._lastMeasureItemYSpacing;
            }

            let viewportBottom = viewportTop + visibleItemCount + EXTRA_ITEMS_BUFFER;
            viewportTop -= EXTRA_ITEMS_BUFFER;
            if (viewportTop < 0) viewportTop = 0;
            if (viewportBottom >= that._items.length) viewportBottom = that._items.length;

            let oldViewportTop = that._virtualVisibleTop;
            let oldViewportBottom = that._virtualVisibleBottom;

            // Clean up items from the top
            for (let i = oldViewportTop; i < Math.min(viewportTop, oldViewportBottom); i++) {
                if (i < 0) continue;
                virtualWrapper.removeChild(virtualWrapper.firstChild);
            }

            // Clean up items from the bottom
            for (let i = Math.max(oldViewportTop, viewportBottom); i < oldViewportBottom; i++) {
                virtualWrapper.removeChild(virtualWrapper.lastChild);
            }

            // Add missing visible items
            let firstToAdd = viewportTop >= oldViewportTop
                ? Math.max(viewportTop, oldViewportBottom)
                : viewportTop;
            let lastToAdd = viewportBottom > oldViewportBottom
                ? viewportBottom
                : Math.min(viewportBottom, oldViewportTop);
            let item, row, $row, before;
            for (let i = firstToAdd; i < lastToAdd; i++) {
                item = that._items[i];

                $row = that._renderItem(item);
                row = $row[0];

                // Position it absolutely
                row.style.position = 'absolute';
                row.style.top = (i * itemHeight + (i > 0 ? i * itemSpacing : 0)) + 'px';
                row.style.left = '0';
                row.style.right = '0';

                // Add it in the right place
                before = virtualWrapper.childNodes[i - viewportTop];
                if (before) {
                    $row.insertBefore(before);
                } else {
                    $row.appendTo(virtualWrapper);
                }
            }

            that._virtualVisibleTop = viewportTop;
            that._virtualVisibleBottom = viewportBottom;

            return that;
        },

        _renderItem: function (item) {
            let that = this, o = that.o;

            let $item;

            if (o.itemTemplate) {
                let itemHtml = o.itemTemplate(item);
                if (itemHtml && !(itemHtml instanceof $)) {
                    $item = $(itemHtml);
                } else {
                    $item = itemHtml;
                }
            }

            if (!$item) {
                $item = $('<li>').append(
                    $('<span class="dropdown-menu-item-label">')
                        .text(item.label),
                );

                if (item.nocheck) {
                    $item.addClass('dropdown-menu-item-multi-nocheck');
                } else if (o.multi) {
                    $item.addClass('dropdown-menu-item-multi');
                    $item.prepend('<span class="checkbox">');
                    if (item.checked) {
                        $item.addClass('dropdown-menu-item-checked');
                    }
                }

                if (item.group) {
                    $item.addClass('dropdown-menu-item-group');
                }

                if (item.child) {
                    $item.addClass('dropdown-menu-item-child');
                }

                if (item.nointeraction) {
                    $item.addClass('dropdown-menu-item-nointeraction');
                }
            }

            $item
                .attr({role: 'menuitem'})
                .data('item', item);

            if (o.capturesFocus) {
                $item.tabIndex = -1;
            }

            this.trigger('renderitem', $item);

            return $item;
        },

        updateItem: function (value, label, shortLabel) {
            let that = this;

            shortLabel = shortLabel || label;

            // Look for the proper item
            let itemIndex = that.itemIndexByValue(value);
            if (itemIndex < -1) return that;

            let item = that.itemAtIndex(itemIndex);
            item.label = label;
            item.short_label = shortLabel || label;

            if (that._isVirtual) {
                // Is the item actually rendered?
                if (itemIndex >= that._virtualVisibleTop && itemIndex < that._virtualVisibleBottom) {
                    $(that._virtualWrapper.childNodes[itemIndex - that._virtualVisibleTop])
                        .find('>span:first-child')
                        .text(label);
                }
            } else {
                $(that.el.childNodes[itemIndex])
                    .find('>span:first-child')
                    .text(label);
            }

            return this;
        },

        removeItem: function (value, label) {
            let that = this, o = that.o;

            // Look for the proper item
            let itemIndex = that.itemIndexByValueOrLabel(value, label);
            if (itemIndex < -1) return this;

            let spliced = that._items.splice(itemIndex, 1);
            if (spliced[0].group) {
                o._groupCount--;
            }

            if (this._isVirtual) {
                // Is the item actually rendered?
                if (itemIndex >= this._virtualVisibleTop
                    && itemIndex < this._virtualVisibleBottom) {

                    // Remove the item
                    $(this._virtualWrapper.childNodes[itemIndex - this._virtualVisibleTop]).remove();

                    // Adjust the range
                    if (itemIndex === this._virtualVisibleTop) {
                        this._virtualVisibleTop++;
                    } else {
                        this._virtualVisibleBottom--;
                    }

                    // refresh
                    this._updateVirtualViewportSize()
                        ._updateVirtualViewportScroll();
                }
            } else {
                $(this.el.childNodes[itemIndex]).remove();
            }

            return this;
        },

        removeAllItems: function () {
            let that = this, o = that.o;

            that._items.length = 0;
            o._groupCount = 0;

            if (this._isVirtual) {
                this._$virtualWrapper.empty();
                this._virtualVisibleTop = Infinity;
                this._virtualVisibleBottom = -Infinity;

                // refresh
                this._updateVirtualViewportSize()
                    ._updateVirtualViewportScroll();
            } else {
                this.$el.empty();
            }

            return this;
        },

        itemByValue: function (value) {

            let itemIndex = this.itemIndexByValue(value);
            if (itemIndex < -1) return null;

            if (this._isVirtual) {
                // Is the item actually rendered?
                if (itemIndex >= this._virtualVisibleTop
                    && itemIndex < this._virtualVisibleBottom) {

                    return $(this._virtualWrapper.childNodes[itemIndex - this._virtualVisibleTop]);
                }
            } else {
                return $(this.el.childNodes[itemIndex]);
            }

            return null;
        },

        itemDataByValue: function (value) {

            for (let i = 0, count = this._items.length; i < count; i++) {
                let item = this._items[i];
                if (item.value === value) {
                    return item;
                }
            }

            return null;
        },

        itemIndexByValue: function (value) {

            for (let i = 0, count = this._items.length; i < count; i++) {
                let item = this._items[i];
                if (item.value === value) {
                    return i;
                }
            }

            return -1;
        },

        itemIndexByValueOrLabel: function (value, label) {

            for (let i = 0, count = this._items.length; i < count; i++) {
                let item = this._items[i];
                if (item.value === value || item.label === label) {
                    return i;
                }
            }

            return -1;
        },

        items: function () {
            return this._items.slice(0);
        },

        itemsReference: function () {
            return this._items;
        },

        itemCount: function () {
            return this._items.length;
        },

        itemAtIndex: function (index) {
            return this._items[index];
        },

        itemElementAtIndex: function (index) {

            if (this._isVirtual) {
                // Is the item actually rendered?
                if (index >= this._virtualVisibleTop
                    && index < this._virtualVisibleBottom) {

                    return this._virtualWrapper.childNodes[index - this._virtualVisibleTop];
                }
            } else {
                return this.el.childNodes[index];
            }

            return null;
        },

        /**
         *
         * @param {Object?} positionOptions
         * @returns {DropDownMenu}
         * @public
         */
        repositionAndResize: function (positionOptions) {

            let that = this, o = that.o;

            if (!that.isVisible()) return that;

            let $window = $(window);

            let targetBox = {};

            let offset = positionOptions.targetOffset || positionOptions.$target.offset();
            targetBox.left = offset.left;
            targetBox.top = offset.top;
            targetBox.height = positionOptions.targetHeight == null
                ? positionOptions.$target.outerHeight()
                : positionOptions.targetHeight;
            targetBox.width = positionOptions.targetWidth == null
                ? positionOptions.$target.outerWidth()
                : positionOptions.targetWidth;
            targetBox.bottom = targetBox.top + targetBox.height;

            let viewport = {};
            viewport.top = $window.scrollTop();
            viewport.height = $window.height();
            viewport.bottom = viewport.top + viewport.height;

            let defaultVerticalDirection = (positionOptions.position || '').split(/ |,/)[1] === 'bottom' ? 'above' : 'below';

            // Reset dropdown width
            that.$el.css('width', '');

            // Make estimations
            if (o.estimateWidth ||
                o.estimateHeight ||
                that._isVirtual) {

                that._measureItem();
            }

            // Calculate virtual viewport size
            if (that._isVirtual && that._itemHeightMeasureChanged) {
                that._updateVirtualViewportSize();
            }

            // Now set the width of the dropdown
            if (positionOptions.updateWidth) {
                that._updateWidth(positionOptions);
            }

            // How much space is there above, and how much below?
            let roomAbove = targetBox.top - viewport.top;
            let roomBelow = viewport.bottom - targetBox.bottom;

            // Calculate height for dropdown

            let maxViewHeight;

            let isBoxing = that.$el.css('box-sizing') === 'border-box';
            let verticalPadding = (parseFloat(that.$el.css('padding-top')) || 0) +
                (parseFloat(that.$el.css('padding-bottom')) || 0);
            let verticalBorderWidth = (parseFloat(that.$el.css('border-top-width')) || 0) +
                (parseFloat(that.$el.css('border-bottom-width')) || 0);

            if (o.estimateHeight || that._isVirtual) {
                // One method is measuring one item and multiplying.
                // This is good for virtual lists, or for avoiding page re-layout just to measure everything.
                let itemCount = that._items.length;
                maxViewHeight =
                    (itemCount * (that._lastMeasureItemHeight || 0)) +
                    (Math.max(itemCount - 1, 0) * (that._lastMeasureItemYSpacing || 0));

                maxViewHeight += verticalPadding + verticalBorderWidth;

            } else {
                // Another method to calculate height is measuring the whole thing at once.
                // This causes relayout of course.
                that.$el.css({
                    'height': ''
                    , 'top': -9999,
                });
                maxViewHeight = Math.max(that.$el.height(), that.el.scrollHeight);
                maxViewHeight += verticalPadding + verticalBorderWidth;
            }

            // Consider css max-height

            let maxHeight = parseFloat(that.$el.css('max-height'));
            if (!isNaN(maxHeight)) {
                if (!isBoxing) {
                    maxHeight += verticalPadding + verticalBorderWidth;
                }

                maxViewHeight = Math.min(maxViewHeight, maxHeight);
            }

            // Figure out the direction

            let enoughRoomAbove = roomAbove >= maxViewHeight;
            let enoughRoomBelow = roomBelow >= maxViewHeight;

            let newDirection = that._currentDirection || defaultVerticalDirection;
            if (newDirection === 'above' && !enoughRoomAbove && enoughRoomBelow) {
                newDirection = 'below';
            } else if (newDirection === 'below' && !enoughRoomBelow && enoughRoomAbove) {
                newDirection = 'above';
            } else if (enoughRoomAbove && enoughRoomBelow) {
                if (newDirection !== defaultVerticalDirection &&
                    ((defaultVerticalDirection === 'above' && roomAbove >= roomBelow) ||
                        (defaultVerticalDirection === 'below' && roomBelow >= roomAbove))) {
                    newDirection = defaultVerticalDirection;
                }
            } else if (!enoughRoomAbove && !enoughRoomBelow) {
                if (roomAbove > roomBelow) {
                    newDirection = 'above';
                } else if (roomBelow > roomAbove) {
                    newDirection = 'below';
                }
            }
            that._currentDirection = newDirection;

            // Figure out that final view size
            let viewSize = {
                width: that.$el.outerWidth()
                , height: Math.min(maxViewHeight, Math.max(roomAbove, roomBelow, 0)),
            };

            let isTargetRtl = positionOptions.targetRtl !== undefined ?
                positionOptions.targetRtl :
                positionOptions.$target == null ? false : positionOptions.$target.css('direction') === 'rtl';
            let isRtlDocument = $(document.documentElement).css('direction') === 'rtl';

            let anchor = calcAnchor(positionOptions.$target, positionOptions.anchor, targetBox, isTargetRtl);
            let position = calcAnchor(that.$el, positionOptions.position, viewSize, isTargetRtl);

            let positionYPos = (positionOptions.position || '').split(/ |,/)[1];

            // If it's not in the direction that the user expected, invert it
            let invertYPos =
                (positionYPos === 'top' && newDirection === 'above') ||
                (positionYPos === 'bottom' && newDirection === 'below') ||
                (positionYPos !== 'bottom' && positionYPos !== 'top' && newDirection === 'above');

            if (invertYPos) {
                position.top = viewSize.height - position.top;
                anchor.top = targetBox.height - anchor.top;
            }

            let viewCss = {
                'position': 'absolute'
                , 'left': targetBox.left
                , 'top': targetBox.top,
            };

            if (isRtlDocument) {
                viewCss.left -= document.documentElement.clientWidth - document.documentElement.scrollWidth;
            }

            viewCss.left += anchor.left - position.left;
            viewCss.top += anchor.top - position.top;

            if (positionOptions.offset) {
                if (positionOptions.offset.y) {
                    if (invertYPos) {
                        viewCss.top -= positionOptions.offset.y;
                    } else {
                        viewCss.top += positionOptions.offset.y;
                    }
                }

                if (positionOptions.offset.x) {
                    let rtl = this.$el.css('direction') === 'rtl';
                    viewCss.left += rtl
                        ? -positionOptions.offset.x
                        : positionOptions.offset.x;
                }
            }

            // Constrain to the window if required
            if (o.constrainToWindow) {
                let scrollLeft =
                    (window.pageXOffset !== undefined) ?
                        window.pageXOffset :
                        (document.documentElement || document.body.parentNode || document.body).scrollLeft;
                scrollLeft = Math.abs(scrollLeft);
                if (isRtlDocument) {
                    scrollLeft = document.documentElement.scrollWidth - scrollLeft - document.documentElement.clientWidth;
                }

                let minX = scrollLeft,
                    maxX = document.documentElement.clientWidth + scrollLeft - viewSize.width;

                if (isRtlDocument) {
                    if (viewCss.left < minX) {
                        viewCss.left = minX;
                    }
                    if (viewCss.left > maxX) {
                        viewCss.left = maxX;
                    }
                } else {
                    if (viewCss.left > maxX) {
                        viewCss.left = maxX;
                    }
                    if (viewCss.left < minX) {
                        viewCss.left = minX;
                    }
                }
            }

            // Set position CSS
            that.$el.css(viewCss).outerHeight(viewSize.height);

            // Update the scroll position for virtual lists
            if (this._isVirtual) {
                this._updateVirtualViewportScroll();
            }

            // Update position classes
            if (positionOptions && positionOptions.$target) {
                this._$lastPositionTarget = positionOptions.$target;

                positionOptions.$target
                    .addClass('has-dropdown-menu')
                    .toggleClass('has-dropdown-menu__below', newDirection === 'below')
                    .toggleClass('has-dropdown-menu__above', newDirection === 'above');

                that.$el
                    .toggleClass('dropdown-menu-is-below', newDirection === 'below')
                    .toggleClass('dropdown-menu-is-above', newDirection === 'above');
            }

            return that;
        },

        /**
         *
         * @param {Object?} positionOptions
         * @returns {Number} new outer width
         * @private
         */
        _updateWidth: function (positionOptions) {
            let that = this, o = that.o;

            let targetWidth = 0;

            if (positionOptions) {
                // Measure target
                targetWidth = positionOptions.targetWidth;
                if (targetWidth == null) {
                    targetWidth = positionOptions.$target.outerWidth();
                }
            }

            let autoWidth = 0;
            if (!o.useExactTargetWidth) {
                if (o.estimateWidth || that._isVirtual) {
                    autoWidth = that._lastMeasureItemWidth;
                } else {
                    that.$el.css('width', ''); // Reset width
                    autoWidth = that.$el.outerWidth();
                }
            }

            let newOuterWidth = Math.max(autoWidth, targetWidth);

            that.$el.outerWidth(newOuterWidth);

            return newOuterWidth;
        },

        /**
         * Set the checked mode of a specific value.
         * @public
         * @param {*} value - array of values to check
         * @param {Boolean} checked - will the value be checked?
         * @returns {DropDownMenu} self
         */
        setItemChecked: function (value, checked) {

            checked = !!checked;

            let index = this.itemIndexByValue(value);
            if (index === -1) return this;

            let li = this.itemElementAtIndex(index);
            if (!li) return this;

            let $li = $(li);
            let item = $li.data('item');

            checked = checked && !item.nocheck;

            if (item.checked !== checked) {
                item.checked = checked;

                $li.toggleClass('dropdown-menu-item-checked', item.checked);

                this._updateGroupStateForItem(item);
            }

            return this;
        },

        /**
         * Set the checked values. All the other values will be unchecked,
         * @public
         * @param {Array<*>} values - array of values to check
         * @returns {DropDownMenu} self
         */
        setCheckedValues: function (values) {
            let that = this, o = that.o;
            let groupIndexes = [];

            for (let i = 0, count = this._items.length, item, li, $li, checked; i < count; i++) {
                item = this._items[i];
                checked = !item.nocheck && values.indexOf(item.value) !== -1;

                if (item.group) {
                    groupIndexes.push(i);
                }

                if (item.checked === checked) continue;

                item.checked = checked;

                li = this.itemElementAtIndex(i);
                if (!li) continue;

                $li = $(li);
                $li.toggleClass('dropdown-menu-item-checked', item.checked);
            }

            if (o.autoCheckGroupChildren) {
                for (let i = 0, count = groupIndexes.length; i < count; i++) {
                    that._updateGroupCheckedState(groupIndexes[i], false);
                }
            }

            return this;
        },

        /**
         * Get all checked values. Returns array of item values.
         * @public
         * @param {Boolean} excludeGroups=false Exclude group items
         * @returns {Array<*>} self
         */
        getCheckedValues: function (excludeGroups) {
            excludeGroups = excludeGroups && this.o._groupCount > 0;

            let values = [];

            for (let i = 0, count = this._items.length; i < count; i++) {
                let item = this._items[i];
                if (!item.checked) continue;
                if (excludeGroups && item.group) continue;
                values.push(item.value);
            }

            return values;
        },

        /**
         * Get all checked items. Returns array of actual item data object.
         * @public
         * @param {Boolean} excludeGroups=false Exclude group items
         * @returns {Array<{
         *  label: String,
         *  short_label: String,
         *  value: *,
         *  checked: Boolean?,
         *  group: Boolean=false,
         *  child: Boolean=false,
         *  nocheck: Boolean=false,
         *  nointeraction: Boolean=false,
         * }>} self
         */
        getCheckedItems: function (excludeGroups) {
            excludeGroups = excludeGroups && this.o._groupCount > 0;

            let items = [];

            for (let i = 0, count = this._items.length; i < count; i++) {
                let item = this._items[i];
                if (!item.checked) continue;
                if (excludeGroups && item.group) continue;
                items.push(item);
            }

            return items;
        },

        prepareShow: function (/*event*/) {
            let that = this;
            that._mouseHandled = false;
            _.delay(_.bind(function () { // If it's from a click event, we do not want an immediate hide...
                if (!this._onDocumentMouseDown) {
                    $(document).on('mousedown', this._onDocumentMouseDown = function (event) {
                        if (!$(event.target).closest(".dropdown-menu").length) {
                            that._delayBlur();
                        }
                    });
                }
            }, this));
            that.$el.hide().appendTo(document.body);
            return this;
        },

        hide: function (/*event*/) {

            if (this._onDocumentMouseDown) {
                $(document).off('mousedown', this._onDocumentMouseDown);
                this._onDocumentMouseDown = null;
            }

            if (this.isVisible()) {
                this.$el.detach();
                this.blurFocusedItem();
                this.trigger('hide');
            }

            if (this._$lastPositionTarget) {
                this._$lastPositionTarget
                    .removeClass('has-dropdown-menu')
                    .removeClass('has-dropdown-menu__above')
                    .removeClass('has-dropdown-menu__below');
                delete this._$lastPositionTarget;
            }

            return this;
        },

        isVisible: function () {
            return this.$el[0].parentNode && this.$el.css('display') !== 'none';
        },

        hasFocusedItem: function () {
            return !!this._active;
        },

        setFocusedItemAtIndex: function (itemIndex) {
            if (this._active) {
                this._active.removeClass('dropdown-menu-item-focus');
                this._active = null;
            }

            let item = this._items[itemIndex];

            if (itemIndex > -1) {
                if (item.nointeraction) {
                    return this;
                }

                this._scrollIntoView(null, itemIndex);

                let $itemElement = $(this.itemElementAtIndex(itemIndex));
                if ($itemElement.length) {
                    this._active = $itemElement.addClass('dropdown-menu-item-focus');
                    this.trigger('focus', this._active.data('item'), event);
                }
            }

            return this;
        },

        setFocusedItem: function (item) {
            let itemIndex = -1;

            if (item) {
                if (item.nointeraction) return this;

                let value = (item && item.value !== undefined) ? item.value : item;
                let label = (item && item.label) ? item.label : value;

                itemIndex = this._items.indexOf(item);
                if (itemIndex === -1) {
                    itemIndex = this.itemIndexByValueOrLabel(value, label);
                }
            }

            return this.setFocusedItemAtIndex(itemIndex);
        },

        setFocusedValue: function (value) {
            return this.setFocusedItemAtIndex(this.itemIndexByValue(value));
        },

        next: function (event) {
            this._move('next', event);
        },

        previous: function (event) {
            this._move('prev', event);
        },

        isFirstItem: function () {
            return this._active
                && this._active.data('item') === this._items[0];
        },

        isLastItem: function () {
            return this._active
                && this._active.data('item') === this._items[this._items.length - 1];
        },
    },
);

export default DropDownMenu;
