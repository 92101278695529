import $ from 'jquery';
import _ from 'underscore';
import View from '../controls/View.js';
import CustomFields from '../shared_utils/CustomFields.js'
import PhoneNumberUtil from '../utils/PhoneNumberUtil.mjs';

const DefaultOptions = {
    clickablePhoneNumbers: false
};

const ContactViewArea = View.extend({

    className: 'contact-edit-area',

    initialize: function (options) {
        var that = this;

        that.options = _.extend({}, DefaultOptions, options);

        return that;
    },

    render: function () {
        var that = this;

        const i18n = this.options.i18n;
        const t = i18n.t;

        var contact = that.options.contact;

        that.$el.empty();

        var row = function (label, value, classes, htmlEncode) {
            if (htmlEncode === undefined) {
                htmlEncode = true;
            }
            var htmlValue = htmlEncode ? ((value || '') + '').toHtml().replace(/\n/g, '<br />') : value;
            return $('<tr>').append(
                $('<th>').append($('<div>').text(label + ':')),
                $('<td>').append($('<span class="value">').addClass(classes || '').html(htmlValue))
            );
        };
        var phoneRow = function (label, number, classes) {
            var htmlValue = ((number || '') + '').toHtml().replace(/\n/g, '<br />');
            if (htmlValue) {
                htmlValue = '<span class="icon"></span><span class="' + classes + '">' + htmlValue + '</span>';
            }

            var interactive = number && that.options.clickablePhoneNumbers;

            var $valueCell;
            var $row = $('<tr>').append(
                $('<th>').append($('<div>').text(label + ':')),
                $('<td>').append($valueCell = $('<span class="value phone-number">').toggleClass('interactive', interactive).html(htmlValue))
            );
            if (interactive) {
                $valueCell.click(function () {
                    that.trigger('call', number, contact['id']);
                });
            }
            return $row;
        };

        $('<div class="contact-edit-section">').append(
            $('<h2>').text(t('contact_fields.section_basic')),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    row(t('contact_fields.first_name'), contact['first_name']),
                    row(t('contact_fields.last_name'), contact['last_name']),
                    row(t('contact_fields.company'), contact['company'])
                )
            ),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    row(t('contact_fields.title'), contact['title']),
                    row(t('contact_fields.gender'), contact['gender'] ? t('genders', contact['gender']) || '' : ''),
                    row(t('contact_fields.website_url'), contact['website_url'], 'ltr ta-natural')
                )
            ),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    row(t('contact_fields.birthdate'), contact['birthdate'] ? i18n.formatDate(new Date(contact['birthdate']), t('date_formats.normal_date')) : ''),
                    row(t('contact_fields.idt'), contact['idt'])
                )
            )
        ).appendTo(that.$el);

        $('<div class="contact-edit-section">').append(
            $('<h2>').text(t('contact_fields.section_contact')),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    phoneRow(t('contact_fields.phone'), contact['phone'] ? PhoneNumberUtil.displayPhoneNumber(contact['phone']) : '', 'ltr ta-natural'),
                    phoneRow(t('contact_fields.phone2'), contact['phone2'] ? PhoneNumberUtil.displayPhoneNumber(contact['phone2']) : '', 'ltr ta-natural'),
                    phoneRow(t('contact_fields.phone3'), contact['phone3'] ? PhoneNumberUtil.displayPhoneNumber(contact['phone3']) : '', 'ltr ta-natural'),
                    phoneRow(t('contact_fields.phone4'), contact['phone4'] ? PhoneNumberUtil.displayPhoneNumber(contact['phone4']) : '', 'ltr ta-natural'),
                    row(t('contact_fields.fax'), contact['fax'] ? PhoneNumberUtil.displayPhoneNumber(contact['fax']) : '', 'ltr ta-natural'),
                    row(t('contact_fields.email'), contact['email'], 'ltr ta-natural')
                )
            ),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    $('<th>').append($('<h3>').text(t('contact_fields.address'))),
                    row(t('contact_fields.country'), contact['address_country']),
                    row(t('contact_fields.city'), contact['address_city']),
                    row(t('contact_fields.postal_code'), contact['address_postal_code']),
                    row(t('contact_fields.street'), contact['address_street']),
                    row(t('contact_fields.street_number'), contact['address_street_number'])
                )
            ),
            $('<div class="column column-33">').append(
                $('<table>').append(
                    $('<th>').append($('<h3>').text(t('contact_fields.address2'))),
                    row(t('contact_fields.country'), contact['address2_country']),
                    row(t('contact_fields.city'), contact['address2_city']),
                    row(t('contact_fields.postal_code'), contact['address2_postal_code']),
                    row(t('contact_fields.street'), contact['address2_street']),
                    row(t('contact_fields.street_number'), contact['address2_street_number'])
                )
            )
        ).appendTo(that.$el);

        $('<div class="contact-edit-section">').append(
            $('<h2>').text(t('contact_fields.section_comments')),
            $('<table>').append(
                row(t('contact_fields.comment'), contact['comment'])
            )
        ).appendTo(that.$el);

        if (contact['custom_fields_def']) {

            var $column1 = $('<tbody>');
            var $column2 = $('<tbody>');
            var colIndex = 0;

            contact['custom_fields_def'].forEach(function (fieldDef) {
                var value = contact['custom_fields'] ? contact['custom_fields']['f' + fieldDef['id']] : '';
                value = CustomFields.customFieldValue(i18n, fieldDef, value);

                $('<tr>').append(
                    $('<th>').append($('<div>').text(fieldDef['name'] + ':')),
                    $('<td>').append(
                        $('<span class="value">')
                            .toggleClass('ltr', fieldDef.subtype === CustomFields.SubType.tel)
                            .html(((value || '') + '').toHtml().replace(/\n/g, '<br />'))
                    )
                ).appendTo((colIndex % 2) === 0 ? $column1 : $column2);

                colIndex++;
            });

            if (colIndex > 0) {
                $('<div class="contact-edit-section">').append(
                    $('<h2>').text(t('contact_fields.section_more')),
                    $('<div class="column column-50">').append($('<table>').append($column1)),
                    $('<div class="column column-50">').append($('<table>').append($column2))
                ).appendTo(that.$el);
            }
        }

        return that;
    }

});

export default ContactViewArea;
