let defaultCountryCode = 'IL';
let longestMobilePrefix = 0, // Will be determined later
    shortestMobilePrefix = 99; // Will be determined later

const applyFormattingRules = function (number, countryCallingCode) {

    let rules = callingCodeToNumberFormatterMap[countryCallingCode];
    if (rules) {
        for (let i = 0, len = rules.length; i < len; i++) {
            let rule = rules[i];
            let from = rule['from'];
            if (from.test(number)) {
                return number.replace(from, rule['to']);
            }
        }
    }

    rules = callingCodeToNumberFormatterMap['default'];
    if (rules) {
        for (let i = 0, len = rules.length; i < len; i++) {
            let rule = rules[i];
            let from = rule['from'];
            if (from.test(number)) {
                return number.replace(from, rule['to']);
            }
        }
    }

    return number;
};

class PhoneNumberUtil {

    /**
     * Normalize a phone number to a full, clean, +prefixed phone number
     * @param {String} number A phone number
     * @param {String?} countryCode The country code (i.e. "IL", "US") of the default country, to assume for local numbers
     * @returns {String} A full, clean number, with the preceding +. (A normalized number)
     */
    static normalizePhoneNumber(number, countryCode) {
        if (!number) return '';
        countryCode = countryCode || defaultCountryCode;

        number = number.replace(/[^0-9+]/g, ''); // Keep only digits and +
        if (!number) return '';

        if (number[0] === '+') return number; // We're done, it's + prefixed already

        if (number[0] === '0' && number[1] === '0') {
            // Convert '00' to '+'
            return '+' + number.substr(2);
        }

        let countryCallingCode = countryCodeCallingCodeMap[countryCode];

        if (countryCallingCode === '972') {
            number = number.replace(/^(00|01[0-9])/, '+'); // Convert 00 and providers (012, 013...) to +, for outgoing international numbers
            if (number.indexOf('+') !== -1) {
                number = number.substr(number.indexOf('+'));
                return number;
            }
        } else if (countryCallingCode === '1') {
            if (number.substr(0, 3) === '011') {
                number = number.substr(3);
            }
        }

        let trunk = callingCodeTrunkPrefixMap[countryCallingCode];
        if (trunk && number.substr(0, trunk.length) === trunk) {
            number = number.substr(trunk.length);
        }

        return '+' + countryCallingCode + number;
    }

    /**
     * Returns a country calling code (i.e. 972).
     * @param {String} number Always a full, clean number, with the preceding +. (A normalized number)
     * @returns {String} The country calling code, or empty string if not found.
     */
    static countryCallingCodeFromNumber(number) {
        let maxCCCLen = Math.min(3, number.length - 1);
        for (let howMuch = 1; howMuch <= maxCCCLen; howMuch++) {
            let candidate = number.substr(1, howMuch);
            if (callingCodeCountryCodeMap[candidate]) {
                return candidate;
            }
        }
        return '';
    }

    /**
     * Formats a phone number for display;
     * @param {String} number Always a full, clean number, with the preceding +. (A normalized number)
     * @param {String?} countryCode The country code (i.e. "IL", "US") of the default country, to assume for local numbers
     * @returns {String} A formatted phone number
     */
    static displayPhoneNumber(number, countryCode) {
        if (number[0] !== '+') return number;

        countryCode = countryCode || defaultCountryCode;
        let countryCallingCode = countryCodeCallingCodeMap[countryCode], trunk;
        if (number.substr(1, countryCallingCode.length) === countryCallingCode) {
            trunk = callingCodeTrunkPrefixMap[countryCallingCode];
            let localNumber = '';
            if (trunk) {
                localNumber += trunk; // Prefix with trunk number to make a full local number before formatting
            }
            localNumber += number.substr(1 + countryCallingCode.length);
            localNumber = applyFormattingRules(localNumber, countryCallingCode);
            return localNumber;
        } else {
            countryCallingCode = this.countryCallingCodeFromNumber(number);
            if (!countryCallingCode) return number;

            let part1 = number.substr(0, 1 + countryCallingCode.length);
            let part2 = number.substr(1 + countryCallingCode.length);
            trunk = callingCodeTrunkPrefixMap[countryCallingCode];
            if (trunk) {
                part2 = trunk + part2; // Prefix with trunk number to make a full local number before formatting
            }
            part2 = applyFormattingRules(part2, countryCallingCode);
            if (trunk) {
                part2 = part2.replace(trunk, '') // Remove the trunk prefix from within the formatted number
                    .replace('()', '').replace(/  /g, ' ').trim(); // Remove residue from the trunk prefix
            }
            number = part1 + ' ' + part2;
            return number;
        }
    }

    /**
     * Determines if a phone number is for a mobile line
     * @param {String} number Always a full, clean number, with the preceding +. (A normalized number)
     * @returns {Boolean} true if it's a mobile number, false if otherwise.
     */
    static isMobileNumber(number) {
        if (number[0] === '+') number = number.substr(1);
        if (number.length < shortestMobilePrefix) return false;

        let longest = number.length > longestMobilePrefix ? longestMobilePrefix : number.length;
        for (let i = longest; i >= shortestMobilePrefix; i--) {
            if (sortedSearch(mobilePrefixes, number.substr(0, i)) !== -1) return true;
        }

        return false;
    }

    /**
     * Takes a country code (i.e. IL) and returns the appropriate calling code (i.e. 972)
     * @param {String?} countryCode The country code (i.e. IL)
     * @returns {String} The calling code, or an empty string if not found.
     */
    static callingCodeForCountryCode(countryCode) {
        let code = countryCodeCallingCodeMap[countryCode.toUpperCase()];
        if (code) return code;
        return '';
    }

    /**
     * Takes a calling code (i.e. 972) and returns the appropriate calling code (i.e. IL)
     * @param {String?} callingCode The calling code (i.e. 972)
     * @returns {String} The country code, or an empty string if not found.
     */
    static countryCodeForCallingCode(callingCode) {
        let code = callingCodeCountryCodeMap[callingCode];
        if (code) return code;
        return '';
    }

    /**
     * Takes a phone number and tries to guess if it may be a trunked number
     * @param {String?} number the phone number to test
     * @returns {Boolean} `true` if the number could be a trunked number, `false` if it is definitely not a trunked number.
     */
    static isPossiblyTrunked(number) {
        if (!number) return false;

        number = number + ''; // Make sure it is a string
        number = number.replace(/[^0-9+]/g, ''); // Keep only digits and +

        if (!number) return false; // The number may be empty after cleanup

        if (number[0] === '0' && number[1] === '0') {
            // Convert '00' to '+'
            number = '+' + number.substr(2);
        }

        if (number[0] === '+') {
            // It's an international number, we can test accurately for this country.
            let countryCallingCode = this.countryCallingCodeFromNumber(number);
            let trunk = countryCallingCode && callingCodeTrunkPrefixMap[countryCallingCode];
            return trunk && number.substr(1 + countryCallingCode.length, trunk.length) === trunk;
        }

        // Test for all possibilities from longest to shortest
        for (let i = 0, len = trunkPrefixReverseOrderedList.length; i < len; i++) {
            let trunkPossibility = trunkPrefixReverseOrderedList[i];
            if (number.substr(0, trunkPossibility.length) === trunkPossibility) {
                return true;
            }
        }

        return false;
    }

    /**
     * @returns {String}
     */
    static get defaultCountryCode() {
        return defaultCountryCode;
    }

    /**
     * @param {String} value
     */
    static set defaultCountryCode(value) {
        defaultCountryCode = value;
    }

}

/** Search in a sorted array */
const sortedSearch = function (array, element) {
    let low = 0;
    let high = array.length;

    while (high > low) {
        let index = (high + low) / 2 >>> 0;
        let cursor = array[index];

        if (cursor < element) low = index + 1;
        else if (cursor > element) high = index;
        else return index;
    }

    return -1;
};

/** Country code to Country calling code mapping */
const countryCodeCallingCodeMap = {
    "AC": "247", "AD": "376", "AE": "971", "AF": "93", "AG": "1", "AI": "1", "AL": "355",
    "AM": "374", "AN": "599", "AO": "244", "AQ": "672", "AR": "54", "AS": "1", "AT": "43",
    "AU": "61", "AW": "297", "AX": "358", "AZ": "994", "BA": "387", "BB": "1", "BD": "880",
    "BE": "32", "BF": "226", "BG": "359", "BH": "973", "BI": "257", "BJ": "229", "BL": "590",
    "BM": "1", "BN": "673", "BO": "591", "BR": "55", "BS": "1", "BT": "975", "BV": "226",
    "BW": "267", "BY": "375", "BZ": "501", "CA": "1", "CC": "61", "CD": "243", "CF": "236",
    "CG": "242", "CH": "41", "CI": "225", "CK": "682", "CL": "56", "CM": "237", "CN": "86",
    "CO": "57", "CR": "506", "CU": "53", "CV": "238", "CX": "61", "CY": "357", "CZ": "420",
    "DE": "49", "DJ": "253", "DK": "45", "DM": "1", "DO": "1", "DZ": "213", "EC": "593",
    "EE": "372", "EG": "20", "EH": "212", "ER": "291", "ES": "34", "ET": "251", "FI": "358",
    "FJ": "679", "FK": "500", "FM": "691", "FO": "298", "FR": "33", "GA": "241", "GB": "44",
    "GD": "1473", "GE": "995", "GF": "594", "GG": "44", "GH": "233", "GI": "350", "GL": "299",
    "GM": "220", "GN": "224", "GP": "590", "GQ": "240", "GR": "30", "GS": "500", "GT": "502",
    "GU": "1", "GW": "245", "GY": "592", "HK": "852", "HM": "61", "HN": "504", "HR": "385",
    "HT": "509", "HU": "36", "ID": "62", "IE": "353", "IL": "972", "IM": "44", "IN": "91",
    "IO": "246", "IQ": "964", "IR": "98", "IS": "354", "IT": "39", "JE": "44", "JM": "1876",
    "JO": "962", "JP": "81", "KE": "254", "KG": "996", "KH": "855", "KI": "686", "KM": "269",
    "KN": "1", "KP": "850", "KR": "82", "KW": "965", "KY": "1", "KZ": "7", "LA": "856",
    "LB": "961", "LC": "1", "LI": "423", "LK": "94", "LR": "231", "LS": "266", "LT": "370",
    "LU": "352", "LV": "371", "LY": "218", "MA": "212", "MC": "377", "MD": "373", "ME": "382",
    "MF": "599", "MG": "261", "MH": "692", "MK": "389", "ML": "223", "MM": "95", "MN": "976",
    "MO": "853", "MP": "1", "MQ": "596", "MR": "222", "MS": "1", "MT": "356", "MU": "230",
    "MV": "960", "MW": "265", "MX": "52", "MY": "60", "MZ": "258", "NA": "264", "NC": "687",
    "NE": "227", "NF": "672", "NG": "234", "NI": "505", "NL": "31", "NO": "47", "NP": "977",
    "NR": "674", "NU": "683", "NZ": "64", "OM": "968", "PA": "507", "PE": "51", "PF": "689",
    "PG": "675", "PH": "63", "PK": "92", "PL": "48", "PM": "508", "PN": "872", "PR": "1",
    "PS": "970", "PT": "351", "PW": "680", "PY": "595", "QA": "974", "RE": "262", "RO": "40",
    "RS": "381", "RU": "7", "RW": "250", "SA": "966", "SB": "677", "SC": "248", "SD": "249",
    "SE": "46", "SG": "65", "SH": "290", "SI": "386", "SJ": "47", "SK": "421", "SL": "232",
    "SM": "378", "SN": "221", "SO": "252", "SR": "597", "ST": "239", "SV": "503", "SY": "963",
    "SZ": "268", "TC": "1", "TD": "235", "TF": "262", "TG": "228", "TH": "66", "TJ": "992",
    "TK": "690", "TL": "670", "TM": "993", "TN": "216", "TO": "676", "TP": "670", "TR": "90",
    "TT": "1", "TV": "688", "TW": "886", "TZ": "255", "UA": "380", "UG": "256", "UM": "699",
    "US": "1", "UY": "598", "UZ": "998", "VA": "39066", "VC": "1", "VE": "58", "VG": "1",
    "VI": "1", "VN": "84", "VU": "678", "WF": "681", "WS": "685", "YE": "967", "YT": "262",
    "YU": "891", "ZA": "27", "ZM": "260", "ZR": "243", "ZW": "263"
};

/** Country calling code to Country code mapping. Automatically populated. */
const callingCodeCountryCodeMap = { };

/** Country code to Trunk prefix code mapping */
const callingCodeTrunkPrefixMap = {
    "212": "0", "250": "0", "255": "0", "254": "0", "54": "0", "51": "0", "55": "0", "591": "0",
    "93": "0", "994": "0", "880": "0", "95": "0", "855": "0", "86": "0", "91": "0", "62": "0",
    "972": "0", "81": "0", "962": "0", "850": "0", "82": "0", "856": "0", "60": "0", "976": "0",
    "977": "0", "92": "0", "63": "0", "9": "8","886": "0", "66": "0", "993": "8", "998": "8",
    "84": "0", "355": "0", "43": "0", "375": "8", "32": "0", "387": "0", "359": "0", "385": "0",
    "357": "0", "358": "0", "33": "0", "995": "0", "49": "0", "36": "06", "353": "0", "377": "0",
    "370": "8", "389": "0", "373": "0", "382": "0", "31": "0", "40": "0", "381": "0", "421": "0",
    "386": "0", "46": "0", "41": "0", "90": "0", "380": "0", "44": "0", "61": "0", "64": "0",
    "1": "1"
};

/** A reverse ordered list of possible trunk prefixes. Automatically populated. */
const trunkPrefixReverseOrderedList = [];

/** Country code to Trunk prefix code mapping */
const callingCodeToNumberFormatterMap = {
    "972": [
        { "from": "^(0[567][0-9])([0-9]{3,3})([0-9]+)$", "to": "$1-$2-$3" },
        { "from": "^(0[0-9])([0-9]{3,3})([0-9]+)$", "to": "$1-$2-$3" },
        { "from": "^(0[567][0-9])([0-9]{3,3})$", "to": "$1-$2" },
        { "from": "^(0[0-9])([0-9]{3,3})$", "to": "$1-$2" }
    ],
    "1": [
        { "from": "^1([0-9]{3,3})([0-9]{3,3})([0-9]+)$", "to": "(1) ($1) $2-$3" },
        { "from": "^1([0-9]{3,3})([0-9]{3,3})$", "to": "(1) ($1) $2" },
        { "from": "^1([0-9]{3,3})$", "to": "($1)" }
    ],
    "default": [
        { "from": "^([0-9]{3,3})([0-9]{3,3})([0-9]+)$", "to": "$1 $2-$3" },
        { "from": "^([0-9]{3,3})([0-9]{3,3})$", "to": "$1 $2" }
    ]
};

/** This array must always be sorted lexically */
const mobilePrefixes = [
    "110","1200","1201","1202","1203","1204","1205","1206","1207","1208","1209","1210","1211","1212","1213","1214","1215","1216","1217","1218","1219","1220","1221","1222","1223","1224","1225","1226","1227","1228","1229","1230","1231","1232","1233","1234","1235","1236","1237","1238","1239","1240",
    "1241","1242","1243","1244","1245","1246","1247","1248","1249","1250","1251","1252","1253","1254","1255","1256","1257","1258","1259","1260","1261","1262","1263","1264","1265","1266","1267","1268","1269","1270","1271","1272","1273","1274","1275","1276","1277","1278","1279","1280","1281","1282","1283","1284","1285","1286",
    "1287","1288","1289","1290","1291","1292","1293","1294","1295","1296","1297","1298","1299","1340","1345","1350","1351","1352","1353","1354","1355","1356","1357","1358","1359","1410","1411","1412","1413","1414","1415","1416","1417","1418","1419","1441","1450","1451","1452","1453","1454","1455","1456",
    "1457","1458","1459","1473","1550","1551","1552","1553","1554","1555","1556","1557","1558","1559","1649","1664","1670","1671","1684","1700","1701","1702","1703","1704","1705","1706","1707","1708","1709","1710","1711","1712","1713","1714","1715","1716","1717","1718","1719","1720","1721","1722","1723","1724","1725","1726","1727","1728","1729","1730","1731","1732","1733","1734","1735",
    "1736","1737","1738","1739","1740","1741","1742","1743","1744","1745","1746","1747","1748","1749","1750","1751","1752","1753","1754","1755","1756","1757","1758","1759","1760","1761","1762","1763","1764","1765","1766","1767","1768","1769","1770","1771","1772","1773","1774","1775","1776","1777","1778",
    "1779","1780","1781","1782","1783","1784","1785","1786","1787","1788","1789","1790","1791","1792","1793","1794","1795","1796","1797","1798","1799","1808","1809","1829","1849","1868","1869","1876","1939","20010","20011","20012","2010","2011","2012","2014","20150","20151","20152","20155","2016","2017","2018","2019","21190",
    "21191","21192","21193","21194","21195","21196","21197","21198","21199","2125288","2125289","2126","2135","2136","2137","2139619","2162","2163","2164","2165","2169","21891","21892","21894","21895","21896","21899","2207","2209","22176","22177","2222","2223","2224","2226","2227","2233","2234","2235","2236",
    "2237","2239","2246","22500","22501","22502","22503","22504","22505","22506","22507","22508","22509","22540","22541","22544","22545","22546","22547","22548","22549","22555","22557","22558","2256","2267","2279","2289","22966","22967","2299","23025","230421","230422","230423","230428","230429","23049","230525","2305421","2305422","2305423","2305428","2305429","230549",
    "23057","230587","23059","2307","2308","2309","2314","2315","2316","2317","23188","23223","23230","23233","23235","23240","23250","2327","2332","23354","2347025","2347026","234703","234705","234706","234708","23480","234812","234813","234815","234816","234818","23490","2353","2356","2359","2377","2379",
    "23859","2389","23990","2402","2405","2406","2407","24103","24104","24105","24106","24107","2412","2413","2414","2415","2416","2417","24201","24204","24205","24206","2423","2424","2425","2426","2427","2428","2429","24322","24378","2438","2439","24491","24492","24493","2455","2456","2457","246387","2485","2487","2489",
    "24911","24912","2499","25007","25008","2507","2508","2519","2536","2538","2547","25574","25577","25639","256477","2567","25729","2577","2588","2609","2613","26226965","26226966","26226967","26226968","26226969","2626","262700","26311","26323","26371","26373","26377","26391","26460","26481","26485","2658",
    "2659","2665","2666","2677","2686","2687","2693","2760","2771","2772","2773","2774","2776","2778","2779","27811","27812","27813","27814","27815","2782","2783","2784","2785","2908","2917","29756","29759","297600","297622","29766","29769","29773","29774","29796","29799","29821","29822","29823","29824","29825","29826","29827","29828","29829","2985","2987","29891","29892",
    "29893","29894","29895","29896","29897","29898","29899","29949","2995","3069","316","32460","32463","324681","324682","324683","3247","3248","3249","336","337","346","347","3505","3506","3511691","3511693","3511696","3511891","3511892","3511893","3511896","35160091","35160093","351609","351639","351659","351669",
    "3519","3526021","352621","352628","352661","352668","352691","352698","353700","35376","3538","354385","354388","354389","3546","35475","35477","3548","35566","35567","35568","35569","3567","3568004","3569","35712196","357700","3577777","3579","3584","35850","35871","35948","35987","35988","35989","35998","35999","3620","3630","3631",
    "3670","3706","3712","3725","3728","3732","3735","3736","3737","3745","37477","3749","37525","37529","37533","37544","3763","3764","3765","3766","3774","3776","3786","3787","38039","38050","38063","38066","38067","38068","38091","38092","38093","38094","38095","38096","38097","38098","38099","38160","38161","38162","38163","38164",
    "38165","38166","38168","38169","38263","38267","38268","38269","38591","38592","38595","38597","38598","38599","38620","38621","38630","38631","38640","38641","38643","38649","38650","38651","38660","38661","38664","38670","38671","3869800","3869801","3869802","3869803","3869814","3869817","3876","3897","3906","393",
    "39698","407","4174","4176","4177","4178","4179","41860","42060","420702","42072","42073","42077","42079","42093","42096","4219","4236","4237","43644","43650","43651","43652","43653","43655","43657","43659","43660","43661","43664","43665","43666","43667","43668","43669","43676","43677","43678","43680","43681",
    "43688","43699","43711","43730","43740","43810","43820","447","448","452","4530","4531","453434","4540","4541","4542","4550","4551","4552","4553","4560","4561","457031","4571","4581","46252","46254","46376",
    "46518","46519","46673","46674","46675","46676","4670","4671","4672","4673","4676","474","4758","4759","4779","47810","47815","479","4850","4851","4853","48570","48571","48572","48573","48574","48575","48576","48577","48578","48579","48600","48601","48602","48603","48604","48605","48606","48607","48608",
    "48609","4866","4869","4872","4873","48780","48781","48782","48783","48784","48785","48786","48787","48788","48789","48790","48791","48792","48793","48794","48795","48796","48797","48798","48799","4888","4915","49160","49161","49162","49163","49164","49165","49166","49167","49168","49169","49170","49171","49172","49173","49174","49175","49176","49177","49178","49179","49700",
    "5005","5006","5016","5022243","5022244","5022245","5022246","5022247","50223","5022428","5022429","50230","50231","5024","5025","5037","5043","5047","5048","5049","5058","5063","5066","50670","5068","5074","5075","5076","5077","50855","50925","509281","509291","509292","509293","509294","509295","509296","509297",
    "509298","509299","5093","5094","5095","5096","5097","50980","50981","50982","50983","50984","50985","50986","50987","5099","519","521","535","5415","549","5500","5501","5502","5503","5504","5505","5506","5507","5508","5509","5510","5511","5512","5513","5514","5515","5516","5517","5518","5519","5520",
    "5521","5522","5523","5524","5525","5526","5527","5528","5529","5530","5531","5532","5533","5534","5535","5536","5537","5538","5539","5540","5541","5542","5543","5544","5545","5546","5547","5548","5549","5550","5551","5552","5553","5554","5555","5556","5557","5558","5559","556","557","558","559","5632",
    "568","569","573","584","5906","590700","5916","5917","5926","59359","593691","593692","593693","593694","5938","5939","5946","594700","5959","5966","596700","5977","5978","5989","5993181","5993184","5993185","5993186","5993187","5994161","5994165","5994166","5994167","5994168","599520","599521","599522","599523","599524","599526","599527","599550","599551","599552","599553",
    "599554","599555","599556","599557","599558","599559","599580","599581","599586","599587","599588","599700","599701","59978","59979","599951","599952","5999530","599954","599956","599957","599961","5999630","5999631","601","608","6114","6116","614","618","619162","619164","628","63813","63817","639","642",
    "658","659","668","669","6707","67210","67211","67212","67213","67214","67215","67216","67217","67218","67219","67238","673227","673228","673229","67371","6738","6739","674555","67561","67562","67563","675644","67565","67566","67567","67568","67569","67571","67572","67576","67611","67612","67613","67614",
    "67615","67616","67617","67618","67619","67634","67636","67638","67639","67644","67645","67646","67647","67648","67649","67651","67652","67653","67654","67655","67656","67657","67658","67659","67661","67662","67663","67664","67665","67666","67667","67668","67672","67673","67675","67676","67677","67678","67681",
    "67682","67683","67684","67687","67688","67689","67774","67775","6784","6785","67877","67970","67971","67972","67973","67974","67975","67976","67977","67983","67984","67986","67987","6799","680620","680630","680640","680660","680680","680690","68250","68251","68252","68253","68254","68255","68256","68257","68258",
    "68259","68270","68271","68272","68273","68274","68275","68276","68277","68278","68279","6831","6833","6834","6857","68660","68661","68662","68663","68664","68665","68666","68667","68668","68669","68690","68691","68692","68693","68694","68695","68696","68697","68698","68699","6877","6878","6879","6892","68930","68931",
    "68932","68933","68934","68935","68939","689411","6897","69192","69193","69195","69197","7700","7701","7702","7705","7707","7771","7775","7777","7778","79","7901","7902","7903","7904","7905","7906","7908","7909","7910","7911","7912","7913","7914","7915","7916","7917","7918","7919","7920","7921","7922","7923","7924",
    "7925","7926","7927","7928","7929","7930","7931","7932","7933","7934","7935","7936","7937","7938","7939","7940","7950","7951","7952","7953","8170","8180","8190","821","8412","84160","84161","84162","84163","84164","84165","84166","84167","84168","84169","84188","84199","8490","8491",
    "8492","8493","8494","8495","8496","8497","8498","8499","85217","85248","85249","8525","8526","8529","8536","8551","85567","85568","85569","85570","85576","85577","85578","8558","8559","85620","8613","8615","8618","8801","8869","90050","90053","90054","90055","90392","905","917","918",
    "919","923","930381","9370","9372","9375","9377","9378","9379","947","959","9607","9609","9613","96170","96171","96176","9627","9639","96470","96471","96472","96473","96474","96475","96476","96477","96478","96479","9655","9656","9659","966050","966051","966053","966054","966055","966056","966057","966058","966059","9665",
    "9677","9689","97050","97051","97052","97053","97054","97055","97056","97057","97058","97059","971050","971052","971055","971056","9715","97250","97252","97253","97254","9725566","9725588","97257","97258","97331","97332","97333","97334","973353","973355","97336","97337","97338","97339","973663","973666","973669","9742","9743","9745",
    "9746","9747","97517","97577","97670","9768","9769","977961","97798","989","9929","99365","99366","99367","99440","99450","99451","99455","99460","99470","99477","995340","995341","995342","995343","995344","995345","995346","995347","995348","995349","995440","995441","995442","995443","995444","995445","995446","995447","995448","995449","9955","995770","995778","995790","9965","9989"
];

(function () {

    // Calculate shortest and longest mobile prefix length

    for (let i = 0, len = mobilePrefixes.length; i < len; i++) {
        let prefix = mobilePrefixes[i];
        if (prefix.length > longestMobilePrefix) {
            longestMobilePrefix = prefix.length;
        }
        if (prefix.length < shortestMobilePrefix) {
            shortestMobilePrefix = prefix.length;
        }
    }

    // Make a mapping of calling-code to country-code

    for (let key in countryCodeCallingCodeMap) {
        if (!countryCodeCallingCodeMap.hasOwnProperty(key)) continue;
        callingCodeCountryCodeMap[countryCodeCallingCodeMap[key]] = key;
    }

    // Compile the regexes for all nubmer formatters

    for (let key in callingCodeToNumberFormatterMap) {
        if (!callingCodeToNumberFormatterMap.hasOwnProperty(key)) continue;
        let items = callingCodeToNumberFormatterMap[key];
        for (let i = 0, len = items.length; i < len; i++) {
            let item = items[i];
            if (item['from'] && !(item['from'] instanceof RegExp)) {
                item['from'] = new RegExp(item['from']);
            }
        }
    }

    // Make a reverse-ordered list of trunk prefixes

    for (let key in callingCodeTrunkPrefixMap) {
        if (!callingCodeTrunkPrefixMap.hasOwnProperty(key)) continue;
        trunkPrefixReverseOrderedList.push(callingCodeTrunkPrefixMap[key]);
    }

    trunkPrefixReverseOrderedList.sort().reverse();

    for (let i = 0; i < trunkPrefixReverseOrderedList.length; i++) {
        let lastIndexOf = trunkPrefixReverseOrderedList.lastIndexOf(trunkPrefixReverseOrderedList[i]);
        if (lastIndexOf !== i) {
            trunkPrefixReverseOrderedList.splice(lastIndexOf, 1);
        }
    }

})();

if (typeof module !== 'undefined' && module.exports) { // Export as node.js module
    module.exports = PhoneNumberUtil;
}

if (typeof window !== 'undefined') { // Export to global scope in browser
    /** @expose */
    window.PhoneNumberUtil = PhoneNumberUtil;
}