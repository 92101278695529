const anchorPrototype = HTMLAnchorElement.prototype;

const hideProperty = function (prototype, functionName) {
    if (Object.defineProperty) {
        Object.defineProperty(prototype, functionName, {
            enumerable: false,
            configurable: true,
            writable: true,
            value: prototype[functionName],
        });
    }
};

if (!('click' in anchorPrototype)) {
    /** @expose */
    anchorPrototype.click = function () {
        const clickEvent = document.createEvent('MouseEvent');
        clickEvent.initEvent('click', true, true);
        this.dispatchEvent(clickEvent);
    };
    hideProperty(anchorPrototype, 'click');
}