<template>
    <div class="order-fields">

        <div v-for="field in fieldsToShow" class="order-field">

            <h2 :class='field.headerClass'>{{field.name}}</h2>

            <div v-if="field.explain" class="explain">{{field.explain}}</div>

            <component
                    :is="`${field.type}Component`"
                    :name="`f${field.id}`"
                    :subType="field.subtype ? getInputType(field['subtype']) : null"
                    :readOnly="field.readonly"
                    :options="field.options"
                    :invalid="field.invalid"
                    :original-data="field.originalData"
                    v-on:input="updateData($event, field.id)"
            />

        </div>

    </div>
</template>

<script>
    import CustomFieldsComponents from "./VueCustomFields";
    import CustomFields from "../../shared_utils/CustomFields";
    import i18n, { t }  from '../i18n/i18n.js';

    const checkCondition = (a, operator, b) => {
        const numberA = Number(a),
            numberB = Number(b);

        if (a === undefined || a === '') return false;

        switch (operator) {
            case 'isEqual':
                return a == b;
            case 'isDifferent':
                return a != b;
            case 'isBigger':
                if (isNaN(numberA)) return false;
                return numberA > numberB;
            case 'isSmaller':
                if (isNaN(numberA)) return false;
                return numberA < numberB;
        }
    };

    export default {
        name: "OrderCustomFields",
        components: CustomFieldsComponents,
        props: ['productId', 'order', 'fieldsDef'],
        data() {
            return {
                invalidFields: [],
                orderData: {},
            }
        },
        computed: {
            fieldsToShow() {
                return this.fieldsDef
                    .filter(field => this.productId === String(field['product_id']))
                    .filter(field => this.verifiedCondition.includes(field.id))
                    .map(field => {

                        const fieldOptions = (field.options &&
                            typeof field.options === 'string' &&
                            JSON.parse(field.options)) || field.options;

                        const explain = field.explain; // TODO: Handle line breaks

                        return Object.assign(field, {
                            headerClass: this.getFieldClass(field),
                            invalid: this.invalidFields.includes(field.id),
                            explain,
                            options: fieldOptions,
                            originalData: this.getFieldOriginalData(field)
                    })
                })
            },
            verifiedCondition() {

                return this.fieldsDef.filter(field => {

                    if (!field.condition) return true;

                    const {sourceField, operator, requiredValue} = JSON.parse(field.condition);

                    return checkCondition(this.orderData[sourceField], operator, requiredValue);

                }).map(field => field.id);
            }

        },
        methods: {
            getInputType(subType) {
                switch (subType) {
                    case CustomFields.SubType.date:
                        return 'date';
                    case CustomFields.SubType.tel:
                        return 'tel';
                    default:
                        return 'text';
                }
            },

            getFieldOriginalData(fieldDef) {

                const order = this.order;
                const contact = order['contact_source'];
                const {id: fieldId, contact_source_field: sourceField} = fieldDef;

                let value = order['custom_fields'] ? order['custom_fields'][`f${fieldId}`] : '';

                // If there's no custom_fields data on this order, but there's a contact_source,
                // Then it's a new order, and we need to pull data
                if (!order['custom_fields'] && contact) {

                    // Is it custom or base field?
                    const foundValueAsCustomField = contact['custom_fields'][sourceField];

                    value = foundValueAsCustomField !== undefined ?
                        contact['custom_fields'][sourceField] :
                        contact[sourceField];
                }

                if (!value) return '';

                // Some temporary hacks:
                if (fieldDef.subtype === CustomFields.SubType.date) {
                    return i18n.formatDate(value);
                }

                if (fieldDef.type === CustomFields.Type.checkbox) {
                    return value;
                }

                return CustomFields.customFieldValue(i18n, fieldDef, value);
            },

            getFieldClass(field) {

                let classString = '';

                if (field.required) {
                    classString = 'required'
                }

                if ((field.type.startsWith('checkbox') || field.type === 'radio') &&
                    this.invalidFields.includes(field.id)) {

                    classString += ' invalid';

                }

                return classString;
            },

            updateData(value, fieldId) {
                this.invalidFields.remove(fieldId);
                this.$set(this.orderData, fieldId, value);
            },

            testForInvalidFields() {

                this.invalidFields = [];

                this.fieldsToShow.forEach(field => {

                    if (!field.required || field.readonly) return;

                    if (!this.orderData[field.id]) {
                        this.invalidFields.push(field.id);
                    }

                });

                return !this.invalidFields.length;

            },
        }
    }
</script>