import $ from 'jquery';
import Backbone from 'backbone';
import View from './View.js';

const defaultOptions = {
    draggable: true,
    title: null,
    titleHtml: null,
    html: null,
    mask: true,
    maskOpacity: 0.3,
    dismissFromMask: false,
    dismissFromEscape: true,
    hasCloseButton: true,
    removeOnHide: true
};

/** @class Window */
const Window = View.extend(
    /** @lends Window.prototype */
    {

        className: "window",

        events: {
            'click >.caption>.close': 'hide'
        },

        /**
         * @constructs
         * @param {Backbone.ViewOptions?} options
         * @param {Boolean=true} options.draggable
         * @param {String?} options.title
         * @param {String?} options.titleHtml
         * @param {String?} options.html
         * @param {Boolean=true} options.mask
         * @param {Number} [options.maskOpacity=0.3]
         * @param {Boolean=false} options.dismissFromMask
         * @param {Boolean=true} options.dismissFromEscape
         * @param {Boolean=true} options.hasCloseButton
         * @param {Boolean=true} options.removeOnHide
         */
        initialize: function (options) {
            const that = this;

            options = that.options = Object.assign({}, defaultOptions, options);

            const $caption = that.$caption = $('<div class="caption">').appendTo(that.$el);

            that._$title = $('<div class="title">').appendTo($caption);

            if (options.hasCloseButton) {
                that.$close = $('<button class="close">').appendTo($caption);
            }

            that.$inner = $('<div class="inner">').appendTo(that.$el);

            this.onKeyDown = (function (event) {

                if (event.which === 9) {
                    that._watchTab(event);
                }

                if (this.options.dismissFromEscape && event.which === 27 /* ESCAPE */) {
                    this.hide();
                    event.preventDefault();
                }

            }).bind(this);

            this.render();
        },

        render: function () {

            if (this.options.titleHtml) {
                this._$title.html(this.options.titleHtml);
            } else {
                this._$title.text(this.options.title || '');
            }

            if (this.options.html) {
                this.$inner.html(this.options.html);
            }

            return this;
        },

        title: function () {
            if (arguments.length) {
                this._$title[arguments[1] ? 'html' : 'text'](arguments[0] || '');
                if (arguments[1]) {
                    this.options.title = '';
                    this.options.titleHtml = arguments[0];
                } else {
                    this.options.title = arguments[0];
                    this.options.titleHtml = '';
                }

                return this;
            } else {
                return this.options.titleHtml || this.options.title;
            }
        },

        titleEl: function () {
            return this._$title;
        },

        html: function () {
            if (arguments.length) {
                this.$inner.html((this.options.html = arguments[0]) == null ? '' : this.options.html);
                return this;
            } else {
                return this.$inner.html();
            }
        },

        inner: function () {
            return this.$inner;
        },

        remove: function () {
            const that = this;
            if (this.$mask) {
                this.$mask.stop().remove();
                this.$mask = null;
            }
            this._$title = null;
            this.$caption = null;
            this.$close = null;
            this.$inner = null;
            if (this._activeElement) {
                this._activeElement.focus();
                this._activeElement = null;
            }
            $(window).off('keydown', this.onKeyDown);

            if (that._onDragMove) {
                $(document)
                    .off('mousemove', that._onDragMove)
                    .off('touchmove', that._onDragMove)
                    .off('mouseup', that._onDragEnd)
                    .off('touchend', that._onDragEnd)
                    .off('touchcancel', that._onDragEnd);
                that._onDragMove = that._onDragEnd = null;
            }

            Window.__super__.remove.apply(this, arguments);
        },

        show: function (parent) {
            const that = this;

            this.$parent = parent = $(parent).add(document.body).first();

            this._activeElement = document.activeElement;
            if (this._activeElement) {
                this._activeElement.blur();
            }

            if (parent[0] === document.body && this.options.mask) {
                this.$mask || (this.$mask = $('<div class="screen-mask">')
                    .css({
                        background: '#000',
                        opacity: this.options.maskOpacity,
                        position: 'fixed',
                        left: 0, top: 0, right: 0, bottom: 0,
                        'z-index': '10000'
                    }))
                    .appendTo(parent)
                    .stop()
                    .css('opacity', 0)
                    .animate({'opacity': this.options.maskOpacity}, {duration: 150, swing: 'easeOutQuint'});

                if (this.options.dismissFromMask) {
                    this.$mask.on('click', this.hide.bind(this));
                } else {
                    this.$mask.on('mousedown', function (evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                    });
                }
            }

            this.$el
                .appendTo(parent)
                .css({
                    'z-index': 10000,
                    position: parent[0] === document.body ? 'fixed' : 'absolute'
                });

            if (that.options.position) {
                let top = that.options.position['top'];
                if (that.options.is_above === true) {
                    top -= $(this.$el).outerHeight() + 2;
                }
                that.$el.css({left: that.options.position['left'], top: top});
            } else {
                this.center();
            }

            $(window).on('keydown', this.onKeyDown);

            if (this.options.draggable) {
                this.$caption.off().on('mousedown', this.onDragStart.bind(this)).on('touchstart', this.onDragStart.bind(this));
            }

            this.trigger('show');

            setTimeout(function () {
                if (!that.$el.closest('body').length) return;

                that.$el.find(':tabbable:first').focus();
            }, 0);

            return this;
        },

        hide: function () {
            const that = this;

            if (that.$el.parent().length) {
                that.trigger('hide');
            }
            if (that.$mask) {
                that.$mask.stop().animate(
                    {'opacity': 0},
                    {
                        duration: 150,
                        swing: 'easeOutQuint',
                        complete: function () {
                            that.$mask.remove();
                            that.$mask = null;
                            that.trigger('hidecomplete');
                            if (that.options.removeOnHide) {
                                that.remove();
                            }
                        }
                    }
                );
            }
            that.$el.detach();
            $(window).off('keydown', that.onKeyDown);
            if (that._activeElement) {
                that._activeElement.focus();
                that._activeElement = null;
            }

            if (that._onDragMove) {
                $(document)
                    .off('mousemove', that._onDragMove)
                    .off('touchmove', that._onDragMove)
                    .off('mouseup', that._onDragEnd)
                    .off('touchend', that._onDragEnd)
                    .off('touchcancel', that._onDragEnd);
                that._onDragMove = that._onDragEnd = null;
            }

            if (!that.$mask) {
                that.trigger('hidecomplete');
                if (that.options.removeOnHide) {
                    that.remove();
                }
            }

            return this;
        },

        center: function () {
            const that = this;

            const parent = that.$el.parent(),
                w = parent.innerWidth(),
                h = (parent[0] && parent[0] === document.body) ? document.documentElement.clientHeight : parent.innerHeight();
            const x = (w - that.$el.outerWidth()) / 2;
            let y = (h - that.$el.outerHeight()) / 2;
            if (y < 0) y = 0;

            that.$el.css({left: x, top: y});

            return this;
        },

        onDragStart: function (event) {
            const that = this;

            const isTouch = event.originalEvent.changedTouches;

            function touchPageX(touch) {
                return touch.pageX == null ? touch.clientX + window.pageXOffset : touch.pageX;
            }

            function touchPageY(touch) {
                return touch.pageY == null ? touch.clientY + window.pageYOffset : touch.pageY;
            }

            let startPage;
            if (isTouch) {
                let touch = event.originalEvent.changedTouches[0];
                that._draggingTouchId = touch.identifier;
                startPage = {left: touchPageX(touch), top: touchPageY(touch)};
            } else {
                startPage = {left: event.pageX, top: event.pageY};
            }

            const startPos = that.$el.position();
            startPage.left -= startPos.left;
            startPage.top -= startPos.top;

            const documentMove = that._onDragMove = function (event) {
                let page;
                if (event.originalEvent.changedTouches) {
                    const touch = Array.prototype.find.call(event.originalEvent.changedTouches, touch => touch.identifier === that._draggingTouchId);
                    page = {left: touchPageX(touch), top: touchPageY(touch)};
                } else {
                    page = {left: event.pageX, top: event.pageY};
                }
                that.$el.css({left: (page.left - startPage.left), top: (page.top - startPage.top)});
            };

            const documentEnd = that._onDragEnd = function (event) {
                if (event.originalEvent.changedTouches) {
                    if (!Array.prototype.some.call(event.originalEvent.changedTouches, touch => touch.identifier === that._draggingTouchId)) {
                        return;
                    }
                }
                if (isTouch) {
                    $(document)
                        .off('touchmove', documentMove)
                        .off('touchend', documentEnd)
                        .off('touchcancel', documentEnd);
                } else {
                    $(document)
                        .off('mousemove', documentMove)
                        .off('mouseup', documentEnd);
                }
            };

            if (isTouch) {
                $(document)
                    .on('touchmove', documentMove)
                    .on('touchend', documentEnd)
                    .on('touchcancel', documentEnd);
            } else {
                $(document)
                    .on('mousemove', documentMove)
                    .on('mouseup', documentEnd);
            }
        },

        _watchTab: function (event) {

            const that = this;

            if ($.contains(that.el, event.target)) {
                const inputs = that.$el.find(':tabbable:first, :tabbable:last');

                // if it's the first or last tabbable element, refocus
                if ((!event.shiftKey && event.target === inputs[inputs.length - 1]) ||
                    (event.shiftKey && event.target === inputs[0]) ||
                    inputs.length === 0) {

                    event.preventDefault();
                }
            } else {
                event.preventDefault();
                that.$el.find(':tabbable:first').focus();
            }
        }
    }
);

export default Window;

