const stringPrototype = String.prototype;

if (!('toHtml' in stringPrototype)) {
    /** @expose */
    stringPrototype.toHtml = function () {
        return this.replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/'/g, "&#39;")
            .replace(/"/g, "&quot;")
            .replace(/\n/g, '<br />');
    };
}

if (!('regexEscape' in stringPrototype)) {
    const regexEscape = /[-\/()[\]?{}|*+:]/g;
    /** @expose */
    stringPrototype.regexEscape = function () {
        return this.replace(regexEscape, "\\$&");
    };
}

if (!('getQueryStringParams' in stringPrototype)) {
    /** @expose */
    stringPrototype.getQueryStringParams = function (queryStringOnly) {
        let query = this;
        if (!queryStringOnly) {
            const idx = query.indexOf('?');
            if (idx > -1) {
                query = query.substr(idx + 1);
            } else {
                query = '';
            }
        }
        const pairs = query.split(/&/g);
        const params = {};
        for (let i = 0; i < pairs.length; i++) {
            if (pairs[i].length === 0) continue;
            const eqIdx = pairs[i].indexOf('=');
            const pair = eqIdx === -1 ? pairs[i] : [pairs[i].substr(0, eqIdx), pairs[i].substr(eqIdx + 1)];
            pair[0] = decodeURIComponent(pair[0]);
            pair[1] = pair[1] === undefined ? '' : decodeURIComponent(pair[1]);
            if (params.hasOwnProperty(pair[0])) {
                let array = params[pair[0]];
                if (!(array instanceof Array)) {
                    array = [array];
                    params[pair[0]] = array;
                }
                array.push(pair[1]);
            } else {
                params[pair[0]] = pair[1];
            }
        }
        return params;
    };
}
