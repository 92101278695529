﻿export default ['he', {

    'rtl': true,
    'two_letter_iso_name': 'he',
    'iso_name': 'he-IL',
    'strong_side': 'right',
    'weak_side': 'left',
    'google_lang_code': 'iw',

    'activity_indicator': {
        'loading': 'טוען...',
        'saving': 'שומר...',
        'removing': 'מסיר...',
        'loggingin': 'מתחבר...',
        'loggingout': 'מתנתק...',
        'nextcontact': 'מחפש את איש הקשר הבא...',
        'stopping_break': 'מסיים הפסקה...'
    },

    'size_abbrs': {
        'b': 'בתים',
        'kb': 'ק"ב',
        'mb': 'מ"ב',
        'gb': 'ג"ב',
        'tb': 'ט"ב'
    },

    'calendar': {
        'weekdays': ['יום ראשון', 'יום שני', 'יום שלישי', 'יום רביעי', 'יום חמישי', 'יום שישי', 'יום שבת'],
        'weekdays_short': ['ראש', 'שני', 'שלי', 'רבי', 'חמי', 'ששי', 'שבת'],
        'weekdays_very_short': ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
        'months': ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
        'months_short': ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
        'today': 'היום',
        'close': 'סגור'
    },

    'date_formats': {
        'normal_date': 'dd/MM/yy',
        'short_time': 'HH:mm'
    },

    'error_codes': {
        'not-found': 'המשאב שביקשת אינו קיים.',
        'not-allowed': 'אינך מורשה לבצע פעולה זו.',
        'authorization-error': 'עליך להתחבר למערכת על מנת לבצע פעולה זו.',
        'invalid-email': 'כתובת האימייל שסופקה אינה תקינה.',
        'username-in-use': 'שם המשתמש שסופק הינו כבר בשימוש.',
        'no-campaigns-associated': 'אינך משויך לאף קמפיין, צור קשר עם מנהל המערכת.',
        'email-in-use': 'כתובת האימייל שסופקה הינה כבר בשימוש.',
        'empty-file': 'הקובץ שהועלה הינו קובץ ריק.',
        'ami-trouble': 'שגיאת מרכזיה, נא לפנות למנהל המערכת.',
        'no-project': 'אינך משויך לאף פרויקט, צור קשר עם מנהל המערכת',
        'predictive-trouble': 'החייגן לא זמין, נא לפנות למנהל המערכת',
        'no-such-queue': 'התור אינו קיים במרכזיה, אנא פנה למנהל המערכת',
        'wrong-device-state': 'מצב השלוחה אינו תקין! המצב הוא: <b>{{status}}</b>',
        'no-extension': 'התחברת ללא שלוחה - ייתכן שנציג אחר התחבר עם השלוחה שלך, יש לצאת מהמערכת ולהתחבר עם השלוחה הנכונה',
        '403-no-match': 'שם המשתמש או הסיסמא אינם תואמים.',
        '403-locked': 'חשבונך נעול. נא צור קשר עם מנהל המערכת שלך.',
        '403': 'אינך מורשה לבצע פעולה זו.',
        '400': 'בקשה לא תקינה נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '401': 'עליך להתחבר למערכת על מנת לבצע פעולה זו.',
        '402': 'הפעולה נדחתה על ידי השרת עקב תשלומים ממתינים.',
        '404': 'המשאב שביקשת אינו קיים.',
        '405': 'בקשה לא תקינה נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '500': 'אירעה שגיאת שרת פנימית. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '501': 'בקשה לא מוכרת נשלחה אל השרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '502': 'נראה שאתה משתמש בשרת Proxy כושל. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '503': 'השירות אינו זמין כרגע. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        '504': 'נראה שאתה משתמש בשרת Proxy כושל. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.',
        'default': 'אירעה שגיאה לא מוכרת. נא צור קשר עם מנהל המערכת שלך, או נסה שוב.'
    },

    'device_states': {
        UNKOWN: 'לא ידוע',
        NOT_INUSE: 'לא בשימוש',
        INUSE: 'בשימוש',
        BUSY: 'תפוס',
        INVALID: 'שגוי / לא קיים',
        UNAVAILABLE: 'לא זמין',
        RINGING: 'מחייג',
        RINGINUSE: 'Ring In Use',
        ONHOLD: 'בהמתנה'
    },

    'main_nav': {
        'welcome_male': 'ברוך הבא<br />{{name}}!',
        'welcome_female': 'ברוכה הבאה<br />{{name}}!',
        'welcome': 'ברוך/ה הבא/ה<br />{{name}}!',

        'outgoing_calls': 'שיחות יוצאות',
        'initiated_calls': 'שיחות יזומות',
        'break': 'הפסקה',
        'logout': 'יציאה',

        'override_mode': 'מצב באנר'
    },

    'side_nav': {
        'status_incall': 'סטטוס: בשיחה',
        'status_nocall': 'סטטוס: לא בשיחה',
        'status_waiting_for_call': 'סטטוס: ממתין לשיחה',
        'status_incoming_ringing': 'שיחה נכנסת - מצלצל',
        'status_override': 'סטטוס: באנר',
        'time_in_call': 'משך זמן שיחה:',
        'time_on_hold': 'משך זמן המתנה:',
        'time_no_call': 'זמן ללא שיחה:',
        'immediate_call_label': 'חיוג ישיר:',
        'call_button': 'חייג',
        'call_again_button': 'חייג שוב',
        'hangup_button': 'נתק',
        'next_contact_button': 'איש קשר הבא',
        'enter_queue_button': 'כניסה לתור',
        'exit_queue_button': 'יציאה מהתור',
        'unavailable_button': 'תיעוד / יוצאות',
        'available_button': 'חזרה לזמינות',
    },

    'side_info': {
        'tab_general': 'כללי',
        'tab_calculator': 'מחשבון',
        'tab_documents': 'מסמכים',
        'tab_scenario': 'תסריט שיחה',

        'general': {
            'returns_title': 'חזרה לאנשי קשר היום',
            'returns_button': 'רשימה מלאה',
            'reason': 'סיבה',
            'time': 'שעה',
            'contact': 'איש קשר',
            'stats_title': 'נתוני הנציג',
            'stats_button': 'דו"ח מכירות',
            'notifications_title': 'הודעות'
        },

        'calculator': {
            title: 'מחשבון'
        },

        'documents': {
            title: 'מסמכים',
            view: 'צפיה',
            email: 'שליחה לאימייל',
            fax: 'שליחה לפקס'
        },

        'scenario': {
            title: 'תסריט שיחה'
        },

        'stats': {
            'daily_target': 'יעד יומי:',
            'daily_target_satisfaction': 'אחוז עמידה ביעד היומי:',
            'orders_this_month': 'כמות מכירות <span>(מתחילת החודש)</span>:',
            'calls_today': 'כמות שיחות מתחילת היום:',
            'saved_sessions': 'כמות תהליכים מתחילת היום',
            'personal_break_available': 'זמן הפסקה שנותר היום:'
        }
    },

    'login': {
        'title': 'כניסה למערכת',
        'username': 'שם משתמש:',
        'password': 'סיסמא:',
        'ext_number': 'מס\' שלוחה: ',
        'mode': 'מצב:',

        'mode_options': {
            'client': 'רגיל',
            //'override': 'באנר'
        },

        'submit': 'המשך >',
    },

    'order_statuses': {
        'open': 'פתוח',
        'closed': 'סגור'
    },

    'call_types': {
        'outgoing': 'שיחה יוצאת',
        'initiated': 'שיחה יזומה',
        'predictive': 'חייגן אוטומטי',
        'incoming': 'שיחה נכנסת',
        'current_call': 'שיחה נוכחית'
    },

    'call_actions': {
        'continue': 'חזרה לאיש קשר בעתיד',
        'end': 'סיום תהליך'
    },

    'call_history_statuses': {
        'dialing': "מחייג",
        'answered': "נענה",
        'busy': "תפוס",
        'noanswer': "אין מענה",
        'denied': "נדחה",
        'failed': "נכשל",
        'cancelled': "בוטל",
        'abandoned': 'ננטש'
    },

    'outgoing': {
        'title_format': '{{project_name}} | {{campaign_name}}',
        'empty_title': 'אין אנשי קשר',
        'empty_contacts': 'אין אנשי קשר',
        'predictive_waiting': 'ממתין לשיחה אוטומטית',
        'incoming_waiting': 'ממתין לשיחה',

        'client': {
            'title': 'פרטי איש הקשר',
            'more_details': 'פרטים נוספים'
        },
        'finish': {
            'title': 'תיעוד שיחה',
            'no_answer': 'אין מענה',
            'new_order': 'הזמנה חדשה',
            'continue_order': 'חזרה לאיש קשר בעתיד',
            'end_order': 'סגירה וסיום',
            'no-answer_title': 'אין מענה:',
            'continue_title': 'חזרה לאיש קשר בעתיד:',
            'end_title': 'סגירה וסיום:',
            'reason': 'סיבה:',
            'no-answer-reason_no-answer': 'אין מענה',
            'no-answer-reason_busy': 'תפוס',
            'no-answer-reason_wrong-number': 'מספר שגוי',
            'no-answer-reason_replace-phone': 'החלפת מספר',
            'continue_call_when': 'מתי לחזור:',
            'continue_call_by': 'חזרה ע"י:',
            'continue_call_by_me': 'רק אני',
            'continue_call_by_any': 'כל נציג',
            'date': 'תאריך:',
            'time': 'שעה:',
            'comments_title': 'הערות שיחה:',
            'save': 'שמירה'
        },
        'incoming': {
            'last_call': 'זוהי השיחה הנכנסת האחרונה',
            'current_call': 'זוהי השיחה הנכנסת הנוכחית'
        },

        'finish_periods': {
            '10mins': 'בעוד עשר דקות',
            '30mins': 'בעוד חצי שעה',
            '1hour': 'בעוד שעה',
            '2hours': 'בעוד שעתיים',
            '24hours': 'מחר באותה השעה',
            'other': 'אחר'
        },

        'tab_last_calls': 'שיחות אחרונות',
        'tab_orders': 'מכירות',

        'contact': {
            'first_name': 'שם פרטי:',
            'last_name': 'שם משפחה:',
            'company': 'חברה:',
            'idt': 'מספר מזהה:',
            'website_url': 'אתר:',
            'link_text': 'לחץ כאן',
            'phones': 'טלפונים:',
            'city': 'עיר:',
            'fax': 'פקס:',
            'gender': 'מין:',
        },

        'last_calls': {
            'type': 'סוג',
            'date': 'תאריך',
            'time': 'שעה',
            'campaign': 'קמפיין',
            'user': 'נציג/ה',
            'action': 'פעולה',
            'reason': 'פרטים',
            'comment': 'הערות'
        },

        'no_next_contact_message': 'אין תוצאות.',
        'no_specific_contact_message': 'אין גישה לאיש הקשר שנבחר.',
        'continue_previous_session_message': 'יש תהליך קיים, על מנת להתחיל תהליך חדש או לעבור לאיש קשר הבא,\nיש לעדכן סטטוס שיחה.',
        'no_current_call_event_message': 'אין תהליך אותו ניתן להמשיך או לסיים...\nיש לחייג לאיש קשר על מנת להתחיל תהליך.\nניתן גם לדלג לאיש הקשר הבא.',
        'no_number_to_call': 'אין מספרי טלפון לאיש קשר זה',
        'answer_before_saving_last_contact': 'אין לענות לשיחה לפני שמירה של השיחה הקודמת!'
    },

    'contact_popup': {
        'title_format': '<span>כרטיס איש קשר:</span> <b>{{name}}</b>',
        'title_new': 'הקמת איש קשר חדש',
        'tab_profile': 'פרטי איש קשר',
        'tab_call_history': 'היסטוריית אירועים',
        'tab_orders': 'הזמנות',

        'continue_session': 'המשך תהליך',

        'contact': {
            'title': 'פרטי איש הקשר'
        },

        'call_sessions': {
            'title': 'היסטוריית אירועים',
            'type': 'סוג',
            'date': 'תאריך',
            'time': 'שעה',
            'project': 'פרויקט',
            'campaign': 'קמפיין',
            'user': 'נציג/ה',
            'action': 'פעולה',
            'reason': 'פרטים',
            'comment': 'הערות'
        },

        'last_orders': {
            'title': 'דו"ח מכירות',
            'date': 'תאריך',
            'time': 'שעה',
            'user': 'נציג/ה',
            'campaign': 'קמפיין',
            'product': 'מוצר',
            'order': 'הזמנה מס\'',
            'status': 'סטטוס',
            'comment': 'הערות',
            'details': 'פרטי ההזמנה',
            'continue_order': 'המשך הזמנה',
            'view_order': 'צפה בהזמנה'
        }
    },

    'contact_fields': {
        'section_basic': 'כללי',
        'section_contact': 'פרטי יצירת קשר',
        'section_comments': 'הערות',
        'section_more': 'שדות נוספים',

        'title': 'תואר',
        'gender': 'מין',
        'idt': 'מספר מזהה',
        'first_name': 'שם פרטי',
        'last_name': 'שם משפחה',
        'company': 'חברה',
        'website_url': 'אתר',
        'birthdate': 'תאריך לידה',
        'phone': 'טלפון',
        'phone2': 'טלפון 2',
        'phone3': 'טלפון 3',
        'phone4': 'טלפון 4',
        'fax': 'פקס',
        'email': 'אימייל',
        'comment': 'הערות',
        'address_country': 'כתובת - ארץ',
        'address_state': 'כתובת - מדינה',
        'address_city': 'כתובת - עיר',
        'address_street': 'כתובת - רחוב',
        'address_street_number': 'כתובת - מס\' בית',
        'address_postal_code': 'כתובת - מיקוד',
        'address2_country': 'כתובת 2 - ארץ',
        'address2_state': 'כתובת 2 - מדינה',
        'address2_city': 'כתובת 2 - עיר',
        'address2_street': 'כתובת 2 - רחוב',
        'address2_street_number': 'כתובת 2 - מס\' בית',
        'address2_postal_code': 'כתובת 2 - מיקוד',

        'address': 'כתובת',
        'address2': 'כתובת נוספת',
        'country': 'ארץ',
        'state': 'מדינה',
        'city': 'עיר',
        'street': 'רחוב',
        'street_number': 'מס\' בית',
        'postal_code': 'מיקוד',

        'assign_to_campaign': 'שייך לקמפיין'
    },

    'genders': {
        '': '',
        'male': 'זכר',
        'female': 'נקבה'
    },

    'new_edit_contact': {
        'new_title': 'הקמת איש קשר חדש',
        'view_title': 'פרטי איש קשר'
    },

    'break_page': {
        'title_choose': 'בחירת סוג הפסקה:',
        'break_personal': 'הפסקה ארוכה',
        'break_official': 'משוב / תדריך',
        'break_short': 'הפסקה קצרה',
        'break_backoffice': 'בק אופיס',
        'official_break_length_format': '<b>משך זמן הפסקה:</b>',
        'official_break_start_format': 'שעת התחלה:',
        'personal_break_today_length_format': '<b>משך זמן הפסקה</b> <small>סה"כ היום</small>:',
        'personal_break_today_time_left_format': '<b>כמה זמן נשאר</b> <small>סה"כ היום</small>:',
        'personal_break_current_format': '<b>הפסקה נוכחית:</b>',
        'personal_break_length_format': '<b>משך זמן הפסקה נוכחית:</b>',
        'personal_break_start_format': '<b>שעת התחלה:</b>'
    },

    'break_popup': {
        'title': 'הפסקה'
    },

    'orders': {
        'exists_popup': {
            'title': 'הזמנה חדשה',
            'message_format': 'קיימת הזמנה קודמת שלא נסגרה<br />מתאריך {{date}}<br />האם להמשיך עם ההזמנה הקודמת או להתחיל הזמנה חדשה?',
            'continue': 'המשך הזמנה קודמת',
            'new': 'התחל הזמנה חדשה'
        },
        'product_title': 'מוצר:',
        'saved_campaign': 'בחירת קמפיין:',
        'saved_campaign_explain': 'איש הקשר נמצא כרגע בקמפיין <i>{{campaign_name}}</i>, במידה ומקור המכירה בפועל הוא שונה יש לציין כאן',
        'back_to_call': 'חזרה למסך שיחה',
        'view_order_title': 'צפייה בהזמנה',
        'new_order_title': 'הזמנה חדשה',
        'edit_order_title': 'המשך הזמנה',
        'save_order': 'שמירה ללא סיום',
        'end_order': 'שמירה וסיום',
        'end_order_and_duplicate': 'סיים הזמנה ושכפל'
    },

    'last_orders_page': {
        'title': 'דו"ח מכירות',
        'date': 'תאריך',
        'time': 'שעה',
        'user': 'נציג/ה',
        'contact': 'איש קשר',
        'campaign': 'קמפיין',
        'product': 'מוצר',
        'order': 'הזמנה מס\'',
        'status': 'סטטוס',
        'comment': 'הערות',
        'details': 'פרטי ההזמנה',
        'continue_order': 'המשך הזמנה',
        'view_order': 'צפה בהזמנה',
        'contact_details': 'פרטי איש הקשר',
        'contact_details_button': 'פרטים נוספים',
        'call': 'חייג לאיש קשר',
        'call_button': 'חייג לאיש קשר',
        'continue_session': 'המשך תהליך'
    },

    'call_history_page': {
        'title': 'היסטוריית שיחות',
        'type': 'סוג',
        'date': 'תאריך',
        'time': 'שעה',
        'contact': 'איש קשר',
        'number': 'מס\' טלפון',
        'project': 'פרויקט',
        'campaign': 'קמפיין',
        'user': 'נציג/ה',
        'action': 'פעולה',
        'reason': 'פרטים',
        'comment': 'הערות',
        'contact_details': 'פרטי איש הקשר',
        'contact_details_button': 'פרטים נוספים',
        'call': 'חייג לאיש קשר',
        'call_button': 'חייג לאיש קשר',
        'continue_session': 'המשך תהליך'
    },

    'call_contact_popup': {
        'title': 'חיוג לאיש קשר',
        'phone': 'מספר טלפון',
        'comment': 'הערות',
        'details': 'פרטי איש הקשר',
        'last_call_comment': 'מספר שיחה אחרונה',
        'call': 'חייג לאיש קשר',
        'no_numbers': 'אין מספרים'
    },

    'return_calls_area': {
        'title_today': '<span>חזרה לאיש קשר</span> <b>היום</b>',
        'title_tomorrow': '<span>חזרה לאיש קשר</span> <b>מחר</b>',
        'title_later': '<span>חזרה לאיש קשר</span> <b>בהמשך</b>',
        'return_date': 'תאריך חזרה',
        'return_time': 'שעת חזרה',
        'last_call': 'שיחה אחרונה',
        'contact_name': 'שם איש הקשר',
        'campaign': 'קמפיין',
        'reason': 'סיבה',
        'comment': 'הערות',
        'details': 'פרטי איש הקשר',
        'call': 'חייג לאיש קשר',
        'details_button': 'פרטים נוספים',
        'call_button': 'חייג לאיש קשר',
        'continue_session': 'המשך תהליך'
    },

    'initiated_calls_page': {
        'title': 'תפריט שיחות יזומות',
        'new_contact_button': 'הקמת איש קשר חדש',
        'tab_contacts': 'חיפוש אנשי קשר',
        'tab_returns': 'חזרה לאיש קשר',
        'tab_call_history': 'היסטוריית שיחות',
        'tab_orders': 'דו"ח מכירות',

        'search': {
            'phone': 'טלפון:',
            'free_search': 'חיפוש חופשי:',
            'campaign_filter': 'סינון לפי קמפיין:',
            'search_button': 'חיפוש >',
            'contact_details': 'פרטי איש הקשר',
            'contact_details_button': 'פרטים נוספים',
            'call': 'חייג לאיש קשר',
            'call_button': 'חייג לאיש קשר',
            'continue_session': 'המשך תהליך',
            'mapped_to': 'משוייך ל',
            'contact': 'שם איש הקשר',
            'campaign': 'קמפיין',
            'last_call': 'תאריך שיחה אחרונה'
        }
    },
    
    'default_alert_title': 'centerpoint',

    'yes': 'כן',
    'no': 'לא',
    'cancel': 'ביטול',
    'ok': 'אוקיי',
    'save': 'שמירה',
    'edit': 'עריכה'

}, {
    plural: function (count) {
        if (count === 0) return 'zero';
        if (count === 1) return 'one';
        if (count === 2) return 'two';
        return 'plural';
    }
}
];
