import $ from 'jquery';
import PhoneNumberUtil from '../utils/PhoneNumberUtil.mjs';

/** @enum CustomFieldType */
const CustomFieldType = {
    text: "text",
    multiline: "multiline",
    checkbox: "checkbox",
    checkboxlist: "checkboxlist",
    radio: "radio",
    dropdown: "dropdown"
};

/** @enum CustomFieldSubType */
const CustomFieldSubType = {
    plain: "plain",
    date: "date",
    tel: "tel"
};

class CustomFields {

    static renderField(def) {
        const $field = $('<div class="custom-field-preview">');

        $('<div class="title">').html((def['name'] + '').toHtml() + (def['required'] ? ' <sup>*</sup>' : '')).appendTo($field);

        if (def['explain']) {
            $('<div class="explain">').html(def['explain'].toHtml().replace(/\n/g, '<br />')).appendTo($field);
        }

        switch (def['type']) {
            case CustomFieldType.text:
                switch (def['subtype']) {
                    case CustomFieldSubType.date:
                        $('<input tabindex="-1" type="date">').appendTo($field);
                        break;
                    case CustomFieldSubType.tel:
                        $('<input tabindex="-1" type="tel">').appendTo($field);
                        break;
                    default:
                        $('<input tabindex="-1" type="text">').appendTo($field);
                        break;
                }
                break;

            case CustomFieldType.multiline:
                $('<textarea tabindex="-1">').appendTo($field);
                break;

            case CustomFieldType.dropdown:
                $('<select tabindex="-1">').appendTo($field);
                break;

            case CustomFieldType.checkbox:
                $('<label class="input-checkbox-label">').append($('<input tabindex="-1" type="checkbox">')).append($('<span>').text(def['name'])).appendTo($field);
                break;

            case CustomFieldType.checkboxlist:
            case CustomFieldType.radio:
                let type = def['type'] === CustomFieldType.radio ? 'radio' : 'checkbox';
                let options = [];
                try {
                    options = JSON.parse(def['options']);
                } catch (e) { }

                options.forEach(function (item, index) {
                    item = item + '';
                    var html = index ? '<br />' : '';
                    html += '<label class="input-checkbox-label"><input tabindex="-1" disabled="disabled" type="' + type + '" /><span>' + item.toHtml() + '</span></label>';
                    $(html).appendTo($field);
                });

                break;
        }

        return $field;
    }

    static customFieldValue(i18n, def, value) {
        switch (def['type']) {
            case CustomFieldType.text:
                switch (def['subtype']) {
                    case CustomFieldSubType.date: {
                        if (value) {
                            value = new Date(value);
                        }
                        return value ? i18n.formatDate(value, i18n.t('date_formats.normal_date'), 'normal_date') : '';
                    }
                    case CustomFieldSubType.tel:
                        return value ? PhoneNumberUtil.displayPhoneNumber(value) : '';

                    default:
                        return value || '';
                }

            case CustomFieldType.multiline:
            case CustomFieldType.checkboxlist:
            case CustomFieldType.radio:
            case CustomFieldType.dropdown:
                return value || '';

            case CustomFieldType.checkbox:
                return value ? i18n.t('yes') : i18n.t('no');
        }

        return '';
    }

    static customFieldValueHtml(i18n, def, value, showCheckboxes) {
        switch (def['type']) {
            case CustomFieldType.text:
                switch (def['subtype']) {
                    case CustomFieldSubType.date: {
                        if (value) {
                            value = new Date(value);
                        }
                        return value ? i18n.formatDate(value, i18n.t('date_formats.normal_date'), 'normal_date').toHtml() : '';
                    }
                    case CustomFieldSubType.tel:
                        return value ? PhoneNumberUtil.displayPhoneNumber(value).toHtml() : '';

                    default:
                        return (value || '').toHtml().replace(/\n/g, '<br />');
                }

            case CustomFieldType.multiline:
            case CustomFieldType.dropdown:
                return (value || '').toHtml().replace(/\n/g, '<br />');

            case CustomFieldType.checkbox:
                return Number(value) ? i18n.t('yes').toHtml() : i18n.t('no').toHtml();

            case CustomFieldType.checkboxlist:
            case CustomFieldType.radio:
                if (showCheckboxes) {
                    let randName = 'custfield' + Math.random().toString(36).replace(/[^a-zA-Z0-9]+/g, '').substr(0, 10);
                    let html = '';
                    let type = def['type'] === CustomFieldType.radio ? 'radio' : 'checkbox';
                    value = (value || '').split('\n');

                    let options = [];
                    try {
                        options = JSON.parse(def['options']);
                    } catch (e) { }

                    for (let i = 0; i < value.length; i++) {
                        if (value[i] && !options.includes(value[i])) {
                            options.push(value[i]);
                        }
                    }

                    for (let item of options) {
                        item = item + '';
                        if (html.length) html += '<br />';
                        html += '<label class="input-checkbox-label"><input disabled="disabled" type="' + type + '" name="' + randName + '" ' + (value.includes(item) ? 'checked="checked"' : '') + ' readonly="readonly" /><span>' + item.toHtml() + '</span></label>';
                    }

                    return html;
                } else {
                    return (value || '').toHtml().replace(/\n/g, '<br />');
                }
        }
        return '';
    }

    static customFieldInput(i18n, def, value, id, name) {
        switch (def['type']) {
            case CustomFieldType.text:
                switch (def['subtype']) {
                    case CustomFieldSubType.date: {
                        value = value ? i18n.formatDate(new Date(value), 'yyyy-MM-dd') : '';
                        return $('<input type="date">').val(value).attr('id', id).attr('name', name);
                    }
                    case CustomFieldSubType.tel: {
                        value = value ? PhoneNumberUtil.displayPhoneNumber(value) : '';
                        return $('<input type="tel">').val(value).attr('id', id).attr('name', name);
                    }

                    default:
                        return $('<input type="text">').val(value || '').attr('id', id).attr('name', name);
                }

            case CustomFieldType.multiline:
                return $('<textarea>').val(value || '').attr('id', id).attr('name', name);

            case CustomFieldType.checkbox:
                return $('<input type="checkbox">').val('title', def['name']).prop('checked', !!value).attr('id', id).attr('name', name);

            case CustomFieldType.checkboxlist: {
                let checks = [],
                    options = [];

                value = (value || '').split('\n');
                try {
                    options = JSON.parse(def['options']);
                } catch (e) { }

                for (let item of options) {
                    item = item + '';
                    let $check = $('<input type="checkbox">').val(item).prop('checked', value.includes(item)).attr('name', name);
                    let $label = $('<label class="input-checkbox-label">').append($check).append($('<span>').text(item));
                    if (checks.length) checks.push(document.createElement('br'));
                    checks.push($label[0]);
                }

                return $(checks);
            }

            case CustomFieldType.radio: {
                let radios = [], options = [];
                value = value || '';
                try {
                    options = JSON.parse(def['options']);
                } catch (e) { }
                for (let item of options) {
                    item = item + '';
                    let $radio = $('<input type="radio">').val(item).prop('checked', value == item).attr('name', name);
                    let $label = $('<label class="input-radio-label">').append($radio).append($('<span>').text(item));
                    if (radios.length) radios.push(document.createElement('br'));
                    radios.push($label[0]);
                }
                return $(radios);
            }

            case CustomFieldType.dropdown: {
                let $select = $('<select>').attr('name', name);
                let options = [];
                value = value || '';
                try {
                    options = JSON.parse(def['options']);
                } catch (e) { }
                $('<option>').val('').text('').appendTo($select);
                for (const item of options) {
                    $('<option>').val(item).text(item).attr('selected', value == item).appendTo($select);
                }
                return $select;
            }
        }

        return null;
    }

}

CustomFields.Type = CustomFieldType;
CustomFields.SubType = CustomFieldSubType;

export default CustomFields;