import $ from 'jquery';
import i18n, { t } from '../i18n/i18n.js';
import View from '../../controls/View.js';
import CustomFields from '../../shared_utils/CustomFields.js'

const OrderViewArea = View.extend({

    className: 'new-order-area',

    initialize: function (options) {
        var that = this;

        that.options = options;

        return that;
    },

    render: function () {
        var that = this;

        var order = that.options.order;

        that.$el.empty();

        // --- Base fields

        $('<div class="order-base-fields">').appendTo(that.$el).append(
            $('<h2 class="required">').text(t('orders.product_title')),
            $('<div class="value">').text(order['product_name'])
        );

        // --- Custom fields

        var $fields = that.$fields = $('<div class="order-fields">').appendTo(that.$el);

        if (order['custom_fields_def']) {

            order['custom_fields_def'].forEach(function (fieldDef) {
                var value = order['custom_fields'] ? order['custom_fields']['f' + fieldDef['id']] : '';

                var $field = $('<div class="order-field">');
                $('<h2>').toggleClass('required', !!fieldDef['required']).text(fieldDef['name']).appendTo($field);
                if (fieldDef['explain']) {
                    $('<div class="explain">').html(fieldDef['explain'].toHtml().replace(/\n/g, '<br />'));
                }
                $field.append(CustomFields.customFieldValueHtml(i18n, fieldDef, value, true));

                $fields.append($field);
            });
        }

        // --- Actions

        var $actions = $('<div class="actions">').appendTo(that.$el);

        if (that.options.fromCallScreen) {
            $('<button class="positive-button">').text(t('orders.back_to_call')).click(that.onBackToCall.bind(that)).appendTo($actions);
        }

        $('<button class="neutral-button">').text(t('edit')).click(that.onEdit.bind(that)).appendTo($actions);

        return that;
    },

    onBackToCall: function () {
        return this.trigger('backtocall');
    },

    onEdit: function () {
        return this.trigger('edit');
    }

});

export default OrderViewArea;