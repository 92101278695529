﻿export default ['en', {

    'rtl': false,
    'two_letter_iso_name': 'en',
    'iso_name': 'en-US',
    'strong_side': 'left',
    'weak_side': 'right',
    'google_lang_code': 'en',

    'activity_indicator': {
        'loading': 'Loading...',
        'saving': 'Saving...',
        'removing': 'Removing...',
        'loggingin': 'Logging In...',
        'loggingout': 'Logging Out...',
        'nextcontact': 'Looking for next contact...',
        'stopping_break': 'Stopping break...'
    },

    'size_abbrs': {
        'b': 'bytes',
        'kb': 'KB',
        'mb': 'MB',
        'gb': 'GB',
        'tb': 'TB'
    },

    'calendar': {
        'weekdays': ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        'weekdays_short': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        'weekdays_very_short': ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        'months': ['January','February','March','April','May','June','July','August','September','October','November','December'],
        'months_short': ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        'today': 'Today',
        'close': 'Close'
    },

    'date_formats': {
        'normal_date': 'dd/MM/yy',
        'short_time': 'HH:mm'
    },

    "error_codes": {
        "400": "An invalid request was sent to the server. Please contact your administrator, or try again.",
        "401": "You must be loggen on to perform this action.",
        "402": "This action was rejected by the server due to pending payments.",
        "403": "You are not authorized to perform this action.",
        "404": "The resource you requested does not exist.",
        "405": "An invalid request was sent to the server. Please contact your administrator, or try again.",
        "500": "An internal server error occurred. Please contact your administrator, or try again.",
        "501": "An unknown request has been sent to the server. Please contact your administrator, or try again.",
        "502": "Looks like you are using a failed proxy server. Please contact your administrator, or try again.",
        "503": "The service is currently unavailable. Please contact your administrator, or try again.",
        "504": "Looks like you are using a failed proxy server. Please contact your administrator, or try again.",
        "not-found": "The resource you requested does not exist.",
        'no-project': "You are not associated to any project",
        "not-allowed": "You are not authorized to perform this action.",
        "authorization-error": "You must be logged on to perform this action.",
        "invalid-email": "The provided email address is invalid.",
        "username-in-use": "The username provided is already in use.",
        'no-campaigns-associated': 'You are not associated to campaign. Please contact your administrator',
        "email-in-use": "The email address provided is already in use.",
        "empty-file": "The uploaded file is an empty file.",
        "ami-trouble": "PBX error, please contact your system administrator.",
        'predictive-trouble': 'The predictive dialer is not available, please contact your system administrator.',
        "403-no-match": "Username or password does not match.",
        "403-locked": "Your account is locked. Please contact your administrator.",
        "default": "An unknown error occurred. Please contact your administrator, or try again."
    },
    "main_nav": {
        "welcome_male": "Welcome <br/> {{name}}!",
        "welcome_female": "Welcome back <br/> {{name}}!",
        "welcome": "Welcome! <br/> {{name}}!",
        "outgoing_calls": "Outgoing calls",
        "initiated_calls": "Initiated calls",
        "break": "Breaks",
        "logout": "Log out",
        "override_mode": "Override Mode"
    },
    "side_nav": {
        "status_incall": "Status: In call",
        "status_nocall": "Status: Not in call",
        'status_waiting_for_call': 'Status: Waiting for call',
        "status_override": "Status: Override",
        "time_in_call": "Talk Duration:",
        "time_on_hold": "Standby time:",
        "time_no_call": "Time without call:",
        "immediate_call_label": "Direct dialing:",
        "call_button": "Dial",
        "call_again_button": "Redial",
        "hangup_button": "Hangup",
        "next_contact_button": "Next contact"
    },
    "side_info": {
        "tab_general": "General",
        "tab_calculator": "Calculator",
        "tab_documents": "Documents",
        "tab_scenario": "Call scenario",
        "general": {
            "returns_title": "Callbacks today",
            "returns_button": "Full list",
            "reason": "Reason",
            "time": "Time",
            "contact": "Contact",
            "stats_title": "Agent stat",
            "stats_button": "Sales report",
            "notifications_title": "Messages"
        },
        "calculator": {
            "title": "Calculator"
        },
        "documents": {
            "title": "Documents",
            "view": "View",
            "email": "Send to Email",
            "fax": "Send to Fax"
        },
        "scenario": {
            "title": "Call scenario"
        },
        "stats": {
            "daily_target": "Daily goal:",
            "daily_target_satisfaction": "Percentage of daily goal:",
            "orders_this_month": "Sales volume <span> (beginning of month) </span>:",
            "calls_today": "Number of calls today:",
            'saved_sessions': 'Number of sessions today:',
            "personal_break_available": "Break time remaining:"
        }
    },
    "login": {
        "title": "Login",
        "username": "Username:",
        "password": "Password:",
        "ext_number": "Extension:",
        "mode": "Status:",
        "mode_options": {
            "client": "Regular"
        },
        "submit": "Submit >",
    },
    "order_statuses": {
        "open": "Open",
        "closed": "Close"
    },
    "call_types": {
        "outgoing": "Outgoing Call",
        "initiated": "Initiated Call",
        'predictive': 'Predictive Call',
        'incoming': 'Incoming Call',
        "current_call": "Current Call"
    },
    "call_actions": {
        "continue": "Callback",
        "end": "End process"
    },
    "call_history_statuses": {
        "dialing": "Dialing",
        "answered": "Answered",
        "busy": "Busy",
        "noanswer": "No answer",
        "denied": "Rejected",
        "failed": "Failed",
        "cancelled": "Cancelled",
        'abandoned': 'Abandoned'
    },
    "outgoing": {
        "title_format": "{{project_name}} | {{campaign_name}}",
        "empty_title": "No title",
        "empty_contacts": "No contacts",
        'predictive_waiting': 'Waiting for Auto Dialer Call',
        "client": {
            "title": "Contact details",
            "more_details": "More details"
        },
        "finish": {
            "title": "Call notes",
            "no_answer": "No answer",
            "new_order": "New order",
            "continue_order": "Continue order",
            "end_order": "Close and finish",
            "no-answer_title": "No answer:",
            "continue_title": "Callback:",
            "end_title": "Close and finish:",
            "reason": "Reason:",
            "no-answer-reason_no-answer": "No answer",
            "no-answer-reason_busy": "Busy",
            "no-answer-reason_wrong-number": "Wrong number",
            "continue_call_when": "Callback at:",
            "continue_call_by": "Callback by:",
            "continue_call_by_me": "Only me",
            "continue_call_by_any": "Any agent",
            "date": "Date:",
            "time": "Time:",
            "comments_title": "Call notes:",
            "save": "Save"
        },
        "finish_periods": {
            "10mins": "In 10 minutes",
            "30mins": "In 30 minutes",
            "1hour": "In an hour",
            "2hours": "In 2 hours",
            "24hours": "Tomorrow at the same time",
            "other": "Other"
        },
        "tab_last_calls": "Last calls",
        "tab_orders": "Sales",
        "contact": {
            "first_name": "First name:",
            "last_name": "Last name:",
            'company': 'Company:',
            'idt': 'ID Number:',
            'website_url': 'URL:',
            'link_text': 'Click Here',
            "phones": "Phones:",
            "city": "City:",
            "fax": "Fax:",
            "gender": "Sex:"
        },
        "last_calls": {
            "type": "Type",
            "date": "Date",
            "time": "Time",
            "campaign": "Campaign",
            "user": "Agent",
            "action": "Action",
            "reason": "Details",
            "comment": "Notes"
        },
        "no_next_contact_message": "No results.",
        "no_specific_contact_message": "You don't have access the selected contact.",
        "continue_previous_session_message": "There is an existing process, to start a new one the call status must be updated.",
        "no_current_call_event_message": "There is no process that can be continued or terminated ...\nYou must dial a contact to start a process.\nYou can also skip to the next contact.",
        "no_number_to_call": "There are no phone numbers for this contact"
    },
    "contact_popup": {
        "title_format": "<span> Contact Card: </span> <b> {{name}} </b>",
        "title_new": "Create a new contact",
        "tab_profile": "Contact details",
        "tab_call_history": "Call history",
        "tab_orders": "Orders",
        "continue_session": "Continue process",
        "contact": {
            "title": "Contact details"
        },
        "call_history": {
            "title": "Call history",
            "type": "Type",
            "date": "Date",
            "time": "Time",
            "project": "Project",
            "campaign": "Campaign",
            "user": "Agent",
            "action": "Action",
            "reason": "Details",
            "comment": "Notes"
        },
        "last_orders": {
            "title": "Sales report",
            "date": "Date",
            "time": "Time",
            "user": "Agent",
            "campaign": "Campaign",
            "product": "Product",
            "order": "Order num",
            "status": "Status",
            "comment": "Notes",
            "details": "Order details",
            "continue_order": "Continue order",
            "view_order": "View order"
        }
    },
    "contact_fields": {
        "section_basic": "General",
        "section_contact": "Contact info",
        "section_comments": "Notes",
        "section_more": "More fields",
        "title": "Title",
        "gender": "Sex",
        "idt": "ID Num",
        "first_name": "First name",
        "last_name": "Last name",
        "company": "Company",
        "website_url": "Website",
        "birthdate": "Birth date",
        "phone": "Phone",
        "phone2": "Phone 2",
        "phone3": "Phone 3",
        "phone4": "Phone 4",
        "fax": "Fax",
        "email": "Email",
        "comment": "Notes",
        "address_country": "Address - Country",
        "address_state": "Address - State",
        "address_city": "Address - City",
        "address_street": "Address - Street",
        "address_street_number": "Address - House number",
        "address_postal_code": "Address - Zip code",
        "address2_country": "Address 2 - Country",
        "address2_state": "Address 2 - Country",
        "address2_city": "Address 2 - City",
        "address2_street": "Address 2 - Street",
        "address2_street_number": "Address 2 - House no",
        "address2_postal_code": "Address 2 - Zip code",
        "address": "Address",
        "address2": "Additional address",
        "country": "Country",
        "state": "State",
        "city": "City",
        "street": "Street",
        "street_number": "House no",
        "postal_code": "Zip code",
        "assign_to_campaign": "Assign to campaign"
    },
    "genders": {
        "male": "Male",
        "female": "Female"
    },
    "new_edit_contact": {
        "new_title": "New contact",
        "view_title": "Contact details"
    },
    "break_page": {
        "title_choose": "Break type",
        "break_personal": "Personal break",
        "break_official": "Official break",
        'break_short': 'Short break',
        'break_backoffice': 'Back Office',
        "official_break_length_format": "<b> Break duration: </b>",
        "official_break_start_format": "Beginning time:",
        "personal_break_today_length_format": "<b> Break duration </b><small> Total today</small>:",
        "personal_break_today_time_left_format": "<b> How long remains</b><small> Total today</small>:",
        "personal_break_current_format": "<b> Current break: </b>",
        "personal_break_length_format": "<b> Current break duration: </b>",
        "personal_break_start_format": "<b> Start time: </b>"
    },
    "break_popup": {
        "title": "Break"
    },
    "orders": {
        "exists_popup": {
            "title": "New order",
            "message_format": "A previous order that has not been closed <br/> dated {{date}} <br/> still exist. Continue with the previous order or start a new order?",
            "continue": "Continue a previous order",
            "new": "Start a new order"
        },
        "product_title": "Product:",
        "saved_campaign": "Select campaign:",
        "saved_campaign_explain": "The contact is currently in the <i> {{campaign_name}} </i> campaign, if the source of the actual sale is different please specify it here",
        "back_to_call": "Back to call screen",
        "view_order_title": "View order",
        "new_order_title": "New order",
        "edit_order_title": "Continue order",
        "save_order": "Save without close",
        "end_order": "Save and close",
        "end_order_and_duplicate": "Close and duplicate"
    },
    "last_orders_page": {
        "title": "Sales report",
        "date": "Date",
        "time": "Time",
        "user": "Agent",
        "contact": "Contact",
        "campaign": "Campaign",
        "product": "Product",
        "order": "Order num",
        "status": "Status",
        "comment": "Notes",
        "details": "Details",
        "continue_order": "Continue order",
        "view_order": "View order",
        "contact_details": "Contact details",
        "contact_details_button": "More details",
        "call": "Call contact",
        "call_button": "Call contact",
        "continue_session": "Continue process"
    },
    "call_history_page": {
        "title": "Call history",
        "type": "Type",
        "date": "Date",
        "time": "Time",
        "contact": "Contact",
        "number": "Phone number",
        "project": "Project",
        "campaign": "Campaign",
        "user": "Agent",
        "action": "Action",
        "reason": "Details",
        "comment": "Notes",
        "contact_details": "Contact details",
        "contact_details_button": "More details",
        "call": "Call contact",
        "call_button": "Call contact",
        "continue_session": "Continue process"
    },
    "call_contact_popup": {
        "title": "Call contact",
        "phone": "Phone number",
        "comment": "Notes",
        "details": "Contact details",
        "last_call_comment": "Last call notes",
        "call": "Call contact",
        "no_numbers": "No numbers"
    },
    "return_calls_area": {
        "title_today": "<span> Callback</span><b> Today </b>",
        "title_tomorrow": "<span> Callback</span><b> tomorrow</b>",
        "title_later": "<span> Callback</span><b> later</b>",
        "return_date": "Callback date",
        "return_time": "Callback time",
        "last_call": "Last call",
        "contact_name": "Contact name",
        "campaign": "Campaign",
        "reason": "Reason",
        "comment": "Notes",
        "details": "Contact details",
        "call": "Call contact",
        "details_button": "More details",
        "call_button": "Call contact",
        "continue_session": "Continue process"
    },
    "initiated_calls_page": {
        "title": "Initiated calls menu",
        "new_contact_button": "New contact",
        "tab_contacts": "Search contact",
        "tab_returns": "Callbacks",
        "tab_call_history": "Call history",
        "tab_orders": "Sales report",
        "search": {
            "phone": "Phone:",
            "free_search": "Free search:",
            "campaign_filter": "Filter by campaign:",
            "search_button": "Search:",
            "contact_details": "Contact details",
            "contact_details_button": "More details",
            "call": "Call contact",
            "call_button": "Call contact",
            "continue_session": "Continue process",
            "mapped_to": "Mapped to",
            "contact": "Contact name",
            "campaign": "Campaign",
            "last_call": "Last call date"
        }
    },
    "default_alert_title": "CenterPoint",
    "yes": "Yes",
    "no": "No",
    "cancel": "Cancel",
    "ok": "OK",
    "save": "Save",
    "edit": "Edit"
}, {
    plural: function (count) {
        if (count === 0) return 'zero';
        if (count === 1) return 'one';
        return 'plural';
    }
}
];
