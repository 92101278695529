import $ from 'jquery';
import Backbone from 'backbone';

class PreventNavUtil {
    /**
     * @param {*} i18n
     * @param {AlertUI} alertUI
     */
    constructor(i18n, alertUI) {
        /** @private */
        const p = this._p = {
            /**
             * @type {function():Boolean}
             * @private
             */
            hasUnsavedChangesHandler: null,

            /** @private */
            lastFragment: null,

            /** @private */
            lastFragmentTime: null,

            /** @private */
            lastAllowNavigate: null,
        };

        /** @private */
        this._t = i18n.t;

        $(window).on('beforeunload', event => {
            if (p.hasUnsavedChangesHandler && p.hasUnsavedChangesHandler()) {
                // Let the browser notify about unsaved changes.
                // We can't do more than that here.
                event.preventDefault();
                return false;
            }
        });

        /** @private */
        p.originalCheckUrl = Backbone.history.checkUrl;

        // Punch through the `checkUrl` method, just to keep track of things
        Backbone.history.checkUrl = function () {
            const history = Backbone.history; // Might be called with a global `this`. We need `history`/

            if (Date.now() - p.lastFragmentTime > 10) {
                p.lastFragmentTime = Date.now();
                p.lastFragment = history.fragment;
            }

            return p.originalCheckUrl.apply(history, arguments);
        };

        // Punch through the `navigate` method, just to keep track of things
        p.originalNavigate = Backbone.history.navigate;

        Backbone.history.navigate = function () {
            const history = Backbone.history; // Might be called with a global `this`. We need `history`/

            if (Date.now() - p.lastFragmentTime > 10) {
                p.lastFragmentTime = Date.now();
                p.lastFragment = history.fragment;
            }

            return p.originalNavigate.apply(history, arguments);
        };

        // Punch through the `loadUrl` method
        p.originalLoadUrl = Backbone.history.loadUrl;

        Backbone.history.loadUrl = function (fragment) {
            const history = Backbone.history,
                originalArguments = arguments;

            if (p.hasUnsavedChangesHandler) {
                let unsavedResult = p.hasUnsavedChangesHandler();

                if (unsavedResult &&
                    // And there was no allowing callback immediately before this one
                    (p.lastAllowNavigate == null || Date.now() - p.lastAllowNavigate > 10)) {

                    // This is how Backbone.js retrieves the updated fragment
                    fragment = history.getFragment(fragment);

                    // When being called from `checkUrl`, fragment should be set inside `loadUrl`.
                    // Allow original update to take place, so returning back to same fragment won't fail.
                    history.fragment = fragment;

                    if (typeof unsavedResult !== 'function') {
                        unsavedResult = (fragment, navigate) => {
                            alertUI.confirmNegative(i18n.t('generic_prompts.confirm_leave_unsaved'),
                                null,
                                () => navigate(true),
                                () => navigate(false));
                        };
                    }

                    unsavedResult(fragment, (navigate) => {
                        if (navigate) {
                            // Allow navigation!
                            p.lastAllowNavigate = Date.now();

                            // Call original `loadUrl`
                            p.originalLoadUrl.apply(history, originalArguments);
                        } else {
                            // No navigation allowed!
                            p.lastAllowNavigate = Date.now();

                            // Go back to previous fragment.
                            // With `trigger = false`, the `loadUrl` will not be called by this.
                            Backbone.history.navigate(p.lastFragment, { trigger: false });
                        }
                    });

                    return false;
                }
            }

            // Fall-through to original `loadUrl`
            p.originalLoadUrl.apply(history, originalArguments);
        };

    }

    /**
     * Sets a handler that tells whether we should prevent navigation away from current fragment.
     * The `hasUnsavedChanges` is a function returning `true`/`false`
     * @param {function():Boolean|null} hasUnsavedChangesHandler
     * @returns {this} self
     */
    setHasUnsavedChangedHandler(hasUnsavedChangesHandler) {
        this._p.hasUnsavedChangesHandler = hasUnsavedChangesHandler;
        return this;
    }
}

export default PreventNavUtil;
