import WsCommBase from '../../../../utils/WsCommBase.js';
import { addEvents } from '../utils/EventsBase.js';

const ManualDisconnectSymbol = Symbol('manual_disconnect');

/**
 * @implements IEventsProvider
 */
class Comm extends WsCommBase {

    /**
     *
     * @param {string} url
     * @param {number} [timeout]
     * @param {boolean} [autoConnect = true]
     * @param {number} [autoConnectTimeout = 30000]
     * @param {boolean} [autoReconnect = true]
     * @param {number} [retryMin = 1000]
     * @param {number} [retryMax = 60000]
     * @param {function(id:number,message:*,shouldReturn:boolean)} [onMessage]
     */
    constructor({
                    url,
                    timeout,
                    autoConnect = true,
                    autoConnectTimeout = 30000,
                    autoReconnect = true,
                    retryMin = 1000,
                    retryMax = 20000,
                    onMessage,
                } = {}) {
        super({
            onDisconnect: () => this.emit('disconnect'),
            onConnect: () => this.emit('connect'),
            onMessage: onMessage
        });

        Object.assign(this._p, {
            retry: false,
            url,
            timeout,
            autoConnect,
            autoConnectTimeout,
            autoReconnect,
            retryMin,
            retryMax,
        });
    }

    async reconnect() {
        const p = this._p;

        let resolve, reject;
        let promise = new Promise((r, j) => {
            resolve = r;
            reject = j;
        });

        if (p._retryTimeout) {
            clearTimeout(p._retryTimeout);
            delete p._retryTimeout;
        }

        this.disconnect(true);

        let conn = new WebSocket(p.url);
        this._setupConnection(conn);

        conn.addEventListener('close', _event => {
            // Auto reconnect with backoff
            if (p.autoReconnect && !conn[ManualDisconnectSymbol]) {
                if (p.retry === false) {
                    p.retry = p.retryMin || 0;
                } else {
                    p.retry = Math.min(p.retry > 0 ? p.retry * 2 : 1000, p.retryMax);
                }

                if (p._retryTimeout) {
                    clearTimeout(p._retryTimeout);
                }

                p._retryTimeout = setTimeout(() => {
                    if (this.isConnectionOpen) return;
                    this.reconnect();
                }, p.retry);
            }
        });

        conn.addEventListener('error', () => reject());

        conn.addEventListener('open', () => {
            p.retry = false;
            resolve();

            if (p.openCallbacks.length > 0) {
                let cbs = p.openCallbacks;
                p.openCallbacks = [];
                for (let cb of cbs)
                    cb();
            }
        });

        return promise;
    }

    async waitForOpenConnection(timeout = Infinity) {
        if (!this.isConnectionOpen) {
            let resolve = null, reject = null;
            let promise = new Promise((r, j) => {
                resolve = r;
                reject = j;
            });

            if (Number.isFinite(timeout)) {
                setTimeout(() => {
                    reject(new Error('Timed out waiting for a connection'));
                }, timeout);
            }

            this._p.openCallbacks.push(resolve);

            await this.reconnect();

            return promise;
        }
    }

    /**
     * Send a message and optionally wait for a result
     * @param {*} data - message to send
     * @param {boolean} [expectResult=false] - should we expect a result for this message
     * @returns {Promise<*>} - promise for after the message was sent, or with the result if `expectResult=true`
     */
    async sendMessage(data, expectResult = false) {
        const p = this._p;

        if (!this.isConnectionOpen) {
            if (!p.autoConnect) {
                throw new Error('Not connected');
            }
            else {
                await this.waitForOpenConnection(p.autoConnectTimeout);
            }
        }

        return super.sendMessage(data, expectResult);
    }

    /**
     * Send result for an incoming message
     * @param {number} id - original message id
     * @param {*} result - the result
     * @returns {boolean} - true if the result was sent successfully
     */
    sendMessageResult(id, result) {
        if (!this.isConnectionOpen) {
            return false;
        }

        return super.sendMessageResult(id, result);
    }

    disconnect(emitEvent = true) {
        if (this._p.conn) {
            this._p.conn[ManualDisconnectSymbol] = true;
        }

        return super.disconnect(emitEvent);
    }
}

addEvents(Comm.prototype);

export default Comm;