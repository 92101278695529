import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';

const ResizeManager = {};

_.extend(ResizeManager, Backbone.Events);

ResizeManager.emit = function() {
    return ResizeManager.trigger("resize", {width: window.innerWidth, height: window.innerHeight})
};

const boundEmit = _.throttle(ResizeManager.emit, 100);
let started = false;

ResizeManager.start = function() {
    if (!started) {
        $(window).on("resize", boundEmit);
        started = true;
    }
    return this;
};

ResizeManager.stop = function() {
    if (started) {
        $(window).off("resize", boundEmit);
        started = false;
    }
    return this;
};

ResizeManager.start();

export default ResizeManager;