
export const CONTACT_BASIC_FIELDS = [
    'title', 'gender', 'idt', 'first_name', 'last_name', 'company', 'website_url', 'birthdate',
    'phone', 'phone2', 'phone3', 'phone4', 'fax', 'email',
    'address_country', 'address_state', 'address_city', 'address_street', 'address_street_number', 'address_postal_code',
    'address2_country', 'address2_state', 'address2_city', 'address2_street', 'address2_street_number', 'address2_postal_code',
    'comment',
];

export const ORDER_BASIC_FIELDS = [
    'id', 'product_id', 'campaign_id', 'contact_id', 'status', 'created_at', 'created_by', 'updated_at', 'closed_at', 'closed_by',
];

export const USER_BASIC_FIELDS = [
    'id', 'project_id', 'team_id', 'username', 'password', 'idt',
    'first_name', 'last_name', 'birthdate', 'gender', 'address',
    'phone', 'phone2', 'work_email', 'job_start', 'clock_number',
    'hourly_rate', 'comment', 'personal_break_minutes', 'is_active', 'external_id',
]
