import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import BreakPopup from './popups/BreakPopup.js';
import app from './App.js';

/**
 * @const
 * @type {number}
 */
let INTERVAL = 30 * 1000;

/**
 * @enum
 */
const UserBreakType = {
    personal: "personal",
    official: "official",
    outgoing: "outgoing",
    short: "short",
    backoffice: "backoffice"
};

let currentStatsData = null;

class UserStats {

    static queryStats() {
        var that = this;

        if (this._xhr) {
            this._xhr.abort();
            this._xhr = null;
        }

        (that._xhr = $.get(app.apiUrl('me/stats')))
            .then(function (data) {

                currentStatsData = data;

                UserStats.trigger('data', data);

                that._updateBreakPopup();

            })
            .fail(function (xhr, error) {
                app.handleXhrLoginRequired(xhr, error);
            })
            .always(function () {
                if (that._queryTimeout) {
                    that._queryTimeout = setTimeout(function () { that.queryStats(); }, INTERVAL);
                }
            });

        return that;
    }

    static _updateBreakPopup() {
        const that = this,
            data = currentStatsData;

        that.stopListening(Backbone.history, 'route');

        if (data && data['break_type'] && !/^break(\/|\?|$)/.test(Backbone.history.getFragment())) {

            if (data['break_type'] === UserBreakType.outgoing) return;

            if (!that._breakPopup) {

                const popup = that._breakPopup = new BreakPopup().show();

                that.listenTo(popup, 'cancel', () => delete that._breakPopup);

            }

            that._breakPopup.setBreakData({
                'type': data['break_type'],
                'start': data['break_start'],
                'today_total': data['break_type'] === 'personal' ? data['personal_break_minutes'] * 60 : null,
                'today_length': data['break_type'] === 'personal' ? data['personal_break_duration'] : null
            });

        } else {

            if (that._breakPopup) {

                that._breakPopup.remove();
                delete that._breakPopup;

                that.stopListening(Backbone.history);
            }

        }

        // Show/hide when moving into/ out of the break page, maybe with back/forward buttons
        if (data && data['break_type']) {
            that.listenTo(Backbone.history, 'route', () => that._updateBreakPopup());
        }

        return that;
    }

    static startQueryingStats(startImmediately) {
        var that = this;

        that.stopQueryingStats();

        if (startImmediately) {
            that._queryTimeout = true;
            that.queryStats();
        } else {
            that._queryTimeout = setTimeout(function () { that.queryStats(); }, INTERVAL);
        }

        return that;
    }

    static stopQueryingStats() {
        if (this._queryTimeout) {
            clearTimeout(this._queryTimeout);
            this._queryTimeout = null;
        }
        if (this._xhr) {
            this._xhr.abort();
            this._xhr = null;
        }
        return this;
    }

    static getUserStats() {
        return currentStatsData;
    }

    static resetUserStats() {
        var that = this;

        currentStatsData = null;

        that._updateBreakPopup();

        return that;
    }

    static clearBreakStatus() {
        if (!currentStatsData) return this;

        currentStatsData['break_type'] = null;
        currentStatsData['break_start'] = null;

        return this;
    }

    static setBreakStatus(type, start) {
        if (!currentStatsData) return this;

        currentStatsData['break_type'] = type;
        currentStatsData['break_start'] = start;

        this._updateBreakPopup();

        return this;
    }

}

_.extend(UserStats, Backbone.Events);

export default UserStats;