import '../../client/css/main.scss';

import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import i18n, {t} from './i18n/i18n.js';

// Require jquery plugins & extensions
import '../jquery-plugins.js';
import '../jquery-extensions.js';

// Require generic prototype extensions
import '../extensions.js';

import {apiUrl, wsUrl} from '../shared_utils/baseUrls';
import MessagePopupView from '../controls/MessagePopupView.js';
import NTP from '../utils/NTP.js';
import AlertUI from '../appHelpers/Alert.js';
import PreventNavUtil from '../appHelpers/PreventNav.js';
import TableAutoSavingConfigUtil from '../appHelpers/TableAutoSavingConfig.js';
import UserStats from './UserStats.js';
import Calls from './Calls.js';

// Require the main app namespace
import app from './App.js';

// Other stuff we use here
import Vue from 'vue';
import Router from './routers/Router.js';
import MainNavView from './views/MainNav.js';
import SideNav from './views/SideNav.vue';
import SideInfo from './views/SideInfo.js';
import comm from './Comm.js';
import VueTimersMixin from '../extensions/VueTimersMixin.js';
import VueAjaxMixin from '../extensions/VueAjaxMixin.js';

Vue.mixin({
    data() {
        return {
            i18n: i18n,
            t: t,
        }
    }
});

Vue.mixin(VueTimersMixin).mixin(VueAjaxMixin);

const STORAGE_KEY_PHONE_EXTENSION = 'phone_extension';

const keepalive = () => { comm.sendMessage({ type: 'keepalive' }).catch(err => console.warn('keep alive err')) };

// We need this to avoid a circular dependency
app.UserStats = UserStats;

app.AlertUI = new AlertUI(i18n);
app.PreventNavUtil = new PreventNavUtil(i18n, app.AlertUI);
app.TableAutoSavingConfigUtil = new TableAutoSavingConfigUtil(app);

// All of these should be extracted to separate importable functions

// showXhrError
(function () {

    app.showXhrError = function (xhr, error) {
        if (error === 'abort') return;

        let errorCode;
        try {
            errorCode = xhr.responseJSON ? xhr.responseJSON['error'] : JSON.parse(xhr.responseText)['error'];
        } catch (e) {
        }
        errorCode = errorCode || error;

        let errorCodes = t('error_codes'),
            msg = errorCodes['default'];

        if (errorCode === 'authorization-error') {

            let location = '/client/login';
            if (Backbone.history.getFragment()) {
                location += '?return_url=' + encodeURIComponent(Backbone.history.getFragment());
            }

            window.location = location;
            return;
        }

        if (errorCode && errorCodes[xhr.status.toString() + '-' + errorCode]) {
            msg = errorCodes[xhr.status.toString() + '-' + errorCode];
        } else if (errorCode && errorCodes[errorCode]) {
            msg = errorCodes[errorCode];
        } else if (errorCodes[xhr.status.toString()]) {
            msg = errorCodes[xhr.status.toString()];
        }

        new MessagePopupView({addClass: 'error', message: msg, queue: true}).show();

        return this;
    };

    // Redirect to login if authorization is required, otherwise ignore the error

    app.handleXhrLoginRequired = function (xhr, error) {
        if (error === 'abort') return;

        let errorCode;
        try {
            errorCode = xhr.responseJSON ? xhr.responseJSON['error'] : JSON.parse(xhr.responseText)['error'];
        } catch (e) {
        }
        errorCode = errorCode || error;

        if (errorCode === 'authorization-error') {

            let location = '/client/login';
            if (Backbone.history.getFragment()) {
                location += '?return_url=' + encodeURIComponent(Backbone.history.getFragment());
            }

            window.location = location;
            return;
        }

        return this;
    };


})();

// API urls - Temp until adding import to different files
app.apiUrl = apiUrl;
app.wsUrl = wsUrl;

(function(){
    let userSettings = {}, userSettingsDebouncedUpdates = {};
    app.setUserSettings = function (value) {
        userSettings = value || {};
        return this;
    };
    app.getUserSetting = function (key) {
        const conf = userSettings[key];
        if (conf) {
            return JSON.parse(conf);
        }
        return null;
    };
    app.setUserSetting = function (key, conf) {
        conf = JSON.stringify(conf);
        const oldConf = userSettings[key];
        if (conf !== oldConf) {
            userSettings[key] = conf;

            let debounced = userSettingsDebouncedUpdates[key];
            if (!debounced) {
                debounced = userSettingsDebouncedUpdates[key] = _.debounce(function () {
                    $.ajaxJsonPost(app.apiUrl('me/settings/' + encodeURIComponent(key)), { 'value': userSettings[key] || '' });
                }, 500);
            }

            // Debounce the settings update
            debounced();
        }
        return this;
    };
})();

// User details
(function (app) {

    let _userId = null,
        _userName = '',
        _firstName = '',
        _lastName = '',
        _gender = null,
        _loginMode = '',
        _externalId = '',
        _phoneExtension = localStorage.getItem(STORAGE_KEY_PHONE_EXTENSION) || '',
        _projectMode = '',
        _roles = [],
        _permissions = [],
        _permissionsMap = {},
        _generalSettings = {};

    app.isLoggedIn = function () {
        return !!_userId;
    };

    app.setLoggedOut = function () {
        _userId = _gender = _loginMode = _projectMode = null;
        _userName = _firstName = _lastName = '';
        _roles = _permissions = [];
        _permissionsMap = {};

        UserStats
            .stopQueryingStats()
            .resetUserStats();

        return this;
    };

    app.setUserId = function (userId) {
        _userId = userId;
        return this;
    };
    app.getUserId = function () {
        return _userId;
    };

    app.getUsername = function () {
        return _userName;
    };

    app.setUsername = function (username) {
        _userName = username || '';
        return this;
    };

    app.getFirstName = function () {
        return _firstName;
    };

    app.setFirstName = function (firstName) {
        _firstName = firstName || '';
        return this;
    };

    app.getLastName = function () {
        return _lastName;
    };

    app.setLastName = function (lastName) {
        _lastName = lastName || '';
        return this;
    };

    app.getGender = function () {
        return _gender;
    };

    app.setGender = function (gender) {
        _gender = gender || '';
        return this;
    };

    app.getExternalId = function () {
        return _externalId;
    };

    app.setExternalId = function (externalId) {
        _externalId = externalId || '';
        return this;
    };

    app.getLoginMode = function () {
        return _loginMode;
    };

    app.setLoginMode = function (loginMode) {
        _loginMode = loginMode || '';
        return this;
    };

    app.setPhoneExtension = function (phoneExtension) {
        _phoneExtension = phoneExtension || '';
        localStorage.setItem(STORAGE_KEY_PHONE_EXTENSION, phoneExtension);
        return this;
    };

    app.getPhoneExtension = function () {
        return _phoneExtension;
    };

    app.getProjectMode = function () {
        return _projectMode;
    };

    app.setProjectMode = function (projectMode) {
        _projectMode = projectMode || '';
        return this;
    };

    app.setUserRoles = function (value) {
        _roles = value || [];
        return this;
    };

    app.getUserRoles = function () {
        return _roles.splice(0);
    };

    app.setUserPermissions = function (permissions) {
        _permissions = permissions || [];
        _permissionsMap = {};
        for (let i = 0, len = _permissions.length; i < len; i++) {
            _permissionsMap[_permissions[i]] = true;
        }
        return this;
    };

    app.hasPermission = function (perm) {
        return _permissionsMap[perm] === true;
    };

    app.hasAnyPermissionIn = function () {
        let perms = arguments;
        if (perms.length === 1 && perms[0].splice) {
            perms = perms[0];
        }
        for (let i = 0; i < perms.length; i++) {
            if (_permissionsMap[perms[i]] === true) return true;
        }
        return false;
    };

    app.doAfterLogin = function () {

        // Start fetching user stats periodically
        UserStats.startQueryingStats(true);

        comm.on('connect', () => {
            Calls.registerToCallStatus();
        });

        comm.on('agent_call_stat', Calls.updateCallStatus);

        comm.on('incoming_call', Calls.incomingCallUpdate);

        keepalive();

        // Reload SideInfo data
        if (app.getSideInfo()) {
            app.getSideInfo().reload();
        }

        return this;
    };

    app.getCachedGeneralSetting = function (key) {
        return _generalSettings[key];
    };

    app.extendCachedGeneralSettings = function (settings) {
        _.extend(_generalSettings, settings || {});
        return this;
    };

})(app);

(() => {

    let _timer = null;

    app.startKeepaliveTimer = () => {
        app.stopKeepaliveTimer();

        _timer = setInterval(keepalive, 30000);

    };

    app.stopKeepaliveTimer = () => {
        if (_timer) {
            clearInterval(_timer);
            _timer = null;
        }
    };

})();

(function (app) {

    let mainNav = null,
        mainView = null,
        sideNav = null,
        sideInfo = null;

    app.setupMainNav = function () {

        if (!mainNav) {
            mainNav = new MainNavView()
                .render()
                .setLogo('/resources/client/images/logo.png');
        }

        $('#main-nav-placeholder').replaceWith(mainNav.$el);
    };

    app.getMainNav = function () {
        return mainNav;
    };

    app.setMainView = function (view, detach) {
        if (view === mainView) return this;

        if (mainView) {
            if (typeof mainView.hasUnsavedChanges === 'function') {
                app.PreventNavUtil.setHasUnsavedChangedHandler(null);
            }

            if (detach) {
                mainView.$el.detach();
            } else {
                mainView.remove();
            }
        }

        mainView = view;

        if (mainView) {
            mainView.$el.appendTo(document.body);
            mainView.render();

            if (typeof mainView.hasUnsavedChanges === 'function') {
                app.PreventNavUtil.setHasUnsavedChangedHandler(mainView.hasUnsavedChanges.bind(mainView));
            }
        }

        return this;
    };

    app.getMainView = function () {
        return mainView;
    };

    app.showSideNav = function () {

        if (!sideNav) {
            sideNav = new (Vue.extend(SideNav))({
                data: {
                },
            });

            // When all global data (and Calls) is watchable by Vue,
            // we could remove this.
            sideNav.resyncData();

            sideNav.$mount('#side-nav-placeholder');
        }

        $(sideNav.$el).show();

        $(document.body).addClass('has-side-nav');

        return this;
    };

    app.hideSideNav = function () {
        if (sideNav) {
            $(sideNav.$el).hide();
        }
        $(document.body).removeClass('has-side-nav');
        return this;
    };

    app.getSideNav = function () {
        return sideNav;
    };

    app.removeSideNav = function () {
        if (!sideNav) return this;

        sideNav.$destroy();
        $(sideNav.$el).replaceWith('<div id=side-nav-placeholder>');
        sideNav = undefined;

        return this;
    };

    app.showSideInfo = function () {

        if (!sideInfo) {
            sideInfo = new SideInfo().render();
        }
        if (!sideInfo.$el[0].parentNode) {
            sideInfo.$el.appendTo(document.body);
        }
        sideInfo.$el.show();
        $(document.body).addClass('has-side-info');

        return this;
    };

    app.hideSideInfo = function () {
        if (sideInfo) {
            sideInfo.$el.hide();
        }
        $(document.body).removeClass('has-side-info');
        return this;
    };
    app.getSideInfo = function () {
        return sideInfo;
    };

})(app);

app.bootstrap = function () {

    //i18n.setActiveLanguageFromMetaTag();

    if (t('rtl')) {
        $(document.documentElement).addClass('rtl');
    } else {
        $(document.documentElement).addClass('ltr');
    }

    $('<div id="app-preloader"><div class="preload-spinner"><div></div><div></div><div></div><div></div><div></div></div></div>').appendTo(document.body);

    app.router = new Router();

    $.get(this.apiUrl('auth/login'))
        .always(() => {
            app.setupMainNav();
            $('#app-preloader').fadeOut();
        })
        .done(data => {
            app.startKeepaliveTimer();

            app.setUserId(data['user_id'])
                .setUsername(data['username'])
                .setFirstName(data['first_name'])
                .setLastName(data['last_name'])
                .setGender(data['gender'])
                .setExternalId(data['external_id'])
                .setLoginMode(data['login_mode'])
                .setProjectMode(data['project_mode'])
                .setUserRoles(data['roles'])
                .setUserPermissions(data['permissions'])
                .setPhoneExtension(data['phone_extension'])
                .extendCachedGeneralSettings(data['general_settings']);

            app.doAfterLogin();

            if (data['in_break']) {
                Backbone.history.start({root: '/client/', pushState: true, silent: true});
                if (/^break(\/|$)/.test(Backbone.history.getFragment())) {
                    Backbone.history.loadUrl(Backbone.history.getFragment());
                } else {
                    Backbone.history.navigate('/break', {trigger: true});
                }
            } else {
                Backbone.history.start({root: '/client/', pushState: true});
            }

            app.getMainNav().render();
        })
        .fail(() => {

            Backbone.history.start({root: '/client/', pushState: true, silent: true});
            Backbone.history.fragment = false; // Allow navigating to any URL after navigating in 'silent' mode

            if (/^login(\/|\?|$)/.test(Backbone.history.getFragment())) {
                Backbone.history.navigate(Backbone.history.getFragment(), {trigger: true});
            } else {
                let returnUrl = Backbone.history.getFragment();
                if (returnUrl === 'login') {
                    returnUrl = '';
                }
                Backbone.history.navigate(returnUrl ? '/login?return_url=' + returnUrl : '/login', true);
            }

        });
};

$(function () {

    // Start!
    app.bootstrap();

    // Start synchronizing with our NTP
    NTP.synchronize();

    setInterval(function () {
        NTP.synchronize();
    }, 60000);

});
