let url = '/api/ntp', // The endpoint url for the NTP service. Supposed to return just a JSONified timestamp, in milliseconds, equivalent of JSON.stringify(Date.now())
    timeout = 10000, // After how long of synchronizing should we time out
    minSamples = 3, // How many samples are considered enough to take the best out of
    maxSamples = 10; // The upper limit of how many samples we can keep around

let synchronizing = false, // Are we currently synchronizing
    samples = [], // The samples bag
    bestSample = null; // The chosen best sample

class NTP {

    static synchronize () {

        if (!synchronizing) {

            let xhr = null,
                requestTime, // When we sent the request
                responseTime,
                timeoutHandle = null; // When the server has sent the response

            let sendRequest, processSample;

            sendRequest = function () {
                xhr = new XMLHttpRequest();

                const _url = url; // Record this url for the log

                xhr.open("GET", _url);

                xhr.onreadystatechange = function () {
                    if (this.readyState === this.HEADERS_RECEIVED && this.status === 200) {
                        // This means that the request has been processed,
                        // and this is the earliest time that we can record of the offset between server's time to local time
                        responseTime = Date.now();
                    }
                };

                xhr.onload = function () {
                    xhr = null;
                    if (this.status === 200) {
                        try {
                            processSample(JSON.parse(this.response));
                        } catch (exception) {
                            if (console) {
                                console.log('NTP failure: Request to ' + _url + ' has failed!');
                            }
                        }
                    } else {
                        if (console) {
                            console.log('NTP failure: Request to ' + _url + ' has failed with status ' + this.status);
                        }
                    }
                };

                requestTime = Date.now();
                xhr.send();
            };

            processSample = function (serverNow) {
                const latency = responseTime - requestTime,
                    sample = {
                        offset: serverNow - requestTime - latency / 2,
                        latency: latency,
                    };

                // We are always keeping a buffer window of X samples i.e [50,20,30,40,10,60,120,100,newSample]
                samples.push(sample);
                if (samples.length > maxSamples) {
                    samples.shift();
                }

                // And taking the sample with the lowest latency of them all
                bestSample = samples[0];
                let i = 1;
                const count = samples.length;
                for (; i < count; i++) {
                    if (samples[i].latency < bestSample.latency) {
                        bestSample = samples[i];
                    }
                }

                // And if we do not have the minimum required samples, we are not done yet
                if (samples.length < minSamples) {
                    // So we request another sample
                    sendRequest();
                }
                else {
                    // When we have enough samples, we consider it good enough and we are done synchronizing
                    synchronizing = false;

                    if (timeoutHandle) {
                        clearTimeout(timeoutHandle);
                    }
                }
            };

            synchronizing = true;

            timeoutHandle = setTimeout(() => {
                if (synchronizing) {
                    synchronizing = false;
                    if (xhr) {
                        xhr.abort();
                        xhr = null;
                    }
                }
            }, timeout);

            sendRequest();
        }

        return NTP;

    }

    static now () {
        return Date.now() + (bestSample ? bestSample.offset : null);
    }

    static date () {
        const date = new Date();
        if (bestSample) {
            date.setMilliseconds(date.getMilliseconds() + bestSample.offset);
        }
        return date;
    }

    static get hasServerTime() {
        return !!bestSample;
    }

    static get serverTimeLatency() {
        return bestSample ? bestSample.latency : 0xffffffff;
    }

    static get synchronizing() {
        return synchronizing;
    }

    static get url() {
        return url;
    }

    static set url(value) {
        url = value;
    }
}

export default NTP;