import View from '../../controls/View.js';
import UserStats from '../UserStats.js';
import BreakArea from '../views/BreakArea.js';

var BreakPage = View.extend({

    className: 'main-body',

    initialize: function (options) {

        var that = this;

        that._breakView = new BreakArea();
        that.$el.append(that._breakView.$el);

        that.listenTo(UserStats, 'data', function (data) {

            that.setBreakData({
                'type': data['break_type'],
                'start': data['break_start'],
                'today_total': data['break_type'] === 'personal' ? data['personal_break_minutes'] * 60 : null,
                'today_length': data['break_type'] === 'personal' ? data['personal_break_duration'] : null
            });

        });

    },

    render: function () {

        var that = this;

        that._breakView.render();

        return that;
    },

    hasUnsavedChanges: function () {
        return (fragment, navigate) => {
            if (fragment.startsWith('break'))
                return navigate(false);

            if (this._breakView.breakData && this._breakView.breakData.type === 'outgoing')
                return navigate(true);

            this._breakView.stopBreak(err => {
                if (err) return;
                navigate(true);
            });
        };
    },


    setBreakData: function (breakData) {
        var that = this;

        that._breakView.setBreakData(breakData);

        return that;
    },

    remove: function () {
        var that = this;

        that._breakView.remove();

        BreakPage.__super__.remove.apply(this, arguments);
    }

});

export default BreakPage;
