<template>
    <div class="side-nav">
        <div class="side-nav-number-display">{{displayNumber}}</div>
        <div :class="{'warning': shouldWarn, 'side-nav-status-display': true}">{{statusText}}</div>
        <div class="side-nav-callstatus" v-if="loginMode !== 'override' && isInCall">
            <div class="side-nav-callstatus-label">{{t('side_nav.time_in_call')}}</div>
            <div class="side-nav-callstatus-value">{{callTime != null ? TimeFormatters.timeFromSeconds(callTime) : '...'}}</div>
            <div class="side-nav-callstatus-label">{{t('side_nav.time_on_hold')}}</div>
            <div class="side-nav-callstatus-value">{{holdTime != null ? TimeFormatters.timeFromSeconds(holdTime) : '...'}}</div>
        </div>
        <div class="side-nav-callstatus" v-if="loginMode !== 'override' && !isInCall">
            <div class="side-nav-callstatus-label">{{t('side_nav.time_no_call')}}</div>
            <div class="side-nav-callstatus-value">{{withoutCallTime != null ? TimeFormatters.timeFromSeconds(withoutCallTime) : '...'}}</div>
            <div class="side-nav-callstatus-label" style="visibility:hidden"></div>
            <div class="side-nav-callstatus-value" style="visibility:hidden"></div>
        </div>
        <div class="side-nav-callstatus" v-if="loginMode === 'override'">
            <div class="side-nav-callstatus-label" style="visibility:hidden"></div>
            <div class="side-nav-callstatus-value" style="visibility:hidden"></div>
            <div class="side-nav-callstatus-label" style="visibility:hidden"></div>
            <div class="side-nav-callstatus-value" style="visibility:hidden"></div>
        </div>
        <div class="side-nav-immediatecall"
             v-if="hasImmediateCallButton">
            <div class="side-nav-callstatus-label">{{t('side_nav.immediate_call_label')}}</div>
            <input class="side-nav-immediatecall-input" type="text"
                   v-model="immediateCallPhone"
                   ref="immediateCallPhone"
                   @click="$event.target.select()"
                   @keyup.enter="$refs.immediateCallButton.click()"/>
        </div>
        <button class="side-call-button"
                :disabled="!hasImmediateCallButton || !hasValidImmediateCallPhone || isInCall"
                ref="immediateCallButton"
                v-on:click="callImmediate"
                v-if="hasImmediateCallButton">
            {{t('side_nav.call_button')}}
        </button>
        <button class="side-call-button"
                :disabled="!hasCallButton || isInCall || !canCallNow || loginMode === 'override'"
                v-if="hasCallButton"
                v-on:click="call">
            {{t('side_nav.call_button')}}
        </button>
        <SideNavPredictive
                v-if="isProjectPredictive || isProjectIncoming"
                :session-closed="sessionClosed"
                :update-queue-status="updateQueueStatus"
                :project-mode="projectMode"
                :incoming-status="incomingStatus"
                v-on:enableOutgoing="nextContactEnabled = $event"
        />
        <button class="side-hangup-button"
                :disabled="!isInCall"
                ref="hangupButton"
                v-on:click="hangup">
            {{t('side_nav.hangup_button')}}
        </button>
        <button class="side-next-button"
                :disabled="!nextContactEnabled"
                v-if="hasNextContactButton"
                v-on:click="fetchNextContact">
            {{t('side_nav.next_contact_button')}}
        </button>
        <div class="side-phone-toolbar">
            <button class="hold-button" disabled></button>
            <button class="mute-button" disabled></button>
            <button class="keypad-button" disabled></button>
        </div>
        <div class="side-clock">{{clockTime.getHours().toString().padStart(2, '0') + ':' + clockTime.getMinutes().toString().padStart(2, '0')}}</div>
    </div>
</template>

<script>
    import app from '../App';
    import Calls from '../Calls.js';
    import PhoneNumberUtil from '../../utils/PhoneNumberUtil.mjs';
    import TimeFormatters from '../../appHelpers/TimeFormatters.js';
    import NTP from '../../utils/NTP.js';
    import OutgoingCallsMainPage from '../pages/OutgoingCallsMainPage.js';
    import Backbone from 'backbone';
    import {t} from '../i18n/i18n';
    import SideNavPredictive from './SideNavPredictive.vue';

    // Call settings: 'red_time_call', 'red_hold_call', 'red_without_call', 'red_mute_call'
    // Note: Currently ignoring red_mute_call as we are not tracking mute (until we'll embed a SIP client)

    export default {
        name: "SideNav",
        components: {SideNavPredictive},
        props: [],
        data() {
            return {
                TimeFormatters: TimeFormatters,

                // In the future, Calls object should inherit from a Vue, so its data could be watched directly.
                isInCall: Calls.isInCall(),
                canCallNow: Calls.getCanCallNow(),
                callData: {...Calls.getCallData()},
                loginMode: app.getLoginMode(),
                projectMode: app.getProjectMode(),
                clockTime: NTP.date(),
                immediateCallPhone: '',
                nextContactEnabled: false,
                incomingStatus: 'idle',
                incomingCaller: '',

                sessionClosed: true,
                updateQueueStatus: 'none',

                // We should have an upper level bus when the project is more Vue oriented.
                eventBus: {...Backbone.Events},
            };
        },
        methods: {
            callImmediate() {
                let contactData = {
                    phone: PhoneNumberUtil.normalizePhoneNumber(this.immediateCallPhone.trim())
                };

                this.immediateCallPhone = PhoneNumberUtil.displayPhoneNumber(contactData.phone);

                const immediateFunctions = {
                    getCampaignId: () => this.ajaxGet(app.apiUrl('me/assigned_project')),
                    contactSearch: () => this.ajaxGet(app.apiUrl('/contacts/search', contactData)),
                    createNewContact: () => this.ajaxJsonPost(app.apiUrl('contacts'), contactData),
                };

                immediateFunctions.getCampaignId()
                    .then(project => {
                        contactData.campaign_id = project['new_contact_default_campaign_id'] || project['campaigns'][0]['id'];
                        return immediateFunctions.contactSearch();
                    })
                    .then(searchResult => {
                        if (searchResult.length) {
                            contactData.id = searchResult[0]['id'];
                            return 'done';
                        }
                        contactData.isNew = true;
                        return immediateFunctions.createNewContact();
                    })
                    .then(contact => {
                        if (contact['id']) {
                            contactData.id = contact['id'];
                        }
                        this.gotoOutgoingAndCall(contactData.phone, contactData.id, contactData.campaign_id)
                    })
                    .fail((xhr, error) => {
                        app.showXhrError(xhr, error);
                    });

                this.$refs.hangupButton && this.$refs.hangupButton.focus()
            },

            call() {
                this.eventBus.trigger('call');

                this.$refs.hangupButton && this.$refs.hangupButton.focus()
            },

            hangup() {
                this.eventBus.trigger('hangup');
            },

            fetchNextContact() {
                this.eventBus.trigger('nextcontact');
            },

            gotoOutgoingAndCall(number, contactId, campaignId) {
                let view = new OutgoingCallsMainPage();
                app.setMainView(view);
                //Backbone.history.navigate('/outgoing', { trigger: false }); // Change current url without triggering navigation
                app.getMainNav().render();
                view.loadContactAndCall(contactId, campaignId, number);
                return this;
            },

            resyncData() {
                this.loginMode = app.getLoginMode();
                this.projectMode = app.getProjectMode();
            },
        },
        computed: {
            displayNumber() {

                if (this.incomingStatus === 'answer' || this.incomingStatus === 'ringing') {
                    return PhoneNumberUtil.displayPhoneNumber(this.incomingCaller);
                }

                if (this.loginMode === 'override' || !this.isInCall)
                    return '';

                return this.callData['destination']
                    ? PhoneNumberUtil.displayPhoneNumber(this.callData['destination'])
                    : '...';
            },

            statusText() {
                if (this.loginMode === 'override')
                    return t('side_nav.status_override');

                if (this.isInCall)
                    return t('side_nav.status_incall');

                if (this.isProjectIncoming && this.incomingStatus === 'ringing') {
                    return t('side_nav.status_incoming_ringing');
                }

                if (this.isProjectIncoming || this.isProjectPredictive) {
                    return t('side_nav.status_waiting_for_call');
                }

                return t('side_nav.status_nocall');
            },

            isProjectExternal() {
                return this.projectMode === 'external';
            },

            isProjectPredictive() {
                return this.projectMode === 'predictive';
            },

            isProjectIncoming() {
                return this.projectMode === 'incoming';
            },

            hasImmediateCallButton() {
                return this.isProjectExternal;
            },

            hasCallButton() {
                return !this.isProjectExternal && !this.isProjectIncoming && !this.isProjectPredictive;
            },

            hasNextContactButton() {
                return !this.isProjectExternal && !this.isProjectPredictive;
            },

            hasValidImmediateCallPhone() {
                const normalizedPhone = PhoneNumberUtil.normalizePhoneNumber(this.immediateCallPhone.trim());
                let requiredLength = PhoneNumberUtil.isMobileNumber(normalizedPhone) ? 13 : 12;
                return normalizedPhone.length >= requiredLength;
            },

            callTime() {
                return this.callData['dial_begin_time']
                    ? ((this.clockTime - +new Date(this.callData['dial_begin_time'])) / 1000)
                    : null
            },

            holdTime() {
                return this.callData['on_hold_time']
                    ? ((this.clockTime - +new Date(this.callData['on_hold_time'])) / 1000)
                    : null
            },

            withoutCallTime() {
                let lastTime = this.callData
                    ? (this.callData['last_call_end_time'] || this.callData['login_time'])
                    : null;
                return lastTime != null ? ((this.clockTime - new Date(lastTime)) / 1000) : null;
            },

            shouldWarn() {
                if (this.holdTime != null &&
                    app.getCachedGeneralSetting('red_hold_call') &&
                    this.holdTime >= parseInt(app.getCachedGeneralSetting('red_hold_call'), 10)) {
                    return true;
                }
                else if (this.callTime != null &&
                    app.getCachedGeneralSetting('red_time_call') &&
                    this.callTime >= parseInt(app.getCachedGeneralSetting('red_time_call'), 10)) {
                    return true;
                }
                else if (
                    this.loginMode !== 'override' &&
                    !this.isInCall &&
                    !this.isProjectPredictive &&
                    !this.isProjectIncoming) {

                    if (this.withoutCallTime != null &&
                        app.getCachedGeneralSetting('red_without_call') &&
                        this.withoutCallTime >= parseInt(app.getCachedGeneralSetting('red_without_call'), 10)) {
                        return true;
                    }
                }

                return false;
            },
        },
        mounted() {
            this.setInterval(() => {
                this.clockTime = NTP.date();
                this.isInCall = Calls.isInCall();
                this.canCallNow = Calls.getCanCallNow();
                this.callData = {...Calls.getCallData()};
                this.loginMode = app.getLoginMode();
                this.projectMode = app.getProjectMode();
            }, 500);

            Backbone.history.on('route', this._onRouteListener = () => {
                if (Backbone.history.getFragment() !== 'outgoing') {
                    Calls.setCanCallNow(false);
                    this.canCallNow = false;
                    this.nextContactEnabled = false;
                }
            });

            this._onInCallListener = () => {
                this.isInCall = Calls.isInCall();
                this.canCallNow = Calls.getCanCallNow();
            };

            this._onIncomingUpdate = (evt) => {
                this.incomingStatus = evt.status;
                this.incomingCaller = evt.caller;

            }

            Calls.on('incall', this._onInCallListener);
            Calls.on('callfinished', this._onInCallListener);
            Calls.on('cancallnowupdate', this._onInCallListener);
            Calls.on('makecallsuccess', this._onInCallListener);

            Calls.on('incoming_call', this._onIncomingUpdate);

            if (this.isProjectExternal) {
                this.$refs.immediateCallPhone && this.$refs.immediateCallPhone.focus();
            }
        },
        destroyed() {
            Backbone.history.off('route', this._onRouteListener);
            Calls.off('incall', this._onInCallListener);
            Calls.off('callfinished', this._onInCallListener);
            Calls.off('cancallnowupdate', this._onInCallListener);
            Calls.off('makecallsuccess', this._onInCallListener);
        }
    }
</script>
