import Comm from '../appHelpers/Comm.js';
import {wsUrl} from '../shared_utils/baseUrls';

const comm = new Comm({
    url: wsUrl('comm'),
    timeout: 10000,
    autoConnect: true,
    autoConnectTimeout: 30000,
    autoReconnect: true,
    retryMin: 1000,
    retryMax: 20000,
    onMessage: function (id, message, expectsResult) {

        if (message['type']) {
            this.emit(message['type'], message);
        } else {
            console.log('message without type:', message);
        }

    }
});

export default comm;