import $ from 'jquery';
import MessagePopupView from '../controls/MessagePopupView.js';
import LoadingPopupView from '../controls/LoadingPopupView.js';
import Window from '../controls/Window.js';
import CheckBox from '../controls/CheckBox.js';

class AlertUI {
    constructor(i18n) {

        this._p = {};

        /**
         * @private
         */
        this._t = i18n.t;
    }

    /**
     * Show an confirm alert with yes and no
     * @param {String} message
     * @param {String} title
     * @param {String?} extraConfirm
     * @param {Function} onYes
     * @param {Function} onNo
     * @param {Function} onCancel
     */
    confirm(message, title, extraConfirm, onYes, onNo, onCancel) {

        if (typeof extraConfirm !== 'string') {
            onCancel = onNo;
            onNo = onYes;
            onYes = /** @type function */ extraConfirm;
            extraConfirm = '';
        }

        this.alertWithButtons({
            title: title,
            message: message,
            buttons: [
                {className: 'positive-button', title: this._t('yes'), click: onYes, enter: true, extraConfirm: extraConfirm},
                {className: 'negative-button', title: this._t('no'), click: onNo},
            ],
            close: onCancel || onNo,
        });
    }

    /**
     * Show an confirm alert where the 'yes' is a negative action (like deleting etc.)
     * @param {String} message
     * @param {String} title
     * @param {String=} extraConfirm
     * @param {Function=} onYes
     * @param {Function=} onNo
     * @param {Function=} onCancel
     */
    confirmNegative(message, title, extraConfirm, onYes, onNo, onCancel) {

        if (typeof extraConfirm !== 'string') {
            onCancel = onNo;
            onNo = onYes;
            onYes = /** @type function */ extraConfirm;
            extraConfirm = '';
        }

        this.alertWithButtons({
            title: title,
            message: message,
            buttons: [
                {className: 'negative-button', title: this._t('yes'), click: onYes, enter: true, extraConfirm: extraConfirm},
                {className: 'positive-button', title: this._t('no'), click: onNo},
            ],
            close: onCancel || onNo,
        });
    }

    /**
     * Show an alert with custom buttons
     * @public
     * @param {Object} options
     * @param {String?} options.title
     * @param {String} options.message
     * @param {{ className: String, title: String, click: Function, enter: Boolean, extraConfirm: String|undefined }[]} options.buttons
     * @param {Function|undefined} options.close
     * @returns {Window}
     */
    alertWithButtons(options) {

        const wnd = new Window({
            title: options.title || this._t('default_alert_title'),
        });

        let enterButton = null;
        const onKeyPress = evt => {
            if (evt.which === 13) {
                if (enterButton) {
                    enterButton.click();
                }
            }
        };

        $('<div class="prompt">')
            .html(options.message.toString().toHtml())
            .appendTo(wnd.inner());

        const $actions = $('<div class="actions">');

        let buttonClicked = false;

        for (const button of options.buttons) {

            const $button = $('<button>')
                .addClass(button.className)
                .text(button.title)
                .on('click', function () {
                    if (this.disabled) return;

                    buttonClicked = true;
                    if (button.click) {
                        button.click();
                    }
                    wnd.hide();

                })
                .appendTo($actions);

            if (button.enter) {
                enterButton = $button;
                $(window).on('keypress', onKeyPress);
            }

            if (button.extraConfirm) {
                const chkExtraConfirm = new CheckBox({
                    label: button.extraConfirm,
                });

                chkExtraConfirm.on('change', (chk, checked) => {
                    $button.prop('disabled', !checked);
                });

                $('<div class="prompt extra-confirmation">')
                    .append(chkExtraConfirm.$el)
                    .appendTo(wnd.inner());

                // Start disabled
                $button.prop('disabled', true);
            }

        }

        $actions.appendTo(wnd.inner());

        wnd
            .on('hide', () => {
                if (!buttonClicked && options.close) {
                    options.close();
                }
                $(window).off('keypress', onKeyPress);
            })
            .on('hidecomplete', () => {
                $(window).off('keypress', onKeyPress);
            })
            .show();

        return wnd;
    }

    input(message, title, textProperties, onOk, onCancel) {
        let isOk = false;

        const wnd = new Window({
            title: title || this._t('default_alert_title'),
        });

        const inputId = 'input-window-input-' + (Math.random() * 999999);
        let $input;

        wnd.inner().append(
            $('<div class="prompt">').append(
                $('<div class="label-prompt">').append(
                    $('<label>').attr('for', inputId).html(message.toHtml()),
                ),
                $input = $('<input type="text">').attr('id', inputId),
            ),
            $('<div class="actions">').append(
                $('<button class="positive-button">').text(this._t('ok')).on('click', () => {
                    isOk = true;
                    wnd.hide();
                }),
                $('<button class="neutral-button">').text(this._t('cancel')).on('click', () => {
                    wnd.hide();
                }),
            ),
        );

        wnd
            .on('show', function () {
                $input.focus();
                $input.on('keypress', event => {
                    if (event.which === 13) {
                        isOk = true;
                        wnd.hide();
                    }
                });
            })
            .on('hide', function () {
                if (isOk && onOk) onOk($input.val());
                else if (!isOk && onCancel) onCancel();
            })
            .show();
    }

    warning(message, region, queue) {
        new MessagePopupView({
            addClass: 'warning',
            message: message,
            region: region || 'top',
            queue: queue == null ? true : !!queue,
        }).show();
    }

    /**
     * Show a confirmation with multiple buttons and explanations
     * @public
     * @param {Object} options
     * @param {String?} options.title
     * @param {String} options.message
     * @param {{ className: String, title: String, message: String, warning: String, click: Function, enter: Boolean }[]} options.buttons
     * @param {String|undefined} options.cancelTitle
     * @param {Function|undefined} options.cancel
     * @returns {Window}
     */
    confirmWithExtendedButtons(options) {

        const wnd = new Window({
            title: options.title || this._t('default_alert_title'),
        });

        $('<div class="prompt">')
            .html(options.message || '')
            .appendTo(wnd.inner());

        let enterButton = null;
        let buttonClicked = false;

        const $buttons = $('<table class="buttons-ex">').appendTo(wnd.inner());

        for (const buttonOpts of options.buttons) {

            let explanationCell, button;

            const $row = $('<tr>').append(
                $('<td class="button-cell">').append(
                    button = $('<button>')
                        .html(buttonOpts['title'])
                        .prop('className', buttonOpts['className'] || ''),
                ),
                explanationCell = $('<td class="explanation-cell">').append(
                    $('<div>').html(buttonOpts['message']),
                ),
            );

            if (buttonOpts['warning']) {
                $('<div class="warning">')
                    .html(buttonOpts['warning'])
                    .appendTo(explanationCell);
            }

            $row.appendTo($buttons);

            if (buttonOpts.enter) {
                enterButton = button;
            }

            if (buttonOpts.click) {
                button.on('click', function () {
                    if (this.disabled) return;

                    buttonClicked = true;
                    const res = buttonOpts.click();
                    if (res === false) {
                        buttonClicked = false;
                    } else {
                        wnd.hide();
                    }
                });
            }

        }

        if (options.cancelTitle) {
            $('<div class="cancel-button-wrapper">')
                .append(
                    $('<button>').on('click', () => wnd.hide()),
                );
        }

        const onKeyPress = function (evt) {
            if (evt.which === 13) {
                if (enterButton) {
                    enterButton.click();
                }
            }
        };

        if (enterButton) {
            $(window).on('keypress', onKeyPress);
        }

        wnd
            .on('hide', () => {
                $(window).off('keypress', onKeyPress);

                if (!buttonClicked && options.cancel) {
                    options.cancel();
                }
            })
            .on('hidecomplete', () => {
                $(window).off('keypress', onKeyPress);
            })
            .show();

        return wnd;
    }

    showLoader(title) {
        const p = this._p;

        let $parent = null;

        if (title && (title instanceof $ || title['nodeType'] !== undefined)) {
            $parent = $(title);
            title = arguments[1];
        }

        let count = p.loading || 0;
        if ($parent) {
            count = $parent.data('loading-counter') || 0;
        }

        let loader = $parent ? $parent.data('loader') : p.loader;

        if (++count === 1) {
            if (!loader) {
                loader = new LoadingPopupView();
            }
            loader.title(title || this._t('activity_indicator.loading')).showDelayed($parent, 100);

            if ($parent) {
                $parent.data('loader', loader);
            } else {
                p.loader = loader;
            }
        }

        if ($parent) {
            $parent.data('loading-counter', count);
        } else {
            p.loading = count;
        }

        return this;
    }

    hideLoader(force) {
        const p = this._p;

        let $parent = null;

        if (force && (force instanceof $ || force['nodeType'] !== undefined)) {
            $parent = $(force);
            force = arguments[1];
        }

        let count = p.loading || 0;
        if ($parent) {
            count = $parent.data('loading-counter') || 0;
        }

        if (--count <= 0 || force) {
            count = 0;

            let loader = $parent ? $parent.data('loader') : p.loader;

            if (loader) {
                loader.hide();
            }
        }

        if ($parent) {
            $parent.data('loading-counter', count);
        } else {
            p.loading = count;
        }

        return this;
    }

    setLoaderTitle(title, isHtml) {
        const p = this._p;

        let $parent = null;

        if (title && (title instanceof $ || title['nodeType'] !== undefined)) {
            $parent = $(title);
            title = isHtml;
            isHtml = arguments[2];
        }

        let loader = $parent ? $parent.data('loader') : p.loader;

        if (loader) {
            loader.title(title || '', isHtml).show($parent);
        }

        return this;
    }
}

export default AlertUI;