import PopupBase from './PopupBase.js';
import ReturnCallsArea from '../views/ReturnCallsArea.js';

/* Triggers:
 vieworder (orderId)
 continueorder (orderId)
 call (contactId, number)
 */

var ReturnCallsPopup = PopupBase.extend({

    className: 'popup-frame large-size return-to-contacts',
    hasAutoReposition: false,
    hasContentArea: false,

    initialize: function (options) {

        options = options || {};

        ReturnCallsPopup.__super__.initialize.call(this, options);

        var that = this;

        that._returnCallsArea = new ReturnCallsArea({ className: 'scrolling-pane' });

        that.listenTo(that._returnCallsArea, 'vieworder', function (orderId) {
            that
                .trigger('vieworder', orderId)
                .hide();
        }).listenTo(that._returnCallsArea, 'continueorder', function (orderId) {
            that
                .trigger('continueorder', orderId)
                .hide();
        }).listenTo(that._returnCallsArea, 'call', function (number, contactId) {
            that
                .trigger('call', number, contactId)
                .hide();
        }).listenTo(that._returnCallsArea, 'continuesession', function (contactId, campaignId) {
            that
                .trigger('continuesession', contactId, campaignId)
                .hide();
        });

        that.$el.append(that._returnCallsArea.$el);

    },

    render: function () {
        var that = this;

        if (!that._returnCallsArea.isLoaded()) {
            that._returnCallsArea.reload();
        }

        return that;
    },

    remove: function () {
        var that = this;

        if (that._returnCallsArea) {
            that._returnCallsArea.remove();
        }

        ReturnCallsPopup.__super__.remove.apply(this, arguments);
    }

});

export default ReturnCallsPopup;
