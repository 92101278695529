import $ from 'jquery';
import Backbone from 'backbone';
import _ from 'underscore';
import ResizeManager from '../utils/ResizeManager.js';

const Region = {
    /** @expose */CENTER: 'center',
    /** @expose */TOP: 'top'
};

const defaultOptions = {
    addClass: null,
    title: null,
    message: null,
    messageIsHtml: false,
    mask: true,
    maskOpacity: 0.3,
    dismissFromClick: true,
    dismissFromMask: true,
    dismissFromMessage: true,
    dismissFromEscape: true,
    dismissFromAnyKey: true,
    hasCloseButton: true,
    region: Region.CENTER,
    dismissAfter: false,
    queue: false,
    takeFocus: true
};

const MessagePopupView = Backbone.View.extend({

    className: "message-popup",
    template: _.template('<div class="title" aria-live="polite"></div><div class="message" aria-live="polite"></div><button class="close"></button>'),

    initialize: function (options) {
        options = this.options = Object.assign({}, defaultOptions, options);

        if (options.addClass) {
            this.$el.addClass(options.addClass);
        }

        if (options.dismissFromClick) {
            this.$el.on('click', _.bind(this.dismiss, this));
        }

        this.onKeyDown = _.bind(function (event) {
            if (this.options.dismissFromAnyKey || (this.options.dismissFromEscape && event.which === 27 /* ESCAPE */)) {
                this.dismiss();
                event.preventDefault();
            }
        }, this);

        this.render();
    },

    render: function (options) {

        this.$el.html(this.template());

        this._$title = this.$el.find('.title');
        this.title(this.options.title);
        this.$message = this.$el.find('.message');
        this.message(this.options.message, this.options.messageIsHtml);

        if (!this.options.hasCloseButton) {
            this.$el.find('.close').hide();
        }

        const $closeButton = this.$el.find('.close');
        $closeButton.on('click', _.bind(this.dismiss, this));

        return this;
    },

    title: function () {
        if (arguments.length) {
            this._$title.text((this.options.title = arguments[0]) == null ? '' : this.options.title);
            if (!(this.options.title + '')) this._$title.hide();
            else this._$title.show();
            return this;
        } else {
            return this.options.title;
        }
    },

    message: function () {
        if (arguments.length) {
            this.options.message = arguments[0] || '';
            this.options.messageIsHtml = !!arguments[1];
            if (this.options.messageIsHtml) {
                this.$message.html(this.options.message);
            } else {
                const message = this.options.message + '';
                if (message.indexOf('\n')) {
                    this.$message.html(message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/'/g, "&#39;").replace(/"/g, "&quot;").replace(/\n/g, "<br />"));
                } else {
                    this.$message.text(message);
                }
            }
            if (!(this.options.message + '')) this.$message.hide();
            else this.$message.show();
            return this;
        } else {
            return this.options.message;
        }
    },

    remove: function () {
        if (this.$mask) {
            this.$mask.stop().remove();
            this.$mask = null;
        }
        this._$title = null;
        this.$message = null;
        this.$parent = null;

        if (this.options.takeFocus) {
            if (this._activeElement) {
                this._activeElement.focus()
                this._activeElement = null;
            }
        }

        $(window).off('keydown', this.onKeyDown);

        MessagePopupView.__super__.remove.apply(this, arguments);
    },

    show: function (parent, immediate) {
        this.$parent = $(parent);
        this.$parent.push(document.body)
        this.$parent = parent = this.$parent.first();

        if (!immediate && this.options.queue) {
            MessagePopupView.queue(this);
            return this;
        }

        if (this.options.takeFocus) {
            this._activeElement = document.activeElement;
            if (this._activeElement) {
                this._activeElement.blur();
            }
        }

        this.$el.appendTo(parent).css({position: parent[0] === document.body ? 'fixed' : 'absolute'});
        const zIndex = parseInt(this.$el.css('z-index'), 10) || 10001;
        this.$el.css('z-index', zIndex);

        if (parent[0] === document.body && this.options.mask) {
            this.$mask || (this.$mask = $('<div class="screen-mask">').css({
                background: '#000',
                opacity: this.options.maskOpacity,
                position: 'fixed',
                left: 0, top: 0, right: 0, bottom: 0,
                'z-index': zIndex - 1
            })).appendTo(parent).stop().css('opacity', 0).animate({'opacity': this.options.maskOpacity}, {
                duration: 150,
                swing: 'easeOutQuint'
            });
            if (this.options.dismissFromMask) {
                this.$mask.on('click', _.bind(this.dismiss, this));
            }
        }

        this.listenTo(ResizeManager, 'resize', this.reposition);
        this.reposition();

        if (this.options.region === Region.TOP) {
            this.$el.css('top', -this.$el.outerHeight()).animate({top: 20}, {duration: 300, swing: 'easeOutQuint'});
        }

        if (this.options.dismissFromEscape || this.options.dismissFromAnyKey) {
            $(window).on('keydown', this.onKeyDown);
        }

        if (typeof this.options.dismissAfter === 'number') {
            setTimeout(_.bind(this.dismiss, this), this.options.dismissAfter);
        }

        return this;
    },

    hide: function () {
        if (this.$mask) {
            this.$mask.stop().animate({'opacity': 0}, {
                duration: 150,
                swing: 'easeOutQuint',
                complete: _.bind(function () {
                    this.$mask.remove();
                    this.$mask = null;
                }, this)
            })
        }
        this.stopListening(ResizeManager, 'resize');
        $(window).off('keydown', this.onKeyDown);
        this.$el.detach();

        if (this.options.takeFocus) {
            if (this._activeElement) {
                this._activeElement.focus()
                this._activeElement = null;
            }
        }

        MessagePopupView.dequeue(this);

        return this;
    },

    reposition: function () {

        let parent = this.el.parentNode,
            elementPresent = false;
        while (parent) {
            if (parent === document.documentElement) {
                elementPresent = true;
                break;
            }
            parent = parent.parentNode;
        }
        if (!elementPresent) {
            return this.remove();
        }

        parent = this.$el.parent();
        let w = parent.innerWidth(),
            h = (parent[0] && parent[0] === document.body) ? document.documentElement.clientHeight : parent.innerHeight();
        const x = (w - this.$el.outerWidth()) / 2;
        let y = (h - this.$el.outerHeight()) / 2;
        if (y < 0) y = 0;
        const css = {};
        if (this.options.region === Region.CENTER || this.options.region === Region.TOP) {
            css.left = x;
        }
        if (this.options.region === Region.CENTER) {
            css.top = y;
        }
        this.$el.css(css);

        return this;
    },

    dismiss: function () {
        if (this.options.region === Region.CENTER) {
            this.$el.stop().animate({opacity: 0}, {
                duration: 300,
                swing: 'easeOutQuint',
                complete: _.bind(this._dismissEnd, this)
            });
        } else if (this.options.region === Region.TOP) {
            this.$el.stop().animate({top: -this.$el.outerHeight()}, {
                duration: 300,
                swing: 'easeOutQuint',
                complete: _.bind(this._dismissEnd, this)
            });
        }
        if (this.$mask) {
            this.$mask.stop().animate({opacity: 0}, {
                duration: 150,
                swing: 'easeOutQuint',
                complete: _.bind(this._dismissMaskEnd, this)
            });
        }
        return this;
    },

    _dismissEnd: function () {
        if (this._dismissCalled) return this;
        this._dismissCalled = true;
        if (this.$mask) return this;

        if (this.options.takeFocus) {
            if (this._activeElement) {
                this._activeElement.focus()
                this._activeElement = null;
            }
        }

        MessagePopupView.dequeue(this);
        this.remove();
        return this;
    },

    _dismissMaskEnd: function () {
        if (this.$mask) {
            this.$mask.remove();
            this.$mask = null;
        }
        if (this._dismissCalled) {
            this._dismissCalled = false;
            this._dismissEnd();
        }
        return this;
    }
});

/** @expose */
MessagePopupView.Region = Region;

/** @expose */
MessagePopupView.queue = function (view) {
    const parent = view.$parent;

    let queue = parent.data('message-popup-queue');
    if (!queue) {
        parent.data('message-popup-queue', queue = []);
    }
    queue.push(view);
    if (queue.length === 1) {
        view.show(parent, true);
    }

    return this;
};

/** @expose */
MessagePopupView.dequeue = function (view) {
    const parent = view.$parent;

    let queue = parent.data('message-popup-queue');
    if (!queue) return this;

    if (queue[0] === view) {
        queue.splice(0, 1);

        view = queue[0];
        if (view) {
            view.show(parent, true);
        }
    }

    return this;
};

export default MessagePopupView;
