<template>
    <table class="columned-table">
        <tr>
            <td><table class="simple-detailed-table">
                <tr>
                    <th>{{t('outgoing.contact.idt')}}</th>
                    <td>{{contact.idt}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.first_name')}}</th>
                    <td>{{contact.first_name}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.last_name')}}</th>
                    <td>{{contact.last_name}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.company')}}</th>
                    <td>{{contact.company}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.website_url')}}</th>
                    <td><a target="_blank" rel="noopener noreferrer"
                           v-show="contact.website_url"
                            :href='websiteLink'>
                        {{t('outgoing.contact.link_text')}}
                    </a></td>
                </tr>
            </table></td>
            <td><table class="simple-detailed-table">
                <tr>
                    <th>{{t('outgoing.contact.phones')}}</th>
                    <td>
                        <div
                                v-for="phone in contactPhones"
                                class="phone-number"
                                :class="currentPhoneNumber === phone ? 'selected' : ''"
                                @click="phoneClicked(phone)"
                        >
                            <span class="icon"></span>
                            <span class="ltr">{{displayPhoneNumber(phone)}}</span>
                        </div>
                    </td>
                </tr>
            </table></td>
            <td><table class="simple-detailed-table">
                <tr>
                    <th>{{t('outgoing.contact.city')}}</th>
                    <td>{{contact.address_city}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.fax')}}</th>
                    <td>{{contact.fax}}</td>
                </tr>
                <tr>
                    <th>{{t('outgoing.contact.gender')}}</th>
                    <td>{{t('genders')[contact.gender]}}</td>
                </tr>
            </table></td>
        </tr>
        <tr>
            <td><table class="simple-detailed-table">
                <tr v-for="field in customFieldsToDisplay">
                    <th>{{field.name}}:</th>
                    <td>{{field.value}}</td>
                </tr>
            </table></td>
        </tr>
    </table>
</template>

<script>

    import i18n  from '../i18n/i18n.js';
    import PhoneNumberUtil from '../../utils/PhoneNumberUtil.mjs';
    import CustomFields from "../../shared_utils/CustomFields.js";

    export default {
        name: 'BasicContactDetailsArea',
        props: ['contact', 'onPhoneClicked', 'currentPhoneNumber', 'customFieldsDef', 'customFieldsIdsToDisplay', 'openUrl'],
        data() {
            return {

            }
        },
        computed: {
            customFieldsToDisplay() {
                return this.customFieldsDef // Make sure we have it...
                    && this.customFieldsDef.filter(fieldDef => this.customFieldsIdsToDisplay.includes(String(fieldDef.id))) // Filter only fields to display
                    .map(fieldDef => { // Map to name and value
                        const value = this.contact['custom_fields'] ? this.contact['custom_fields'][`f${fieldDef['id']}`] : '';
                        return {
                            name: fieldDef.name,
                            value: CustomFields.customFieldValue(i18n, fieldDef, value) // TODO: Fix date format
                        };
                    });
            },
            contactPhones() {
                return ['phone', 'phone2', 'phone3', 'phone4']
                    .map(phoneField => this.contact[phoneField])
                    .filter(phoneNumber => phoneNumber)
            },
            websiteLink() {
                let url = this.contact['website_url'];

                if (url && this.openUrl) {

                    const winPopup = window.open(url);
                    if (winPopup) {
                        winPopup.focus();
                    } else {
                        alert('Please allow popups...');
                    }
                }

                return url;
            }
        },
        methods: {
            displayPhoneNumber(phone) {
                return PhoneNumberUtil.displayPhoneNumber(phone);
            },
            phoneClicked(phone) {
                this.onPhoneClicked && this.onPhoneClicked(phone);
            }
        }
    }
</script>
