import $ from 'jquery';
import _ from 'underscore';
import ResizeManager from '../utils/ResizeManager.js';
import DropDownMenu from './DropDownMenu.js';

/**
 * @typedef {DropDownMenu.Options} AutoComplete.Options
 *
 * @property {Boolean} [autoFocus=false] Should the first suggestion be automatically focus when opening the suggestions box
 * @property {Boolean|'empty'} [toggleOnClick=true] Should the search be toggled on/off on click on the input field?
 * @property {Boolean|'empty'} [autoSearchOnFocus=true] Should the search be triggered on focus on the input field?
 * @property {Number} [delay=300] The time (in milliseconds) to wait after typing a character until we send a search request.<br />This is meant to prevent a case where continuous typing causes many search requests.<br />
 * @property {Number} [minLength=0] How many characters must be typed until search suggestions start to appear?<br />Make this 0 if you wish to call the search() function without a value.<br />
 * @property {Array|Object|String|Function} [source=null] The source for the results.<br />Can be either an Array of items, an Object for key->values, a URL for querying results, or a function.<br />The results (except key->value objects) expects a "label" and a "value", and optionally "short_label"/"checked"/"group"/"nocheck"/"nointeraction"/"child_ids".<br />If passing the request URL, then the search term is passed in the query string as "term".<br />If passing a function, then the function receives two arguments: (request, response).<br />* "request" is an object, possibly containing a value for "term"<br />* "response" is a function that takes the results as an array.
 * @property {Boolean} [cacheSource=false] Should the search results be cached?<br />If true, then the "term" is not passed to the source request.<br />A call to "uncacheSource()" will clear the source cache.
 * @property {Boolean} [canRemove=false] When true, a SPAN element with "remove" class is added to each item,<br />And a click on it removes the item from the results and triggers a "removeitem" event.
 * @property {String} [position='start top'] The position of the view relative to the target.<br />The value consists on 'HORZ VERT' while HORZ can be start/center/end and VERT can be top/center/bottom.<br />RTL for start/end is detected automatically.<br />The "anchor" inside the view is attached to the "position" that was calculated on the "target".
 * @property {String} [anchor='start bottom'] The anchor of the view relative to the target.<br />The value consists on 'HORZ VERT' while HORZ can be start/center/end and VERT can be top/center/bottom.<br />RTL for start/end is detected automatically.<br />The "anchor" inside the view is attached to the "position" that was calculated on the "target".
 * @property {{x: number, y: number}} [offset={{x: 0, y: 0}}] An absolute offset to append to the position of the view
 * @property {Element|jQuery|String} [target=null] The target INPUT box to attach to, listen to keyboard events on, etc.
 * @property {Element|jQuery|String} [attachTo=null] The element to stick to. Defaults to `target` value.
 * @property {Boolean} [updateTargetValue=true] Should the target be updated with the focused value
 * @property {Boolean} [captureSpaceOnlyAfterUpDown=true] When true, the `spacebar` key will only be treated as `select` when followed by pressing the up/down keys.<br />This makes sure that text can be entered continuously including space characters
 * @property {Boolean} [autoSelectSingleResult=true] When true, and there's only one result showing and it matches the value of the target, select it automatically
 * @property {Function} [ajaxFunction=jQuery.Ajax] The ajax function to use to make requests. You can override this i.e. with one that adds custom headers to the request
 * @property {String} [labelAttr='label'] Attribute name for the "label", to fetch from the search results
 * @property {String} [shortLabelAttr='short_label'] Attribute name for the "short label", to fetch from the search results
 * @property {String} [valueAttr='value'] Attribute name for the "value", to fetch from the search results
 * @property {Boolean} [sortItems=true] Sort items before displaying
 * @property {Boolean} [putCheckedFirst=true] When sorting - put checked items first (applicable to `multi` mode only)
 * @property {Boolean} [splitCheckedGroups=true] Split groups to "checked" and "unchecked", works with `putCheckedFirst` only
 * @property {Boolean} [filterShortLabel=false] When filtering in code - use `short_label` for filter
 * */

/**
 *
 * @type {AutoComplete.Options}
 */
const defaultOptions = {
    autoFocus: false
    , toggleOnClick: true
    , autoSearchOnFocus: true
    , delay: 300
    , minLength: 0
    , source: null
    , cacheSource: false
    , canRemove: false
    , position: 'start top'
    , anchor: 'start bottom'
    , offset: {x: 0, y: 0}
    , target: null
    , attachTo: null
    , updateTargetValue: true
    , captureSpaceOnlyAfterUpDown: true
    , autoSelectSingleResult: true
    , ajaxFunction: $.ajax
    , labelAttr: 'label'
    , shortLabelAttr: 'short_label'
    , valueAttr: 'value'
    , sortItems: true
    , putCheckedFirst: true
    , splitCheckedGroups: true
    , filterShortLabel: false,
};

const KeyCodes = {
    /** @const */PAGE_UP: 33,
    /** @const */PAGE_DOWN: 34,
    /** @const */UP: 38,
    /** @const */DOWN: 40,
    /** @const */LEFT: 37,
    /** @const */RIGHT: 39,
    /** @const */ENTER: 13,
    /** @const */TAB: 9,
    /** @const */ESCAPE: 27,
    /** @const */SPACE: 32,
};

const hasComputedStyle = document.defaultView && document.defaultView.getComputedStyle;
const getComputedStyle = function (el) {
    return hasComputedStyle ? document.defaultView.getComputedStyle(el) : el.currentStyle;
};

const escapeRegex = function (value) {
    return value.replace(/[-\/()[\]?{}|*+\\:\.$^#|]/g, "\\$&");
};

/**
 * @class AutoComplete
 * @extends DropDownMenu
 * */
const AutoComplete = DropDownMenu.extend(
    /** @lends AutoComplete.prototype */
    {

        className: 'dropdown-menu autocomplete-dropdown-menu',

        _requestIndex: 0,
        _pending: 0,
        _multiSelectedValues: null,
        _multiSelectedItems: null,

        /**
         * @constructs
         * @param {AutoComplete.Options?} options
         * */
        initialize: function (options) {

            if (options.className) {
                this.el.className = this.constructor.prototype.className + ' ' + options.className;
            }

            options.capturesFocus = false;

            this._multiSelectedValues = [];
            this._multiSelectedItems = [];

            const that = this;
            this.constructor.__super__.initialize.call(this, Object.assign({}, defaultOptions, options));

            /** @type {AutoComplete.Options} */
            const o = that.o;

            this.$target = $(o.target);
            this.target = this.$target[0];

            o.$attachTo = o.attachTo ? $(o.attachTo) : this.$target;

            this._boundRepositionAndResize = function () {
                that.repositionAndResize();
            };
            this._listeningToScroll = [];

            let suppressKeyPress, suppressKeyPressRepeat, suppressInput;
            const nodeName = this.target.nodeName.toLowerCase(),
                isTextarea = nodeName === "textarea",
                isInput = nodeName === "input";

            this._isMultiLine = isTextarea ? true : isInput ? false : this.target.isContentEditable;
            this._valueMethod = isTextarea || isInput ? "val" : "text";
            this._isNewMenu = true;

            this._autocompleteAttr = this.target.autocomplete;
            this.target.autocomplete = 'off';
            this.$target.addClass('autocomplete-active');

            if (o.canRemove) {

                this.listenTo(this, 'renderitem', function ($item) {

                    $item.append('<span class="remove"></span>')
                        .on('mouseup', '.remove', function (event) {
                            //noinspection JSAccessibilityCheck
                            that._removeItem($(this).closest('li'));
                            event.stopPropagation();
                        });

                });

            }

            this.$target.on('keydown.autocomplete', function (event) {
                if (that.target.readOnly) {
                    return;
                }

                suppressKeyPress = false;
                suppressInput = false;
                suppressKeyPressRepeat = false;

                switch (event.which) {
                    case KeyCodes.PAGE_UP:
                        suppressKeyPress = true;
                        that._moveDropDown("previousPage", event);
                        break;
                    case KeyCodes.PAGE_DOWN:
                        suppressKeyPress = true;
                        that._moveDropDown("nextPage", event);
                        break;
                    case KeyCodes.UP:
                        suppressKeyPress = true;
                        that._keyEvent("previous", event);
                        break;
                    case KeyCodes.DOWN:
                        suppressKeyPress = true;
                        that._keyEvent("next", event);
                        break;
                    case KeyCodes.SPACE:
                        if (!o.captureSpaceOnlyAfterUpDown || that._lastKeyAllowsSpace) {
                            if (o.multi && that.hasFocusedItem()) {
                                that.toggleFocusedItem(event);
                                event.preventDefault();
                            }
                        }
                        break;
                    case KeyCodes.ENTER:
                        if (that.hasFocusedItem()) {
                            suppressKeyPress = true;
                            event.preventDefault();
                            that.triggerItemSelection(event);
                        }
                        break;
                    case KeyCodes.TAB:
                        if (that.hasFocusedItem()) {
                            that.triggerItemSelection(event);
                        }
                        break;
                    case KeyCodes.ESCAPE:
                        if (that.isVisible()) {
                            that._setInputValue(this.term);
                            that.hide(event);
                            event.preventDefault();
                        }
                        break;
                    default:
                        suppressKeyPressRepeat = true;
                        that._searchTimeout(event);
                        break;
                }

                that._lastKeyAllowsSpace = event.which === KeyCodes.UP || event.which === KeyCodes.DOWN || (event.which === KeyCodes.SPACE && that._lastKeyAllowsSpace);
            });

            this.$target.on('keypress.autocomplete', function (event) {
                if (suppressKeyPress) {
                    suppressKeyPress = false;
                    if (!that._isMultiLine || that.isVisible()) {
                        event.preventDefault();
                    }
                    return;
                }
                if (suppressKeyPressRepeat) {
                    return;
                }

                switch (event.which) {
                    case KeyCodes.PAGE_UP:
                        that._moveDropDown("previousPage", event);
                        break;
                    case KeyCodes.PAGE_DOWN:
                        that._moveDropDown("nextPage", event);
                        break;
                    case KeyCodes.UP:
                        that._keyEvent("previous", event);
                        break;
                    case KeyCodes.DOWN:
                        that._keyEvent("next", event);
                        break;
                }
            });

            this.$target.on('input.autocomplete', function (event) {
                if (suppressInput) {
                    suppressInput = false;
                    event.preventDefault();
                    return;
                }
                that._searchTimeout(event);
            });

            let avoidToggleFromClick = false;

            this.$target
                .on('focus.autocomplete', function (event) {
                    that.selectedItem = null;
                    that._previousSelectedItem = that._getInputValue();

                    avoidToggleFromClick = false;

                    if (o.autoSearchOnFocus) {
                        if (!that.isVisible()) {
                            that.search(o.autoSearchOnFocus === 'empty' ? '' : undefined);
                        }

                        avoidToggleFromClick = true;
                        setTimeout(function () { avoidToggleFromClick = false; }, 10);
                    }
                })
                .on('mousedown.autocomplete', function (event) {
                    if (o.toggleOnClick && !avoidToggleFromClick) {
                        if (that.isVisible()) {
                            that.hide();
                        } else {
                            that.search(o.toggleOnClick === 'empty' ? '' : undefined);
                        }
                    }
                    avoidToggleFromClick = false;
                })
                .on('blur.autocomplete', function (event) {
                    if (that.cancelBlur) {
                        delete that.cancelBlur;
                        return;
                    }
                    clearTimeout(that._searchTimer);
                    that.hide(event);
                    if (that.o) { // If we are not closed in response to the select event
                        that._change(event);
                    }
                });

            this._initSource();

            this.$el.on('mousedown', _.bind(function (event) {
                // prevent moving focus out of the text field
                event.preventDefault();

                // IE doesn't prevent moving focus even with event.preventDefault()
                // so we set a flag to know when we should ignore the blur event
                this.cancelBlur = true;
                _.delay(_.bind(function () {
                    delete this.cancelBlur;
                }, this));

                // clicking on the scrollbar causes focus to shift to the body
                // but we can't detect a mouseup or a click immediately afterward
                // so we have to track the next mousedown and close the menu if
                // the user clicks somewhere outside of the autocomplete
                const menuElement = this.el;
                if (!$(event.target).closest("li").length) {
                    _.delay(_.bind(function () {
                        const that = this;
                        $(document).one("mousedown", function (event) {
                            if (event.target !== that.target &&
                                event.target !== menuElement && !$.contains(menuElement, event.target)) {
                                that.hide();
                            }
                        });
                    }, this));
                }
            }, this));

            this
                .on('focus', function (item, event) {
                    // support: Firefox
                    // Prevent accidental activation of menu items in Firefox (#7024 #9118)
                    if (that._isNewMenu) {
                        that._isNewMenu = false;
                        if (event && /^mouse/.test(event.type)) {
                            that.$el.blur();

                            $(document).one('mousemove', function () {
                                $(event.target).trigger(event);
                            });

                            return;
                        }
                    }

                    if (event && /^key/.test(event.type)) {
                        that._setInputValue(item.short_label || item.label);
                    }
                })
                .on('select', function (event) {
                    const item = event.item,
                        originalEvent = event.originalEvent;

                    if (originalEvent) {
                        originalEvent.preventDefault();
                    } else {
                        return; // This is a select not originating in user action, probably from us
                    }

                    if (that.o) { // If we are not closed in response to the select event

                        if (that.o.multi && originalEvent &&
                            ((/^key/.test(originalEvent.type) && originalEvent.which === KeyCodes.SPACE) ||
                                (originalEvent.type === 'click' || originalEvent.type === 'mouseup'))) {

                            // Multiple selection

                            this.cancelBlur = true;
                            _.delay(_.bind(function () {
                                delete this.cancelBlur;
                            }, this));

                        } else {

                            // Single selection
                            this._setInputValue(item.short_label || item.value);
                            this.term = this._getInputValue();
                            setTimeout(function () {
                                that.hide(originalEvent);
                            }, 0);

                        }
                        this.selectedItem = item;
                    }
                })
                .on('check', function (event) {
                    const item = event.item, checked = item.checked;

                    if (checked) {
                        that._multiSelectedItems.push(item);
                        that._multiSelectedValues.push(item.value);
                    } else {
                        const idx = that._multiSelectedValues.indexOf(item.value);
                        that._multiSelectedItems.splice(idx, 1);
                        that._multiSelectedValues.splice(idx, 1);
                    }
                });

            this._screenReaderLiveRegion = $('<span role="status" aria-live="polite" style="top:-9999px;width:0;height:0;z-index:-10;overflow:hidden;"></span>').css('display', 'none').insertBefore(this.$target);

            $(window).on('beforeunload', this._windowBeforeUnload = _.bind(function () {
                this.$target.removeAttr("autocomplete");
            }, this));

            return that;
        },

        /*render: function () {

         return this;
         },*/

        /**
         * @public
         */
        remove: function () {
            const that = this;

            if (this._searchTimer) {
                clearTimeout(this._searchTimer);
            }
            if (this._listeningToScroll) {
                for (let i = 0; i < this._listeningToScroll.length; i++) {
                    this._listeningToScroll[i].off('scroll', this._boundRepositionAndResize);
                }
                this._listeningToScroll = [];
            }
            $(window).off('beforeunload', this._windowBeforeUnload);
            this._windowBeforeUnload = null;
            this.$target.removeClass('autocomplete-active').off('.autocomplete');
            this.target.autocomplete = this._autocompleteAttr;
            this._screenReaderLiveRegion.remove();

            delete this.$target;
            delete this.target;
            delete that.o;

            AutoComplete.__super__.remove.apply(this, arguments);
        },

        /**
         * @private
         */
        _initSource: function () {
            const that = this,
                o = that.o;

            if ($.isArray(o.source) ||
                typeof (o.source) === 'object') {

                let array = that._normalize(o.source);

                if (o.sortItems) {
                    array = that._sortSource(array, true, false);
                }

                that.source = function (request, response) {
                    response(array, true, false);
                };

            } else if (typeof o.source === "string") {

                const url = o.source;
                that.source = function (request, response) {
                    if (that._xhr) {
                        that._xhr.abort();
                    }
                    that._xhr = o.ajaxFunction({
                        url: url,
                        data: request,
                        dataType: "json",
                        success: function (data) {
                            response(data);
                        },
                        error: function () {
                            response([]);
                        },
                    });
                };

            } else {
                that.source = o.source;
            }
        },

        /**
         *
         * @private
         * @param {Event|jQuery.Event} [event] - the event the possibly triggered this search
         */
        _searchTimeout: function (event) {

            const that = this, o = that.o;

            clearTimeout(this._searchTimer);

            that._searchTimer = _.delay(function () {
                // only search if the value has changed
                if (that.term !== that._getInputValue()) {
                    that.selectedItem = null;
                    that.search(null, event);
                }
            }, o.delay);
        },

        /**
         * Triggers a search for the value specified
         * @public
         * @param {String} value - the value to search for
         * @param {Event|jQuery.Event} [event] - the event the possibly triggered this search
         * @returns {AutoComplete}
         */
        search: function (value, event) {
            const that = this, o = that.o;

            value = value != null ? value : that._getInputValue();

            // always save the actual value, not the one passed as an argument
            that.term = that._getInputValue();

            if (value.length < o.minLength) {
                return that.hide(event);
            }

            let cancelled = false;
            const cancelUpdate = function () {
                cancelled = true;
            };
            that.trigger('search', cancelUpdate);

            if (cancelled) {
                return;
            }

            return that._search(value);
        },

        /**
         *
         * @private
         * @param term
         */
        _search: function (term) {
            const that = this, o = that.o;

            this._pending++;
            this.$target.addClass("autocomplete-loading");
            this._cancelSearch = false;

            const request = {};

            // If we are going to cache, then request all of them
            if (!o.cacheSource) {
                request['term'] = term;
            }

            this.source(request, this._response(term));
        },

        /**
         *
         * @private
         * @param {String} term
         * @returns {Function}
         */
        _response: function (term) {
            const that = this, o = that.o;

            const index = ++this._requestIndex;

            return (function (content, shouldFilter, shouldSort) {

                shouldSort = (shouldSort || shouldSort === undefined)
                    && o.sortItems;

                if (index === this._requestIndex) {
                    this.__response(content, term, shouldFilter, shouldSort);
                }

                this._pending--;
                if (!this._pending) {
                    this.$target.removeClass("autocomplete-loading");
                }

            }).bind(this);
        },

        /**
         *
         * @private
         * @param {Array.<Object>} content
         * @param {String} term
         * @param {Boolean} shouldFilter
         * @param {Boolean} shouldSort
         */
        __response: function (content, term, shouldFilter, shouldSort) {

            const that = this, o = that.o;

            let shouldPutCheckedFirst = o.multi && o.putCheckedFirst;

            if (content) {
                content = this._normalize(content);

                if (shouldSort) {
                    content = that._sortSource(
                        content,
                        true,
                        shouldPutCheckedFirst && !o.cacheSource,
                        o.splitCheckedGroups);

                    if (!o.cacheSource) {
                        shouldPutCheckedFirst = false;
                    }
                }
            }

            if (o.cacheSource) {
                o.cacheSource = false;
                o._cacheSource = true;
                this._source = content;

                this.source = function (request, response) {
                    response(this._source, /*filter*/ true);
                };
            }

            if (shouldFilter) {
                content = this._filterSource(content, term);
            }

            this.trigger('response', content);

            if (content && content.length && !this._cancelSearch) {

                if (shouldPutCheckedFirst) {
                    content = that._sortSource(
                        content,
                        false,
                        shouldPutCheckedFirst,
                        o.splitCheckedGroups);
                }

                const firstItem = content[0];

                // If we have only a single result and it matches the content of the input,
                // We want to automatically select that, as this is what the user expects
                if (!o.multi &&
                    o.autoSelectSingleResult &&
                    content.length === 1 &&
                    this._getInputValue() === (firstItem.short_label || firstItem.label)) {

                    this.trigger('select', {item: firstItem});
                    this.term = this._getInputValue();
                }

                this._suggest(content);
                this.trigger('open');
            } else {
                this._hide();
            }
        },

        /**
         *
         * @private
         * @param source
         * @param term
         * @returns {*}
         */
        _filterSource: function (source, term) {
            const that = this, o = that.o;

            const matcher = new RegExp(escapeRegex(term), "i");

            const filterFunc = o.filterShortLabel ? function (item) {
                if (item.group) return true;
                return matcher.test(item.short_label || item.label);
            } : function (item) {
                if (item.group) return true;
                return matcher.test(item.label || item.short_label);
            };

            source = source.filter(filterFunc);

            // Clean up groups without children

            let lastGroup = -1;
            let len = source.length;

            for (let i = 0; i < len; i++) {
                let item = source[i];

                if (item.group) {
                    if (lastGroup !== -1) {
                        if (lastGroup === i - 1) {
                            // It was an empty group
                            source.splice(lastGroup, 1);
                            i--;
                            len--;
                        }
                    }

                    lastGroup = i;
                }
            }

            if (lastGroup !== -1) {
                if (lastGroup === len - 1) {
                    // It was an empty group
                    source.splice(lastGroup, 1);
                }
            }

            return source;
        },

        /**
         * Handles sorting, and putting checked items first (according to _multiSelectedValues, not item.checked)
         * @private
         * @param source
         * @param sort
         * @param {Boolean=false} putCheckedFirst
         * @param {Boolean=false} splitCheckedGroups
         * @returns {Array}
         */
        _sortSource: function (source, sort, putCheckedFirst, splitCheckedGroups) {

            if (!sort && !putCheckedFirst)
                return source; // Nothing to do

            let group = [];
            let groups = [group];
            const selectedValues = this._multiSelectedValues;
            let item, i, len;

            // Split to groups
            for (i = 0, len = source.length; i < len; i++) {
                item = source[i];
                if (item.group && group.length) {
                    group = [];
                    groups.push(group);
                }
                group.push(item);
            }

            // Leftover
            if (!group.length) {
                groups.length = 0;
            }

            if (sort) {
                // Sort the groups
                groups.sort((a, b) => {
                    a = a[0];
                    b = b[0];

                    // A "group" without a group item will come first
                    if (!a.group && b.group) return -1;
                    if (a.group && !b.group) return 1;

                    if (typeof a.order === 'number') {
                        if (typeof b.order === 'number')
                            return a.order - b.order;
                        else
                            return -1;
                    } else if (typeof b.order === 'number') {
                        return 1;
                    }

                    if (a.label < b.label) return -1;
                    if (a.label > b.label) return 1;

                    return 0;
                });
            }

            // Now we have an array of groups, possibly sorted.
            // Each group is an array that begins with the group item (group name/id).
            // A group could possible start with a normal item, if it's a "default group", which had no group item.

            const checkedGroups = [], uncheckedGroups = [];

            // Iterate groups
            for (let g = 0, glen = groups.length; g < glen; g++) {
                group = groups[g];

                // Sort each group
                group.sort((a, b) => {

                    // Grouped items come first
                    if (a.group && !b.group) return -1;
                    if (!a.group && b.group) return 1;

                    if (typeof a.order === 'number') {
                        if (typeof b.order === 'number')
                            return a.order - b.order;
                        else
                            return -1;
                    } else if (typeof b.order === 'number') {
                        return 1;
                    }

                    if (putCheckedFirst) {
                        const aChecked = selectedValues.indexOf(a.value);
                        const bChecked = selectedValues.indexOf(b.value);

                        if (aChecked > -1 && bChecked === -1) return -1;
                        if (aChecked === -1 && bChecked > -1) return 1;
                    }

                    if (sort) {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                    }

                    return 0;
                });

                uncheckedGroups.push(group);
            }

            if (putCheckedFirst && splitCheckedGroups) {

                let virtualGroup;

                // Iterate groups
                for (let g = 0, glen = groups.length; g < glen; g++) {
                    group = groups[g];

                    virtualGroup = null;

                    for (let gi = 0, gilen = group.length; gi < gilen; gi++) {
                        item = group[gi];
                        if (item.group) continue;
                        if (selectedValues.indexOf(item.value) === -1) break;

                        if (!virtualGroup) {
                            virtualGroup = [];
                            if (group[0].group) {
                                virtualGroup.push(group[0]);
                            }
                        }

                        virtualGroup.push(item);
                        group.splice(gi--, 1);
                        gilen--;
                    }

                    if (virtualGroup) {
                        checkedGroups.push(virtualGroup);
                        if (group.length === 0 || (group.length === 1 && group[0].group)) {
                            groups.splice(g--, 1);
                            glen--;
                        }
                    }
                }
            }

            // Prepare the target array
            const joined = [];
            joined.length = source.length;
            let itemIndex = 0;

            groups = checkedGroups.length ?
                checkedGroups.concat(uncheckedGroups) : // Concat both lists
                uncheckedGroups; // No need for concat

            for (let g = 0, glen = groups.length; g < glen; g++) {
                group = groups[g];

                for (i = 0, len = group.length; i < len; i++) {
                    joined[itemIndex++] = group[i];
                }
            }

            return joined;
        },

        /**
         * Hides the AutoComplete box
         * @public
         * @param {Event|jQuery.Event} [event] - the event the possibly triggered this search
         * @returns {AutoComplete}
         */
        hide: function (event) {
            this._cancelSearch = true;
            return this._hide(event);
        },

        /**
         *
         * @private
         * @param {Event|jQuery.Event} [event] - the event the possibly triggered this search
         * @returns {AutoComplete}
         */
        _hide: function (event) {
            if (this.isVisible()) {
                AutoComplete.__super__.hide.call(this);
                this._isNewMenu = true;
            }

            this.stopListening(ResizeManager, 'resize');
            for (let i = 0; i < this._listeningToScroll.length; i++) {
                this._listeningToScroll[i].off('scroll', this._boundRepositionAndResize);
            }
            this._listeningToScroll = [];

            return this;
        },

        /**
         *
         * @private
         * @param {Event|jQuery.Event} [event] - the event that possibly triggered this search
         */
        _change: function (event) {
            if (this._previousSelectedItem !== this._getInputValue()) {
                this.trigger('change', this.selectedItem);
            }
        },

        /**
         *
         * @private
         * @param items
         * @returns {*}
         */
        _normalize: function (items) {

            const that = this, o = that.o;

            const labelAttr = o.labelAttr
                , valueAttr = o.valueAttr
                , shortLabelAttr = o.shortLabelAttr;

            // Are these in the right format already?
            if ($.isArray(items)
                && labelAttr === 'label'
                && shortLabelAttr === 'short_label'
                && valueAttr === 'value')
                return items;

            let i, len, item, norm;

            const normArray = [];

            if ($.isArray(items)) {

                for (i = 0, len = items.length; i < len; i++) {
                    item = items[i];
                    norm = {};

                    if (typeof item === 'string') {
                        norm.label = norm.value = norm.short_label = item;
                    } else {
                        norm.value = item[valueAttr] === undefined ? item[labelAttr] : item[valueAttr];
                        norm.label = item[labelAttr] || item[shortLabelAttr] || item[valueAttr];
                        norm.short_label = item[shortLabelAttr] || norm.label;
                    }

                    normArray.push(norm);
                }

            } else {

                for (let value in items) {
                    if (!items.hasOwnProperty(value)
                        || typeof items[value] === 'function') continue;

                    norm = {};
                    norm.value = value;
                    norm.label = items[value] || value;
                    norm.short_label = norm.label;

                    normArray.push(norm);
                }

            }

            return normArray;
        },

        /**
         *
         * @private
         * @param items
         */
        _suggest: function (items) {
            const o = this.o;

            this.removeAllItems()
                .addItems(items);

            if (o.multi) {
                this.setCheckedValues(this._multiSelectedValues);
            }

            this._isNewMenu = true;

            // size and position menu
            this.prepareShow().$el.show();

            this.listenTo(ResizeManager, 'resize', this._boundRepositionAndResize);
            let $parent = this.$target.parent();
            while ($parent.length) {
                if ($parent[0].scrollHeight > $parent[0].offsetHeight || $parent[0].scrollWidth > $parent[0].offsetWidth) {
                    if ($parent[0] === document.documentElement) {
                        $parent = $(window);
                    }
                    this._listeningToScroll.push($parent.on('scroll', this._boundRepositionAndResize));
                }
                $parent = $parent.parent();
            }

            this.repositionAndResize();

            if (o.autoFocus) {
                this.next();
            }
        },

        /**
         * Updates the position and size of AutoComplete box
         * @param {Object?} positionOptions
         * @returns {AutoComplete}
         * @public
         */
        repositionAndResize: function (positionOptions) {
            const that = this, o = that.o;

            positionOptions = {
                $target: o.$attachTo
                , offset: {x: 0, y: 0}
                , anchor: 'start bottom'
                , position: 'start top'
                , updateWidth: true,
            };

            return this.constructor.__super__.repositionAndResize.call(this, positionOptions);
        },

        /**
         * Detects the z-index of an element if evaluated against a specific element
         * @private
         * @param {Element} el - the one whose z-index we are looking for
         * @param {Element} until - the one we are evaluating agains
         * @returns {number}
         */
        _detectZIndex: function (el, until) {
            let zIndex = 0;
            while (el) {
                const css = getComputedStyle(el);
                const positionProp = css['position'];
                if ((positionProp === 'relative' ||
                    positionProp === 'absolute' ||
                    positionProp === 'fixed') &&
                    css['z-index']) {
                    zIndex = parseInt(css['z-index'], 0) || 0;
                }
                if (!el.parentNode || el.parentNode === until) break;
                el = el.parentNode;
            }
            return zIndex;
        },

        /**
         *
         * @private
         * @param direction
         * @param {Event|jQuery.Event} [event]
         */
        _moveDropDown: function (direction, event) {
            if (!this.isVisible()) {
                this.search(null, event);
                return;
            }
            event.preventDefault();
            if (this.isFirstItem() && /^previous/.test(direction) ||
                this.isLastItem() && /^next/.test(direction)) {
                this._setInputValue(this.term);
                this.blurFocusedItem();
                return;
            }
            this[direction](event);
        },

        /**
         *
         * @private
         * @returns {*}
         */
        _getInputValue: function () {
            return this.$target[this._valueMethod]();
        },

        /**
         *
         * @private
         * @returns {*}
         */
        _setInputValue: function (label) {

            if (!this.o.updateTargetValue) {
                this._screenReaderLiveRegion.text(label);
                return this;
            }

            // Call get/set
            this.$target[this._valueMethod].call(this.$target, label);

            // Send inputupdate event
            this.trigger('inputupdate');

            return this;
        },

        /**
         *
         * @private
         * @param {KeyboardEvent|jQuery.Event} [keyEvent]
         * @param {Event|jQuery.Event} [event]
         */
        _keyEvent: function (keyEvent, event) {
            if (!this._isMultiLine || this.$el.is(":visible")) {
                this._moveDropDown(keyEvent, event);
                event.preventDefault();
            }
        },

        /**
         * Returns an array of checked item values
         * @public
         * @param {Boolean=false} excludeGroups Exclude group items
         * @returns {Array.<*>}
         */
        getCheckedValues: function (excludeGroups) {
            if (excludeGroups) {
                const values = [],
                    items = this._multiSelectedItems;

                for (let i = 0, len = items.length; i < len; i++) {
                    const item = items[i];
                    if (item.group) continue;
                    values.push(item.value);
                }
                return values;
            } else {
                return this._multiSelectedValues.slice(0);
            }
        },

        /**
         * Returns an array of checked items
         * @public
         * @param {Boolean=false} excludeGroups Exclude group items
         * @returns {Array.<Object>}
         */
        getCheckedItems: function (excludeGroups) {
            if (excludeGroups) {
                return this._multiSelectedItems.filter(function (item) { return !item.group; });
            } else {
                return this._multiSelectedItems.slice(0);
            }
        },

        /**
         * Returns the number of checked items
         * @public
         * @param {Boolean=false} excludeGroups Exclude groups in length calculation
         * @returns {Number}
         */
        getCheckedItemCount: function (excludeGroups) {
            if (excludeGroups) {
                let count = 0,
                    items = this._multiSelectedItems;

                for (let i = 0, len = items.length; i < len; i++) {
                    if (items[i].group) continue;
                    count++;
                }
                return count;
            } else {
                return this._multiSelectedItems.length;
            }
        },

        /**
         * Sets the specified items to "checked" mode.
         * An array of items is passed, not values, because we need to keep track of items,
         * and if we already have the array of items then this spares the process of searching for the items by values.
         * @public
         * @param {Array.<Object>} items - an array of *items* to select (not values)
         * @returns {AutoComplete}
         */
        setCheckedItems: function (items) {

            // Copy those
            this._multiSelectedItems = [].concat(items || []);

            // Now create array of pure values
            const values = [];
            for (let i = 0; i < this._multiSelectedItems.length; i++) {
                values.push(this._multiSelectedItems[i].value);
            }

            this._multiSelectedValues = values;

            // Set checked values if visible
            if (this.isVisible()) {
                this.constructor.__super__.setCheckedValues.call(this, values);
            }

            return this;
        },

        /**
         * Sets the source for the auto-complete results
         * @public
         * @param source
         * @param {Boolean} cacheSource=false
         * @returns {AutoComplete}
         */
        setSource: function (source, cacheSource) {
            const that = this, o = that.o;
            o.source = source;
            o.cacheSource = cacheSource;
            delete o._cacheSource;
            that._initSource();
            return that;
        },

        /**
         * Clears the cache of the results source, in case `cacheSource` options is `true`s
         * @public
         * @returns {AutoComplete}
         */
        uncacheSource: function () {
            const that = this, o = that.o;

            if (o._cacheSource) {
                o.cacheSource = o._cacheSource;
                o._cacheSource = false;
                delete that._source;
                that._initSource();
            }

            return that;
        },

        /**
         *
         * @private
         * @param $item
         * @returns {AutoComplete}
         */
        _removeItem: function ($item) {
            const itemData = $item.data('item');

            this.removeItem(itemData.value, itemData.label)
                .repositionAndResize();

            // If our source is cached, then we remove it locally
            if (this._source) {
                this._source = this._source.filter(function (item) {
                    return item.value !== itemData.value;
                });
            }

            // Emit the removeitem event to allow a request to the server to remove the item
            this.trigger('removeitem', itemData.value || itemData.label);

            return this;
        },
    },
);

export default AutoComplete;
